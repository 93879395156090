import React, { useState } from "react";
import { RefreshControl, ScrollView, TouchableOpacity, View, ActivityIndicator, Platform } from "react-native";
import { Text } from "react-native";


let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";
const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps } from "../styles/main";

let styles = null;
const lang = require("../constants/lang-en.json");


let _props = null;

let _invites = [];

let _leagueList = null;
let _SetLeagueList = null;

let _inviteList = null;
let _SetInviteList = null;

let _isLoading = null;
let _SetIsLoading = null;

let _lastUpdate = (new Date()).valueOf();
function ResetTimer() {
	_lastUpdate=(new Date()).valueOf();
}


export default function LeaguesScreen(props) {
	styles = global.styles;
	_props = props;

	[ _leagueList, _SetLeagueList ] = useState(<Text style={styles.title2}>loading...</Text>);
	[ _inviteList, _SetInviteList ] = useState(<Text style={styles.title2}>loading...</Text>);
	[ _isLoading, _SetIsLoading ] = useState(false);

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		return unsubscribe;
	}, [props.navigation]);

	return (<View style={styles.contentContainer}>
		<ScrollView style={styles.webScrollViewFix} refreshControl={<RefreshControl refreshing={_isLoading} onRefresh={Refresh} />} keyboardShouldPersistTaps="always">
			<View style={{alignItems:"center"}}>
				{UIHelpers.Header(lang.leagues)}
				<View style={styles.box}>
					<View style={{width:"75%"}}>
						{_leagueList}
					</View>
				</View>

				{UIHelpers.Header(lang.invites, null, 40)}
				<View style={styles.box}>
					<View style={{width:"75%"}}>
						{_inviteList}
					</View>
				</View>

				<View style={{paddingTop:Helpers.GetScaledSize(26.67)}}/>
				<TouchableOpacityDB style={styles.button} onPress={CreateLeague}>
					<Text style={styles.buttonText}>{lang.createLeague}</Text>
				</TouchableOpacityDB>
				<View style={{paddingTop:Helpers.GetScaledSize(13.33)}}/>
			</View>
			{ _isLoading && <View style={styles.loading}>
				<ActivityIndicator {...ActivityIndicatorProps} />
			</View> }
		</ScrollView>
	</View>);
}

async function OnFocus() {
	API.Rest.CheckVersion();
	LoadData();
}

async function ShowInfoPopup() {
	if (global.sawWelcome) {
		return;
	}

	let user = await API.Rest.UserLoad();
	if (user.notified && user.notified.indexOf("welcome")>=0) {
		return;
	}
	global.sawWelcome = true;

	Alert.alert(lang.welcome, lang.quickStartText,
		[	{text: "Don't Show Again", onPress: GuideMessageIgnore},
			{text: "Later", style: "cancel"},
			{text: "View Guide", onPress: OpenGuide}
		]
	);
}

async function GuideMessageIgnore() {
	if (!await API.Rest.UserSave({ "notified": "welcome" })) {
		Alert.alert(lang.invalidLogin, lang.invalidLoginText, [{text: lang.ok}]);
	}
}

async function OpenGuide() {
	Helpers.OpenURL(lang.urlHelp);
}

async function LoadData() {
	if (await API.Main.UserLeaguesLoad()) {
		await LoadInvites();
		DisplayLeagues();
		ShowInfoPopup();
	}
}

async function Refresh() {
	await _SetIsLoading(true);

	const now = new Date().valueOf();
	const diff = now - _lastUpdate;

	if (diff>5000) {
		try {
			await LoadData();
			ResetTimer();
		} catch (ex) {
		}
	}

	_SetIsLoading(false);
}



function DisplayLeagues() {
	if (global.context.leagues && global.context.leagues.length==0) {
		_SetLeagueList(<Text style={[styles.title2,{textAlign:"center"}]}>{lang.noLeagues}</Text>);
	} else {
		_SetLeagueList(global.context.leagues.map((item) => (
			<TouchableOpacityDB key={item._id} onPress={()=>{GoToLeague(item._id);}}>
				<View style={styles.listItem}>
					<Text style={styles.title1}>{item.name}</Text>
					<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(12.83)}]}>{ (item.ownerIds.indexOf(global.context.userId)>=0) ? "commissioner" : " " }</Text>
				</View>
			</TouchableOpacityDB>
		)));
	}
}

async function LoadInvites() {
	_invites = await API.Rest.UserInvitesLoad();
	DisplayInvites();
}

function DisplayInvites() {
	if (!_invites) {
		_SetInviteList(<Text style={[styles.title2,{textAlign:"center"}]}>unable to load invites</Text>);
	} else if (_invites.length==0) {
		_SetInviteList(<Text style={[styles.title2,{textAlign:"center"}]}>no invites</Text>);
	} else {
		_SetInviteList(_invites.map((item) => (
			<TouchableOpacityDB key={item._id} onPress={()=>{InviteAction(item);}}>
				<View style={styles.listItem}>
					<Text style={styles.title1}>{item.leagueName}</Text>
					<Text style={styles.title2}>(from {item.invitedBy})</Text>
				</View>
			</TouchableOpacityDB>
		)));
	}
}

async function InviteAction(invite) {
	Alert.alert("Respond to Invite", "Do you want to join " + invite.leagueName + "?",
		[{text: lang.cancel, style: "cancel"},
			{text: "Ignore", onPress: () => InviteDeleteConfirm(invite)},
			{text: lang.joinLeague, onPress: () => API.Rest.UI.InviteAccept(invite)}
		]
	);
}

async function InviteDeleteConfirm(invite) {
	Alert.alert("Ignore Invite", "Are you sure you want to ignore the invitation for " + invite.leagueName + "?",
		[{text: lang.cancel, style: "cancel"},	{text: "Ignore", onPress: () => InviteDelete(invite._id)}]
	);
}

async function InviteDelete(id) {
	if (await API.Rest.InviteDelete(id)) {
		LoadInvites();
	}
}

function CreateLeague() {
	Helpers.Navigate("LeagueCreate");
}

async function GoToLeague(id) {
	console.log("GoToLeague");
	await Helpers.SetIsLoading(_SetIsLoading);
	API.Rest.UI.LeagueSet(id);
}


LeaguesScreen.navigationOptions = {
	header: null,
};
