import React, { useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { ImageBackground, ActivityIndicator } from "react-native";
import { Text, TextInput, Picker } from "react-native";
import SafeAreaView from "react-native-safe-area-view";
import PickeriOS from "react-native-picker-select";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


import Helpers from "../classes/Helpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";

const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";

let styles = null;
const config = require("../config.json");
const countries = require("../constants/countries.json");
const lang = require("../constants/lang-en.json");


let _props = null;

let _firstName = null;
let _SetFirstName = null;
let _lastName = null;
let _SetLastName = null;

let _email = null;
let _SetEmail = null;

let _countryValue = 840;
let _countryPicker = null;
let _SetCountryPicker = null;

let _needPostal = false;
let _SetNeedPostal = null;
let _postalCode = null;
let _SetPostalCode = null;

let _oldpassword = "";
let _password1 = null;
let _password2 = null;
let _SetPassword1 = null;
let _SetPassword2 = null;
let _passwordWarning = null;
let _SetPasswordWarning = null;

let _isLoading = false;
let _SetIsLoading = null;


export default function SignUpScreen(props) {
	styles = global.styles;
	console.log("SignUpScreen");
	_props = props;

	[ _isLoading, _SetIsLoading ] = useState(false);
	[ _firstName, _SetFirstName ] = useState("");
	[ _lastName, _SetLastName ] = useState("");
	[ _email, _SetEmail ] = useState("");
	[ _countryPicker, _SetCountryPicker ] = useState();
	[ _postalCode, _SetPostalCode ] = useState("");
	[ _needPostal, _SetNeedPostal ] = useState(true);
	[ _password1, _SetPassword1 ] = useState("");
	[ _password2, _SetPassword2 ] = useState("");
	[ _passwordWarning, _SetPasswordWarning ] = useState(false);


	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		return unsubscribe;
	}, [props.navigation]);

	return (<ImageBackground source={require("../assets/images/bg_dark.jpg")} style={styles.bgImage}>
		<SafeAreaView style={styles.contentContainerClear} forceInset={{top: "always"}} keyboardShouldPersistTaps="always">
		<View style={{justifyContent:"space-between", flex:1}}>
			<KeyboardAwareScrollView style={styles.webScrollViewFix} keyboardShouldPersistTaps="always">
			<View style={[styles.mainBox, {paddingTop: Helpers.GetScaledSize(13.33)}]}>
				<View style={{flexDirection: "row", marginBottom:Helpers.GetScaledSize(10.33)}}>
					<TextInput placeholder={lang.firstName} {...PlaceholderProps} autoCapitalize="none"
						importantForAutofill="yes" textContentType="givenName" autoCompleteType="name"
						style={[styles.inputBox, {width:Helpers.GetScaledSize(150)}]}
						value={_firstName} onChangeText={(value) => { _SetFirstName(value); }} />
					<View style={{paddingLeft:Helpers.GetScaledSize(6)}}/>
					<TextInput placeholder={lang.lastName} {...PlaceholderProps} autoCapitalize="none"
						importantForAutofill="yes" textContentType="familyName" autoCompleteType="name"
						style={[styles.inputBox, {width:Helpers.GetScaledSize(150)}]}
						value={_lastName} onChangeText={(value) => { _SetLastName(value); }} />
				</View>

				<TextInput placeholder={lang.email} {...PlaceholderProps} autoCapitalize="none"
					importantForAutofill="yes" textContentType="emailAddress" autoCompleteType="email"
					style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_email} onChangeText={(value) => { _SetEmail(value); }} />

				<TextInput placeholder={lang.password} {...PlaceholderProps} autoCapitalize="none" textContentType="password" secureTextEntry={true}
					importantForAutofill="yes" textContentType="newPassword"
					style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_password1} onChangeText={(value) => { SetPassword(1, value); }} />

				<TextInput placeholder={lang.verifyPassword} {...PlaceholderProps} autoCapitalize="none" textContentType="password" secureTextEntry={true}
					importantForAutofill="yes" textContentType="newPassword"
					style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_password2} onChangeText={(value) => { SetPassword(2, value); }} />
				{_passwordWarning && <Text style={{color:"#ff4040"}}>passwords do not match</Text>}

				{_countryPicker}
				{_needPostal && <TextInput placeholder={lang.postalCode} {...PlaceholderProps} autoCapitalize="none"
					importantForAutofill="yes" textContentType="postalCode" autoCompleteType="postal-code"
					style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_postalCode} onChangeText={(value) => { _SetPostalCode(value); }} />}

				<View style={{paddingTop: Helpers.GetScaledSize(16)}}></View>
				<View style={{flexDirection: "row"}}>
					<TouchableOpacityDB style={styles.button} onPress={CreateUser}>
						<Text style={styles.buttonText}>Create Account</Text>
					</TouchableOpacityDB>
				</View>
			</View>

			</KeyboardAwareScrollView>

			<View style={styles.bottomContainer}>
				<TouchableOpacityDB onPress={()=>{ _props.navigation.goBack();}}>
					<Text style={[styles.textLink,styles.title1b,styles.textLight]}>{lang.useExisting}</Text>
				</TouchableOpacityDB>
			</View>
		</View>
		</SafeAreaView>
		{ _isLoading && <View style={styles.loading}>
			<ActivityIndicator {...ActivityIndicatorProps} />
		</View> }
	</ImageBackground>);
}

async function OnFocus() {
	DisplayCountryPicker();
}

async function SetPassword(num, value) {
	if (num==1) {
		await _SetPassword1(value);
	} else {
		await _SetPassword2(value);
	}

	_oldpassword=_password1+_password2;
	setTimeout(async () => {
		if ((_password1+_password2)==_oldpassword && _password2.length>0) {
			_SetPasswordWarning(_password1!=_password2);
		}
	}, 1000);
}

function DisplayCountryPicker() {
	let choices = [];


	/*_SetCountryPicker(
		<RNPickerSelect placeholder={{ label: "pick a country", value: null, color: "#9EA0A4" }}
		items={choices}
		style={{ iconContainer: { top: Helpers.GetScaledSize(13.5), right: Helpers.GetScaledSize(6.67) },
					inputAndroid: { fontSize:Helpers.GetScaledSize(16), backgroundColor:"#ffffff", width:Helpers.GetScaledSize(306), height:Helpers.GetScaledSize(40), borderRadius:Helpers.GetScaledSize(4), borderColor:"#000000", borderWidth:1, color:"#000000", paddingHorizontal: Helpers.GetScaledSize(10) },
					sourcePicker: { fontSize:Helpers.GetScaledSize(16) },
					inputIOS: { fontSize:Helpers.GetScaledSize(16), backgroundColor:"#ffffff", width:Helpers.GetScaledSize(306), height:Helpers.GetScaledSize(40), borderRadius:Helpers.GetScaledSize(4), borderColor:"#000000", borderWidth:1, color:"#000000", paddingHorizontal: Helpers.GetScaledSize(10) }
				}}
		value={_countryValue}
		pickerProps={{ style: { fontSize:120 } }}
		onValueChange={val => { CountryPickerValue(val); }}
		useNativeAndroidPickerStyle={false}
		Icon={() => { return (<View style={{ backgroundColor: "transparent", borderTopWidth: Helpers.GetScaledSize(13.33), borderTopColor: "gray", borderRightWidth: Helpers.GetScaledSize(13.33), borderRightColor: "transparent", borderLeftWidth: Helpers.GetScaledSize(13.33), borderLeftColor: "transparent", width: 0, height: 0, paddingTop: 30 }} /> ); }}
	/>);*/


	if (Platform.OS=="ios") {
		for (let i=0; i<countries.length; i++) {
			choices.push({"label": countries[i].name, "value": countries[i].numeric});
		}

		_SetCountryPicker(<View style={[styles.inputDropdown,{marginBottom:Helpers.GetScaledSize(13.33)}]}>
			<PickeriOS items={choices} value={_countryValue} placeholder={{ label: "pick a country", value: null, color: "#9EA0A4" }}
				onValueChange={val => { CountryPickerValue(val); }}
				style={{ inputIOS: { fontSize: Helpers.GetScaledSize(18.67), paddingVertical: Helpers.GetScaledSize(8), paddingHorizontal: Helpers.GetScaledSize(6.67), borderWidth: 1, borderColor: "gray", borderRadius: Helpers.GetScaledSize(5.33), color: "black", paddingRight: Helpers.GetScaledSize(53.33) }, iconContainer: { top: Helpers.GetScaledSize(17.33), right: Helpers.GetScaledSize(6.67) } }}
				useNativeAndroidPickerStyle={false}	textInputProps={{ underlineColor: "yellow" }}
				Icon={() => { return (<View style={{ backgroundColor: "transparent", borderTopWidth: Helpers.GetScaledSize(13.33), borderTopColor: "gray", borderRightWidth: Helpers.GetScaledSize(13.33), borderRightColor: "transparent", borderLeftWidth: Helpers.GetScaledSize(13.33), borderLeftColor: "transparent", width: 0, height: 0 }} /> ); }}
			/>
		</View>);
	} else {
		choices.push(<Picker.Item label="pick a country" value={0} key={0} />);
		for (let i=0; i<countries.length; i++) {
			choices.push(<Picker.Item label={countries[i].name} value={countries[i].numeric} key={countries[i].numeric} />);
		}

		_SetCountryPicker(<View style={[styles.inputDropdown,{marginBottom:Helpers.GetScaledSize(13.33)}]}>
			<Picker selectedValue={_countryValue} style={[styles.text, { height: Helpers.GetScaledSize(40), width: Helpers.GetScaledSize(306) }]}
				onValueChange={(val, idx) => CountryPickerValue(val, idx)}>
				{choices}
			</Picker>
		</View>);
	}

	_SetNeedPostal(_countryValue==840 || _countryValue==826 || _countryValue==124);
}

function CountryPickerValue(val, idx) {
	_countryValue = val;
	DisplayCountryPicker();
}

async function CreateUser() {
	let userSave = {
		"firstName": _firstName.trim(),
		"lastName": _lastName.trim(),
		"email": _email.trim(),
		"country": _countryValue
	};
	if (_postalCode) {
		userSave.postalCode = _postalCode.trim();
	}

	if (userSave.firstName.length<1) {
		Alert.alert(lang.needFirstName, lang.enterFirstName, [{text: lang.ok}]);
		return;
	}

	if (userSave.lastName.length<1) {
		Alert.alert(lang.needLastName, lang.enterLastName, [{text: lang.ok}]);
		return;
	}

	if (userSave.email.length<6) {
		Alert.alert(lang.needEmail, lang.enterEmail, [{text: lang.ok}]);
		return;
	}

	const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!emailRE.test(userSave.email)) {
		Alert.alert(lang.needEmail, lang.correctEmail, [{text: lang.ok}]);
		return false;
	}

	if (userSave.country<=0) {
		Alert.alert(lang.needCountry, lang.enterCountry, [{text: lang.ok}]);
		return;
	}

	if (_needPostal && (!userSave.postalCode || userSave.postalCode.length==0)) {
		Alert.alert(lang.needPostal, lang.enterPostal, [{text: lang.ok}]);
		return;
	}

	if (_password1!=_password2 || _password1.length<4) {
		Alert.alert(lang.needPassword, lang.enterPassword, [{text: lang.ok}]);
		return;
	}

	await _SetIsLoading(true);
	if (await API.Rest.UserCreate(userSave.firstName, userSave.lastName, userSave.email, _password1, userSave.country, userSave.postalCode)) {
		Alert.alert(lang.emailVerification, "Please check your email at " + _email + " to verify this address.",
			[{text: lang.ok, style: "cancel"}]
		);

		_SetIsLoading(false);
		Helpers.Navigate("LeagueNav", { screen: "Leagues" });
	} else {
		_SetIsLoading(false);
		Alert.alert(lang.error, "Unable to create account.", [{text: lang.ok}]);
	}
}

SignUpScreen.navigationOptions = {
	header: null,
	title: "Sign Up",
};
