import React from "react";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import * as Linking from "expo-linking";

let _isWeb = false;
let Alert = null;
if (Platform.OS=="web") {
	_isWeb = true;
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


import Helpers from "../classes/Helpers";
import API from "../classes/API";

const config = require("../config.json");
const lang = require("../constants/lang-en.json");


let _props = null;
let _isAdded = false;


export default function AuthLoadingScreen(props) {
	_props = props;
	Helpers.SetNavigator(props.navigation);

	console.log("1**** AuthLoadingScreen *****");
	console.log("2**** AuthLoadingScreen *****");
	console.log("3**** AuthLoadingScreen *****");


	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);

		if (_isAdded) {
			console.log("1**** ALREADY ADDED *****");
			console.log("2**** ALREADY ADDED *****");
			console.log("3**** ALREADY ADDED *****");
			//return;
		}
		_isAdded = true;

		if (Platform.OS=="web") {
			return;
		}

		console.log("1**** ADDING *****");
		console.log("2**** ADDING *****");
		console.log("3**** ADDING *****");

		Linking.addEventListener("url", HandleLink);

		return () => {
			Linking.removeEventListener("url", HandleLink);
			console.log("REMOVING link handler");
			_isAdded = false;
		};
	}, []);

	return (null);
}

async function OnFocus() {
	console.log("AuthLoading - OnFocus");
	await BootstrapAsync();
}

async function BootstrapAsync() {
	try {
		global.auth = await AsyncStorage.getItem("auth");

		let ctx = await AsyncStorage.getItem("context");

		if (ctx && ctx.length>5) {
			console.log("!!!!HAVE CONTEXT!!!!! length: " + ctx.length);
			global.context = JSON.parse(ctx);
		}

		if (!global.temp) {
			global.temp = {};
		}

		if (global.serverNum==null || global.serverNum==-1) {
			API.Rest.FindBestServer();
		}

		let keepContext = global.context && (global.context.UntappdLoginCallbackWeb || global.context.loginInfo);

		if (!global.auth || global.auth.length<10 || !global.context) {
			if (!keepContext) {
				global.auth = null;
				global.context = {};
				global.temp = {};
			}
		}

		await LinkingInit();
		await API.Rest.CheckVersion();

		if (!global.processingUrl) {
			Helpers.Navigate(global.auth ? "LeagueNav" : "SignIn");
		}

		global.processingUrl = false;
	} catch (ex) {
		console.log("AuthLoadingScreen ERROR!!!");
		console.log(ex);
	}
}

async function LinkingInit() {
	try {
		await Linking.getInitialURL().then((url) => {
			ProcessInitURL(url);
		}).catch(err => console.log("Error with initial url", err));
	} catch (ex) {
		console.log("LinkingInit error");
		console.log(ex);
	}
}

function HandleLink(event) {
	ProcessInitURL(event.url);
}

let urlTime = 0;
function ProcessInitURL(url) {
	if (!url) {
		return;
	}

	console.log("ProcessInitURL: " + url);
	let now = new Date().valueOf();

	if (now-urlTime<1500) {
		console.log("too soon");
		return;
	}
	urlTime = now;

	if (url.length==118 && url.substring(0,47)=="https://playfbl.com/inapp/passwordReset?userId=") {
		GoToPasswordReset(url);
	} else if (url.length==82 && url.substring(0,48)=="https://playfbl.com/inapp/inviteAccept?inviteId=") {
		console.log("have an invite...");
		if (!global.context || !global.context.userId) {
			//TODO save link as pending?
			Alert.alert(lang.error, "Please login and try the invitation link again.", [{text: lang.ok}]);
			return;
		}

		AcceptInvite(url);
	} else if (!_isWeb && ((url.length>25 && url.substring(0,25)=="fbl://untappd_login?code=")
		|| (url.length>43 && url.substring(0,54)=="https://web.playfbl.com/inapp/untappd_login.html?code="))) {
		let idx = url.substring(0,3)=="fbl" ? 25 : 54;
		let code = url.substring(idx);
		UntappdProcess(code);
	} else if (url=="https://web.playfbl.com/.well-known/apple-app-site-association") {
		global.processingUrl = true;
		console.log("url MATCH: " + url);
	} else if (url.indexOf("web.playfbl.com/inapp/")>=0) { // || url.indexOf("web.playfbl.com/callback/")>=0
		console.log("matched inapp"); // or callback
		global.processingUrl = true;
	} else {
		console.log("no url match: " + url);
	}

	if (_isWeb) {
		if (url.substring(0,51)=="https://web.playfbl.com/callback/untappd_auth?code=") {
			UntappdProcess(url.substring(51));
		} else if (url.substring(0,47)=="https://web.playfbl.com/callback/apple_auth?id=") {
			AppleProcess(url.substring(47));
		}
	}
}

async function UntappdProcess(code) {
	if (global.context.UntappdLoginCallback) {
		global.context.UntappdLoginCallback(code);
		global.context.UntappdLoginCallback = null;
	} else if (global.context.UntappdLoginCallbackWeb) {
		global.context.UntappdLoginCallbackWeb = null;

		if (await API.Rest.UserLoginUntappd(code, global.context.pushToken)) {
			Helpers.Navigate("LeagueNav", { screen: "Leagues" });
		} else {
			Alert.alert(lang.error, "Unable to login with Untappd.", [{text: lang.ok}]);
		}
	} else {
		API.SeasonWS.UntappdAuth({"code": code}, UntappdCallback);
	}
}

function UntappdCallback(success) {
	console.log("UntappdCallback " + success);
	if (success) {
		let user = Helpers.UserFind(global.context.userId);
		user.untappdAuth = true;

		global.context.goodUntappd = true;

		console.log("checking for pendingSearch");

		if (global.context.pendingSearch && global.context.pendingSearch.method) {
			global.context.pendingSearch.method(global.context.pendingSearch.text);
			global.context.pendingSearch = null;
		}
	} else {
		Alert.alert(lang.error, "Error validating Untappd login.", [{text: lang.ok}]);
	}
}

async function AppleProcess(data) {
	let idx = data.indexOf("&");

	if (idx<0) {
		Alert.alert(lang.error, "Invalid data from Apple.", [{text: lang.ok}]);
	}

	let userId = data.substr(0,idx);

	idx = data.indexOf("&token=");
	if (idx<0) {
		Alert.alert(lang.error, "Invalid data from Apple.", [{text: lang.ok}]);
	}

	let token = data.substr(idx+7);

	global.context.loginInfo = { "userId": userId, "token": token };
	await Helpers.SaveContext();

	Helpers.OpenURL("https://web.playfbl.com", true);
}


function GoToPasswordReset(url) {
	// https://playfbl.com/inapp/passwordReset?userId=u:a251c0a810ec41c598ba41f8fb1fd892&key=4aad0ce0dfd34433a383e2393fc78f20
	global.context.passwordResetUserId = url.substring(47, 81);
	global.context.passwordResetKey = url.substring(86, 118);

	Helpers.Navigate("PasswordReset");
}

function AcceptInvite(url) {
	// https://playfbl.com/inapp/inviteAccept?inviteId=i:a251c0a810ec41c598ba41f8fb1fd892
	let inviteId = url.substring(48, 82);
	API.Rest.UI.InviteAcceptById(inviteId);
}


AuthLoadingScreen.navigationOptions = {
	header: null,
};
