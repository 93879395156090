import React, { useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { Image, Text, TextInput } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}

import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";

const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";

const lang = require("../constants/lang-en.json");


let styles = null;


let _props = null;

let _email1 = null;
let _SetEmail1 = null;
let _email2 = null;
let _SetEmail2 = null;
let _email3 = null;
let _SetEmail3 = null;
let _email4 = null;
let _SetEmail4 = null;

let _invites = null;

let _inviteList = null;
let _SetInviteList = null;


export default function LeagueInviteScreen(props) {
	styles = global.styles;
	_props = props;
	[ _inviteList, _SetInviteList ] = useState();
	[ _email1, _SetEmail1 ] = useState("");
	[ _email2, _SetEmail2 ] = useState("");
	[ _email3, _SetEmail3 ] = useState("");
	[ _email4, _SetEmail4 ] = useState("");

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		return unsubscribe;
	}, [props.navigation]);

	return (<KeyboardAwareScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">
		<View style={styles.mainBox}>

			{ UIHelpers.Header(lang.inviteToLeague) }
			<View style={styles.box}>
				<TextInput placeholder="email address" {...PlaceholderProps} style={styles.inputBox} value={_email1} autoCapitalize="none" onChangeText={(value) => { _SetEmail1(value); }} />
				<View style={{paddingTop:Helpers.GetScaledSize(5.33)}} />
				<TextInput placeholder="email address" {...PlaceholderProps} style={styles.inputBox} value={_email2} autoCapitalize="none" onChangeText={(value) => { _SetEmail2(value); }} />
				<View style={{paddingTop:Helpers.GetScaledSize(5.33)}} />
				<TextInput placeholder="email address" {...PlaceholderProps} style={styles.inputBox} value={_email3} autoCapitalize="none" onChangeText={(value) => { _SetEmail3(value); }} />
				<View style={{paddingTop:Helpers.GetScaledSize(5.33)}} />
				<TextInput placeholder="email address" {...PlaceholderProps} style={styles.inputBox} value={_email4} autoCapitalize="none" onChangeText={(value) => { _SetEmail4(value); }} />
				<View style={{paddingTop:Helpers.GetScaledSize(13.33)}} />
				<TouchableOpacityDB style={styles.button} onPress={SendInvites}>
					<Text style={styles.buttonText}>{lang.invitePeople}</Text>
				</TouchableOpacityDB>

				<View style={{paddingTop: Helpers.GetScaledSize(33.33)}}></View>

				<TouchableOpacityDB style={styles.button} onPress={GoToLeague}>
					<Text style={styles.buttonText}>{lang.goToLeagueOverview}</Text>
				</TouchableOpacityDB>
				<Text style={[styles.text,{fontSize:Helpers.GetScaledSize(15.17)}]}>{lang.youCanSendMoreInvites}</Text>
			</View>

			<View style={{paddingTop: Helpers.GetScaledSize(22.67)}} />

			{ UIHelpers.Header(lang.currentInvites) }
			<View style={styles.box}>
				{_inviteList}
			</View>
		</View>
	</KeyboardAwareScrollView>);
}

async function OnFocus() {
	DisplayInvites();
}

async function SendInvites() {
	const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (_email1.length>0 && !emailRE.test(_email1)) {
		Alert.alert(lang.error, "Please correct " + _email1, [{text: lang.ok}]);
		return false;
	}

	if (_email2.length>0 && !emailRE.test(_email2)) {
		Alert.alert(lang.error, "Please correct " + _email2, [{text: lang.ok}]);
		return false;
	}

	if (_email3.length>0 && !emailRE.test(_email3)) {
		Alert.alert(lang.error, "Please correct " + _email3, [{text: lang.ok}]);
		return false;
	}

	if (_email4.length>0 && !emailRE.test(_email4)) {
		Alert.alert(lang.error, "Please correct " + _email4, [{text: lang.ok}]);
		return false;
	}

	if ((_email1.length + _email2.length + _email3.length + _email4.length) == 0) {
		Alert.alert(lang.error, lang.enterEmail, [{text: lang.ok}]);
		return false;
	}

	if (await API.Rest.LeagueInvitesSend([_email1.toLowerCase(), _email2.toLowerCase(), _email3.toLowerCase(), _email4.toLowerCase()])) {
		Alert.alert(lang.success, lang.invitationsSent, [{text: lang.ok}]);
		_SetEmail1("");
		_SetEmail2("");
		_SetEmail3("");
		_SetEmail4("");
		DisplayInvites();
	} else {
		Alert.alert(lang.error, lang.correctEmails, [{text: lang.ok}]);
	}
}

async function DisplayInvites() {
	console.log("DisplayInvites");

	_invites = await API.Rest.LeagueInvitesLoad();

	if (!_invites) {
		_SetInviteList(<Text style={styles.title2}>{lang.unableToLoadInvites}</Text>);
		return;
	}

	if (_invites.length==0) {
		_SetInviteList(<Text style={styles.title2}>{lang.noInvites}</Text>);
		return;
	}

	_SetInviteList(_invites.map((item) => (
		<View style={{flexDirection:"row", justifyContent:"center", alignItems:"center"}} key={item._id}>
			<Text style={[styles.title2,{paddingTop:Helpers.GetScaledSize(4), paddingBottom:Helpers.GetScaledSize(4)}]}>{item.email}</Text>
			<View style={{paddingLeft:Helpers.GetScaledSize(6.67)}}/>
			<TouchableOpacityDB onPress={()=>{DeleteConfirm(item);}}>
				<Image source={require("../assets/icons/delete.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
			</TouchableOpacityDB>
			<View style={{paddingLeft:Helpers.GetScaledSize(10.67)}}/>
			<TouchableOpacityDB style={{paddingLeft:Helpers.GetScaledSize(6.67)}} onPress={()=>{ResendConfirm(item);}}>
				<Image source={require("../assets/icons/email_send.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
			</TouchableOpacityDB>
		</View>))
	);
}

function GoToLeague() {
	Helpers.Navigate("LeagueNav", { screen: "LeagueOverview"});
}

async function DeleteConfirm(item) {
	Alert.alert(lang.deleteInvite, "Delete invite for " + item.email + "?",
		[{text: lang.cancel, style: "cancel"}, {text: "Delete", onPress: () => DeleteInvite(item._id)}],
		{cancelable: false},
	);
}

async function DeleteInvite(id) {
	await API.Rest.InviteDelete(id);
	DisplayInvites();
}

async function ResendConfirm(item) {
	Alert.alert(lang.resendInvite, "Resend invite for " + item.email + "?",
		[{text: lang.cancel, style: "cancel"}, {text: "Resend", onPress: () => ResendInvite(item._id)}],
		{cancelable: false},
	);
}

async function ResendInvite(id) {
	if (await API.Rest.InviteResend(id)) {
		Alert.alert(lang.success, lang.invitationResent, [{text: lang.ok}]);
	} else {
		Alert.alert(lang.error, lang.invitationResendFail, [{text: lang.ok}]);
	}
}

LeagueInviteScreen.navigationOptions = {
	header: null,
};
