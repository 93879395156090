import React, { useState } from "react";
import { Platform, TouchableOpacity, View, ActivityIndicator } from "react-native";
import { Image, Text, TextInput, Picker, Switch } from "react-native";
import PickeriOS from "react-native-picker-select";

let Alert = null;
let _isWeb = false;
if (Platform.OS=="web") {
	_isWeb=true;
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


import Constants from "expo-constants";
import * as Permissions from "expo-permissions";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import * as FileSystem from "expo-file-system";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";

const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";


let styles = null;
const config = require("../config.json");
const countries = require("../constants/countries.json");
const lang = require("../constants/lang-en.json");


let _props = null;

let _user = null;

let _firstName = null;
let _SetFirstName = null;
let _lastName = null;
let _SetLastName = null;

let _email = null;
let _SetEmail = null;

let _emailPending = null;
let _SetEmailPending = null;

let _imageChanged = false;
let _imageSource = null;
let _SetImageSource = null;

let _countryValue = 840;
let _countryPicker = null;
let _SetCountryPicker = null;

let _needPostal = false;
let _SetNeedPostal = null;
let _postalCode = null;
let _SetPostalCode = null;

let _pushNoBeer = null;
let _SetPushNoBeer = null;
let _pushGameSoon = null;
let _SetPushGameSoon = null;
let _pushTeamsLocked = null;
let _SetPushTeamsLocked = null;

let _isLoading = null;
let _SetIsLoading = null;


export default function UserSettingsScreen(props) {
	styles = global.styles;
	_props = props;
	[ _isLoading, _SetIsLoading ] = useState(false);
	[ _firstName, _SetFirstName ] = useState("");
	[ _lastName, _SetLastName ] = useState("");
	[ _email, _SetEmail ] = useState("");
	[ _emailPending, _SetEmailPending ] = useState();
	[ _imageSource, _SetImageSource ] = useState(require("../assets/icons/no_image.png"));
	[ _countryPicker, _SetCountryPicker ] = useState();
	[ _postalCode, _SetPostalCode ] = useState("");
	[ _needPostal, _SetNeedPostal ] = useState(true);

	[ _pushNoBeer, _SetPushNoBeer ] = useState(true);
	[ _pushGameSoon, _SetPushGameSoon ] = useState(true);
	[ _pushTeamsLocked, _SetPushTeamsLocked ] = useState(true);



	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		return unsubscribe;
	}, [props.navigation]);

	return (<KeyboardAwareScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">
		<View style={styles.mainBox}>
			{UIHelpers.Header(lang.userSettings)}
			<View style={styles.box}>
				<View style={{flexDirection: "row", marginBottom:Helpers.GetScaledSize(13.33)}}>
					<TextInput placeholder={lang.firstName} {...PlaceholderProps} autoCapitalize="none"
						style={[styles.inputBox, {width:Helpers.GetScaledSize(150)}]}
						value={_firstName} onChangeText={(value) => { _SetFirstName(value); }} />
					<View style={{paddingLeft:Helpers.GetScaledSize(5.33)}}/>
					<TextInput placeholder={lang.lastName} {...PlaceholderProps} autoCapitalize="none"
						style={[styles.inputBox, {width:Helpers.GetScaledSize(150)}]}
						value={_lastName} onChangeText={(value) => { _SetLastName(value); }} />
				</View>

				<TextInput placeholder={lang.email} {...PlaceholderProps} autoCapitalize="none"
					style={styles.inputBox}
					value={_email} onChangeText={(value) => { _SetEmail(value); }} />
				{_emailPending}
				<View style={{marginBottom:Helpers.GetScaledSize(13.33)}}></View>

				{_countryPicker}
				{_needPostal && <TextInput placeholder={lang.postalCode} {...PlaceholderProps} autoCapitalize="none"
					style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_postalCode} onChangeText={(value) => { _SetPostalCode(value); }} />}

				<TouchableOpacityDB onPress={UserPickImage}>
					<Image source={_imageSource} style={{width: Helpers.GetScaledSize(160), height: Helpers.GetScaledSize(160), borderRadius: Helpers.GetScaledSize(80)}} />
				</TouchableOpacityDB>

				<View style={{paddingTop: Helpers.GetScaledSize(26.67)}}></View>
				<View style={{flexDirection: "row"}}>
					<TouchableOpacityDB style={styles.button} onPress={SaveUser}>
						<Text style={styles.buttonText}>Save</Text>
					</TouchableOpacityDB>
				</View>
			</View>

			{UIHelpers.Header(lang.pushNotifications, null, 20)}
			<View style={styles.box}>
				<View style={{flexDirection: "row", alignItems:"center"}}>
					<Text style={styles.title2}>No Beer Selected</Text>
					{ (Platform.OS=="ios" || global.screen.scaleConversion>=1.5) && <View style={{paddingRight:Helpers.GetScaledSize(13.33)}} /> }
					{ _isWeb && <View style={{paddingRight:Helpers.GetScaledSize(16)}} /> }
					<Switch value={_pushNoBeer} onValueChange={val => { TogglePushNotify("noBeer", val); }} style={{ transform: [{ scaleX: global.screen.scaleConversion}, {scaleY: global.screen.scaleConversion}] }} />
				</View>

				<View style={{flexDirection: "row", alignItems:"center", paddingTop: Helpers.GetScaledSize(20)}}>
					<Text style={styles.title2}>Tasting Reminder</Text>
					{ (Platform.OS=="ios" || global.screen.scaleConversion>=1.5) && <View style={{paddingRight:Helpers.GetScaledSize(13.33)}} /> }
					{ _isWeb && <View style={{paddingRight:Helpers.GetScaledSize(16)}} /> }
					<Switch value={_pushGameSoon} onValueChange={val => { TogglePushNotify("gameSoon", val); }} style={{ transform: [{ scaleX: global.screen.scaleConversion}, {scaleY: global.screen.scaleConversion}] }} />
				</View>

				<View style={{flexDirection: "row", alignItems:"center", paddingTop: Helpers.GetScaledSize(20)}}>
					<Text style={styles.title2}>Tasting Ready for Beers</Text>
					{ (Platform.OS=="ios" || global.screen.scaleConversion>=1.5) && <View style={{paddingRight:Helpers.GetScaledSize(13.33)}} /> }
					{ _isWeb && <View style={{paddingRight:Helpers.GetScaledSize(16)}} /> }
					<Switch value={_pushTeamsLocked} onValueChange={val => { TogglePushNotify("teamsLocked", val); }} style={{ transform: [{ scaleX: global.screen.scaleConversion}, {scaleY: global.screen.scaleConversion}] }} />
				</View>

				<View style={{paddingTop: Helpers.GetScaledSize(26.67)}}></View>
				<View style={{flexDirection: "row"}}>
					<TouchableOpacityDB style={styles.button} onPress={SaveUser}>
						<Text style={styles.buttonText}>Save</Text>
					</TouchableOpacityDB>
				</View>
			</View>

			{UIHelpers.Header(lang.administration, null, 20)}
			<View style={styles.box}>
				<TouchableOpacityDB style={styles.button} onPress={Logout}>
					<Text style={styles.buttonText}>Logout</Text>
				</TouchableOpacityDB>

				<View style={{paddingTop: Helpers.GetScaledSize(20)}}></View>
				<TouchableOpacityDB style={styles.button} onPress={UserDeleteConfirm}>
					<Text style={styles.buttonText}>Delete User</Text>
				</TouchableOpacityDB>
			</View>
		</View>
		{ _isLoading && <View style={styles.loading}>
			<ActivityIndicator {...ActivityIndicatorProps} />
		</View> }
	</KeyboardAwareScrollView>);
}

function TogglePushNotify(type, val) {
	switch (type) {
		case "noBeer":
			_SetPushNoBeer((val!=null) ? val : !_pushNoBeer);
			break;
		case "gameSoon":
			_SetPushGameSoon((val!=null) ? val : !_pushGameSoon);
			break;
		case "teamsLocked":
			_SetPushTeamsLocked((val!=null) ? val : !_pushTeamsLocked);
			break;
	}
}

async function OnFocus() {
	GetPermission();
	await _SetIsLoading(true);
	await LoadSettings();
	await _SetIsLoading(false);
	if (_user) {
		DisplayCountryPicker();
	}
}

async function GetPermission() {
	if (Constants.platform.ios) {
		const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
		if (status !== "granted") {
			alert("Camera roll permission needed to open images.");
		}
	}
}

async function Logout() {
	Helpers.GoToLogin();
}

function DisplayCountryPicker() {
	let choices = [];

	if (Platform.OS=="ios") {
		for (let i=0; i<countries.length; i++) {
			choices.push({"label": countries[i].name, "value": countries[i].numeric});
		}

		_SetCountryPicker(<View style={[styles.inputDropdown,{marginBottom:Helpers.GetScaledSize(13.33)}]}>
			<PickeriOS items={choices} value={_countryValue} placeholder={{ label: "pick a country", value: null, color: "#9EA0A4" }}
				onValueChange={val => { CountryPickerValue(val); }}
				style={{ inputIOS: { fontSize: Helpers.GetScaledSize(18.67), paddingVertical: Helpers.GetScaledSize(8), paddingHorizontal: Helpers.GetScaledSize(6.67), borderWidth: 1, borderColor: "gray", borderRadius: 4, color: "black", paddingRight: Helpers.GetScaledSize(53.33) }, iconContainer: { top: Helpers.GetScaledSize(17.33), right: Helpers.GetScaledSize(6.67) } }}
				useNativeAndroidPickerStyle={false}	textInputProps={{ underlineColor: "yellow" }}
				Icon={() => { return (<View style={{ backgroundColor: "transparent", borderTopWidth: Helpers.GetScaledSize(13.33), borderTopColor: "gray", borderRightWidth: Helpers.GetScaledSize(13.33), borderRightColor: "transparent", borderLeftWidth: Helpers.GetScaledSize(13.33), borderLeftColor: "transparent", width: 0, height: 0 }} /> ); }}
			/>
		</View>);
	} else {
		choices.push(<Picker.Item label="pick a country" value={0} key={0} />);
		for (let i=0; i<countries.length; i++) {
			choices.push(<Picker.Item label={countries[i].name} value={countries[i].numeric} key={countries[i].numeric} />);
		}

		_SetCountryPicker(<View style={[styles.inputDropdown,{marginBottom:Helpers.GetScaledSize(13.33)}]}>
			<Picker selectedValue={_countryValue} style={{ height: Helpers.GetScaledSize(40), width: Helpers.GetScaledSize(266.67) }}
				onValueChange={(val, idx) => CountryPickerValue(val, idx)}>
				{choices}
			</Picker>
		</View>);
	}

	_SetNeedPostal(_countryValue==840 || _countryValue==826 || _countryValue==124);
}

function CountryPickerValue(val, idx) {
	_countryValue = val;
	DisplayCountryPicker();
}

async function UserDeleteConfirm() {
	Alert.alert("Delete User", "Are you sure you want to delete your account?",
		[{text: lang.cancel, style: "cancel"}, {text: lang.delete, onPress: () => UserDelete()}]
		//TODO make them type delete
	);
}

async function UserDelete() {
	//TODO API.Rest.UserDelete;
}

async function UserPickImage() {
	let result = await ImagePicker.launchImageLibraryAsync({
		mediaTypes: ImagePicker.MediaTypeOptions.Images,
		allowsEditing: false,
		aspect: [4, 4],
		//quality: 0 to 1
	});

	if (!result.cancelled) {
		UserProcessImage(result.uri);
	}
};


async function GetImageInfo(uri) {
	if (_isWeb) {
		return { "exists": true, "size": (uri.length*.75) };
	} else {
		return await FileSystem.getInfoAsync(uri, { size: true });
	}
}

async function UserProcessImage(uri) {
	let info = await GetImageInfo(uri);

	if (!info.exists) {
		console.log("Image file does not exist");
		return;
	}

	let type = "";
	if (_isWeb) {
		if (uri.substr(0,23)=="data:image/jpeg;base64,") {
			type = "jpeg";
		} else if (uri.substr(0,22)=="data:image/png;base64,") {
			type = "png";
		} else if (uri.substr(0,22)=="data:image/jpg;base64,") {
			type = "jpeg";
		}
	} else {
		type = uri.substring(uri.length-3).toLowerCase();
		if (type=="jpg") {
			type="jpeg";
		}
	}

	let data = null;
	let width=750;

	while (info.size>150000 || !(type=="jpeg" || type=="png")) {
	console.log("trying width " + width);
		data = await ImageManipulator.manipulateAsync(uri, [ { resize: { width: width } } ],
			{ compress: 0.78, format: ImageManipulator.SaveFormat.JPEG, base64: false });

		info = await GetImageInfo(data.uri);
		width-=100;
		if (width<50) {
			console.log("unable to resize image");
			data=null;
			uri=null;
			break;
		}
	}

	if (data) {
		_SetImageSource({ uri: data.uri });
	} else if (uri) {
		_SetImageSource({ uri: uri });
	}

	_imageChanged = true;
}

async function LoadSettings() {
	_imageChanged = false;
	_user = await API.Rest.UserLoad();

	if (!_user) {
		return;
	}

	_SetFirstName(_user.firstName);
	_SetLastName(_user.lastName);
	_SetEmail(_user.email);

	if (!_user.pushOptOuts) {
		_user.pushOptOuts=[];
	}
	_SetPushNoBeer(_user.pushOptOuts.indexOf("noBeer")==-1);
	_SetPushGameSoon(_user.pushOptOuts.indexOf("gameSoon")==-1);
	_SetPushTeamsLocked(_user.pushOptOuts.indexOf("teamsLocked")==-1);


	if (!_user.emailVerify || !_user.emailVerify.email) {
		_SetEmailPending();
	} else {
		_SetEmailPending(<View style={{flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
			<Text style={[styles.textWarning,{fontSize: Helpers.GetScaledSize(12.83)}]}>
				{ _user.email==_user.emailVerify.email && "email is not verified" }
				{ _user.email!=_user.emailVerify.email && "pending change to " + _user.emailVerify.email }
			</Text>
			<TouchableOpacityDB style={{paddingLeft:Helpers.GetScaledSize(6.67)}} onPress={EmailVerifyResend}>
				<Image source={require("../assets/icons/email_send.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
			</TouchableOpacityDB>
		</View>);
	}

	_countryValue = _user.country;
	_SetPostalCode(_user.postalCode);

	if (_user.image) {
		let server = global.imageBase; //config.imageServers[_user.image.loc]
		_SetImageSource({ uri: server + _user.image.name + ".jpg" });
	}
}

async function EmailVerifyResend() {
	if (await API.Rest.EmailVerifyResend()) {
		Alert.alert("Email Verify", "Resent verification email.", [{text: lang.ok}]);
	} else {
		Alert.alert(lang.error, "Error resending verification email.", [{text: lang.ok}]);
	}
}

async function SaveUser() {
	let userSave = {
		"firstName": _firstName.trim(),
		"lastName": _lastName.trim(),
		"email": _email.toLowerCase().trim(),
		"country": _countryValue
	};
	if (_postalCode) {
		userSave.postalCode = _postalCode.trim();
	}

	if (userSave.firstName.length<1) {
		Alert.alert(lang.needFirstName, lang.enterFirstName, [{text: lang.ok}]);
		return;
	}

	if (userSave.lastName.length<1) {
		Alert.alert(lang.needLastName, lang.enterLastName, [{text: lang.ok}]);
		return;
	}

	if (userSave.email.length<6) {
		Alert.alert(lang.needEmail, lang.enterEmail, [{text: lang.ok}]);
		return;
	}

	const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (!emailRE.test(userSave.email)) {
		Alert.alert(lang.needEmail, lang.correctEmail, [{text: lang.ok}]);
		return false;
	}

	if (userSave.country<=0) {
		Alert.alert(lang.needCountry, lang.enterCountry, [{text: lang.ok}]);
		return;
	}

	if (_needPostal && (!userSave.postalCode || userSave.postalCode.length==0)) {
		Alert.alert(lang.needPostal, lang.enterPostal, [{text: lang.ok}]);
		return;
	}

	await _SetIsLoading(true);

	if (_imageChanged) {
		let imageData = null;
		if (_isWeb) {
			let idx = _imageSource.uri.indexOf(",");
			imageData = _imageSource.uri.substr(idx+1);
		} else {
			imageData = await FileSystem.readAsStringAsync(_imageSource.uri, { encoding: FileSystem.EncodingType.Base64 });
		}

		let imageInfo = await API.Rest.ImageSave(imageData);

		if (!imageInfo) {
			Alert.alert(lang.error, lang.unableToUploadImage, [{text: lang.ok}]);
			await _SetIsLoading(false);
			return;
		}

		_user.image = imageInfo;
		userSave.image = imageInfo;

		if (global.context.season) {
			// also update the team image if there isn't one
			for (let i=0; i<global.context.season.teams.length; i++) {
				if (global.context.season.teams[i].ownerIds.indexOf(global.context.userId)==-1) {
					continue;
				}

				let team=global.context.season.teams[i];
				if (!team.image) {
					team.image=imageInfo;
					await API.Rest.TeamSave(team._id, team.name, team.image);
				}

				break;
			}
		}
	}

	let pushOptOuts = GetPushOptOuts();
	userSave.pushOptOuts = pushOptOuts;

	if (await API.Rest.UserSave(userSave)) {
		_user.firstName = userSave.firstName;
		_user.lastName = userSave.lastName;
		_user.country = userSave.country;
		_user.postalCode = userSave.postalCode;
		_user.pushOptOuts = pushOptOuts;

		if (_user.email!=userSave.email) {
			Alert.alert("Email Verification", "Please check your email at " + userSave.email + " to verify this address.",
				[{text: lang.ok, style: "cancel"}]
			);
		}

		Alert.alert("Saved", "User info updated.", [{text: lang.ok}]);
		//TODO show saved on screen
	} else {
		if (global.temp.error) {
			Alert.alert(lang.error, global.temp.error, [{text: lang.ok}]);
			delete global.temp.error;
		} else {
			Alert.alert(lang.error, "Unable to update user.", [{text: lang.ok}]);
		}
	}

	await _SetIsLoading(false);
}

function GetPushOptOuts() {
	let optOuts = [];
	if (!_pushNoBeer) {
		optOuts.push("noBeer");
	}
	if (!_pushGameSoon) {
		optOuts.push("gameSoon");
	}
	if (!_pushTeamsLocked) {
		optOuts.push("teamsLocked");
	}

	return optOuts;
}

UserSettingsScreen.navigationOptions = {
	header: null,
};
