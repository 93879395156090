import React, { useState } from "react";
import { Platform, TouchableOpacity, View, ActivityIndicator } from "react-native";
import { Image, Text, TextInput } from "react-native";

let Alert = null;
let _isWeb = false;
if (Platform.OS=="web") {
	_isWeb=true;
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}

import Constants from "expo-constants";
import * as Permissions from "expo-permissions";
import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import * as FileSystem from "expo-file-system";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";

const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";


let styles = null;
const config = require("../config.json");
const lang = require("../constants/lang-en.json");


let _props = null;

let _team = null;

let _name = null;
let _SetName = null;

let _imageChanged = false;
let _imageSource = null;
let _SetImageSource = null;

let _isLoading = null;
let _SetIsLoading = null;


export default function TeamSettingsScreen(props) {
	styles = global.styles;
	_props = props;
	[ _name, _SetName ] = useState("");
	[ _imageSource, _SetImageSource ] = useState(require("../assets/icons/no_image.png"));
	[ _isLoading, _SetIsLoading ] = useState(false);

	console.log("TeamSettingsScreen - render");
	//console.log(_name);
	//console.log(_team);

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		return unsubscribe;
	}, [props.navigation]);

	return (<KeyboardAwareScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">
		<View style={styles.mainBox}>
			{UIHelpers.Header(lang.teamSettings)}
			<View style={styles.box}>
				<TextInput placeholder="team name" {...PlaceholderProps} autoCapitalize="none"
					style={[styles.inputBox,{marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_name} onChangeText={(value) => { _SetName(value); }} />

				<TouchableOpacityDB onPress={PickImage}>
					<Image source={_imageSource} style={{width: Helpers.GetScaledSize(160), height: Helpers.GetScaledSize(160), borderRadius: Helpers.GetScaledSize(80)}} />
				</TouchableOpacityDB>

				<View style={{paddingTop: Helpers.GetScaledSize(26.67)}}></View>
				<View style={{flexDirection: "row"}}>
					<TouchableOpacityDB style={styles.button} onPress={()=>{_props.navigation.goBack();}}>
						<Text style={styles.buttonText}>Cancel</Text>
					</TouchableOpacityDB>

					<View style={{paddingLeft:Helpers.GetScaledSize(26.67)}} />
					<TouchableOpacityDB style={styles.button} onPress={TeamSave}>
						<Text style={styles.buttonText}>Save</Text>
					</TouchableOpacityDB>
				</View>
			</View>

			{UIHelpers.Header(lang.administration, null, 20)}
			<View style={styles.box}>
				<TouchableOpacityDB style={styles.button} onPress={TeamDeleteConfirm}>
					<Text style={styles.buttonText}>{lang.deleteTeam}</Text>
				</TouchableOpacityDB>
			</View>
			{ _isLoading && <View style={styles.loading}>
				<ActivityIndicator {...ActivityIndicatorProps} />
			</View> }
		</View>
	</KeyboardAwareScrollView>);
}

function OnFocus() {
	_team = global.context.team;
	global.context.team = null;

	if (_team==null) {
		console.log("don't have team, finding...");
		_team = Helpers.FindTeamByUserId(global.context.season.teams, global.context.userId);
	}

	GetPermissionAsync();
	LoadSettings();
}

async function GetPermissionAsync() {
	if (Constants.platform.ios) {
		const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
		if (status !== "granted") {
			alert("Camera roll permission needed to open images.");
		}
	}
}

async function TeamDeleteConfirm() {
	Alert.alert(lang.deleteTeam, "Are you sure you want to delete this team (" + _team.name + ")?",
		[{text: lang.cancel, style: "cancel"}, {text: lang.delete, onPress: () => TeamDelete(_team._id)}],
		{cancelable: false},
	);
}

async function TeamDelete(id) {
	if (await API.Rest.TeamDelete(id)) {
		Alert.alert(lang.success, "This team is now deleted.", [{text: lang.ok}]);
		if (global.context.isLeagueOwner) {
			_props.navigation.goBack();
		} else {
			if (!global.context.isLeagueOwner) {
				for (let i=0; i<global.context.leagues.length; i++) {
					if (global.context.leagues[i]._id==global.context.league._id) {
						global.context.leagues.splice(i,1);
						break;
					}
				}
			}
			global.context.league = null;
			global.context.season = null;
			global.context.seasonUsers = null;
			Helpers.Navigate("LeagueNav", { screen: "Leagues" });
		}
	} else {
		if (global.temp.error) {
			Alert.alert(lang.error, global.temp.error, [{text: lang.ok}]);
			delete global.temp.error;
		} else {
			Alert.alert(lang.error, "Cannot delete team.", [{text: lang.ok}]);
		}
	}
}


async function PickImage() {
	try {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			aspect: [4, 4],
			//quality: 0 to 1
		}).catch(error => console.log({ error }));

		if (!result.cancelled) {
			ProcessImage(result.uri);
		}
	} catch (ex) {
		console.log("error");
		console.log(ex);
	}

}

async function GetImageInfo(uri) {
	if (_isWeb) {
		return { "exists": true, "size": (uri.length*.75) };
	} else {
		return await FileSystem.getInfoAsync(uri, { size: true });
	}
}

async function ProcessImage(uri) {
	console.log("ProcessImage");

	let info = await GetImageInfo(uri);

	if (!info.exists) {
		console.log("Image file does not exist");
		return;
	}

	let type = "";
	if (_isWeb) {
		if (uri.substr(0,23)=="data:image/jpeg;base64,") {
			type = "jpeg";
		} else if (uri.substr(0,22)=="data:image/png;base64,") {
			type = "png";
		} else if (uri.substr(0,22)=="data:image/jpg;base64,") {
			type = "jpeg";
		}
	} else {
		type = uri.substring(uri.length-3).toLowerCase();
		if (type=="jpg") {
			type="jpeg";
		}
	}

	let data = null;
	let width=750;

	while (info.size>150000 || !(type=="jpeg" || type=="png")) {
		console.log("trying width " + width);
		data = await ImageManipulator.manipulateAsync(uri, [ { resize: { width: width } } ],
			{ compress: 0.78, format: ImageManipulator.SaveFormat.JPEG, base64: false });

		info = await GetImageInfo(data.uri);
		width-=100;
		if (width<50) {
			console.log("unable to resize image");
			data=null;
			uri=null;
			break;
		}
	}

	if (data) {
		_SetImageSource({ uri: data.uri });
	} else if (uri) {
		_SetImageSource({ uri: uri });
	}

	_imageChanged = true;
}


async function LoadSettings() {
	console.log("LoadSettings " + JSON.stringify(_team));

	_imageChanged = false;
	_SetName(_team.name);

	if (_team.image) {
		let server = global.imageBase; //config.imageServers[_team.image.loc]
		_SetImageSource({ uri: server + _team.image.name + ".jpg" });
	} else {
		_SetImageSource(require("../assets/icons/no_image.png"));
	}
}

async function TeamSave() {
	console.log("TeamSave");
	if (_name.length<1) {
		Alert.alert(lang.needName, lang.enterTeamName, [{text: lang.ok}]);
		return;
	}

	await Helpers.SetIsLoading(_SetIsLoading);

	if (_imageChanged) {
		let imageData = null;
		if (_isWeb) {
			let idx = _imageSource.uri.indexOf(",");
			imageData = _imageSource.uri.substr(idx+1);
		} else {
			imageData = await FileSystem.readAsStringAsync(_imageSource.uri, { encoding: FileSystem.EncodingType.Base64 });
		}

		let imageInfo = await API.Rest.ImageSave(imageData);

		if (!imageInfo) {
			Alert.alert(lang.error, lang.unableToUploadImage, [{text: lang.ok}]);
			await _SetIsLoading(false);
			return;
		}

		_team.image=imageInfo;

		// set user image if user doesn't have one
		for (let i=0; i<global.context.seasonUsers.length; i++) {
			if (global.context.seasonUsers[i]._id!=global.context.userId) {
				continue;
			}

			let user=global.context.seasonUsers[i];
			if (!user.image) {
				user = await API.Rest.UserLoad();

				user.image=imageInfo;
				await API.Rest.UserSave({ "image": imageInfo });
			}
		}
	}

	let teamUpdate = { "_id": _team._id, "name": _name };
	if (_team.image) {
		teamUpdate.image = _team.image;
	}
	console.log("TeamSave - saving");

	API.SeasonWS.TeamUpdate(teamUpdate, TeamUpdated);
}


async function TeamUpdated(success) {
	console.log("TeamUpdated");
	if (!success) {
		Alert.alert(lang.error, "Unable to update team.", [{text: lang.ok}]);
		return;
	}

	_props.navigation.goBack();
}


function GetUserName(id) {
	for (let i=0; i<global.context.seasonUsers.length; i++) {
		if (global.context.seasonUsers[i]._id==id) {
			return global.context.seasonUsers[i].firstName + " " + global.context.seasonUsers[i].lastName;
		}
	}

	return "unknown";
}

TeamSettingsScreen.navigationOptions = {
	header: null,
};
