import React, { useState } from "react";
import { Platform, TouchableOpacity, View, ActivityIndicator } from "react-native";
import { Text, TextInput } from "react-native";
import SafeAreaView from "react-native-safe-area-view";
//import { Slider as MobileSlider } from "@brlja/react-native-slider";
//import { Slider as WebSlider } from "@react-native-community/slider";
//import Slider from "@brlja/react-native-slider";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";


let _isWeb = false;
let Alert = null;
let Modal = null;
let Slider = null;

if (Platform.OS=="web") {
	_isWeb = true;
	Alert = require("../components/web/WebAlert").default;
	Modal = require("../components/web/WebModal").default;
	Slider = require("@react-native-community/slider").default;
} else {
	Alert = require("react-native").Alert;
	Modal = require("react-native").Modal;
	Slider = require("@brlja/react-native-slider").default;
}


import Helpers from "../classes/Helpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";
const ModalView = Platform.OS=="ios" ? SafeAreaView : View;
const TouchableOpacityDB = DebounceComponent(TouchableOpacity);
import { ScoreAnimation } from "../components/ScoreAnimation";
import { FullScreenModal } from "../components/FullScreenModal";

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";



let styles = null;
const lang = require("../constants/lang-en.json");


let _t1 = null;
let _props = null;

let _season = null;
let _game = null;
let _myTeamId = null;

let _round = null;
let _roundNum = null;
let _SetRoundNum = null;

let _activeUsers = [];

let _desc = null;
let _SetDesc = null;

let _taste = null;
let _SetTaste = null;
let _aroma = null;
let _SetAroma = null;
let _appearance = null;
let _SetAppearance = null;
let _mouthfeel = null;
let _SetMouthfeel = null;
let _comment = null;
let _SetComment = null;

let _voterInfo = null;
let _SetVoterInfo = null;

let _voteButtonLabel = null;
let _SetVoteButtonLabel = null;

let _voteModalVisible = null;
let _SetVoteModalVisible = null;

let _adminControls = null;
let _SetAdminControls = null;

let _showScores = null;
let _SetShowScores = null;

let _roundComments = null;
let _SetRoundComments = null;

//let _showVotes = null;
//let _SetShowVotes = null;

let _isLoading = null;
let _SetIsLoading = null;


let _savedForRound = 0;
let _roundPrev = 0;
let _roundNext = 0;


export default function ActiveGameScreen(props) {
	styles = global.styles;
	_props = props;
	[ _voteModalVisible, _SetVoteModalVisible] = useState(false);
	[ _voterInfo, _SetVoterInfo] = useState();
	[ _roundNum, _SetRoundNum] = useState(0);
	[ _voteButtonLabel, _SetVoteButtonLabel] = useState(lang.vote);
	[ _desc, _SetDesc] = useState("");
	[ _taste, _SetTaste] = useState(1);
	[ _aroma, _SetAroma] = useState(1);
	[ _appearance, _SetAppearance] = useState(1);
	[ _mouthfeel, _SetMouthfeel] = useState(1);
	[ _comment, _SetComment] = useState("");
	[ _adminControls, _SetAdminControls ] = useState();
	[ _showScores, _SetShowScores ] = useState(false);
	[ _roundComments, _SetRoundComments ] = useState(null);
	//[ _showVotes, _SetShowVotes ] = useState();
	[ _isLoading, _SetIsLoading ] = useState(false);


	console.log("ActiveGameScreen - render");

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		const unsubscribe2 = props.navigation.addListener("blur", OnBlur);
		//return unsubscribe;
	}, [props.navigation]);

	let prev = <View style={{paddingRight:Helpers.GetScaledSize(146.67)}} />;
	let next = <View style={{paddingRight:Helpers.GetScaledSize(146.67)}} />;

	if (_game && global.context.isLeagueOwner) {
		_roundPrev = Helpers.RoundFindPrev(_game, _roundNum);
		_roundNext = Helpers.RoundFindNext(_game, _roundNum);
		if (_roundPrev>0) {
			prev = <View style={{paddingHorizontal:Helpers.GetScaledSize(26.67), width:Helpers.GetScaledSize(146.67)}}><TouchableOpacityDB style={styles.button} onPress={() => {RoundChange(_roundPrev);}}><Text style={styles.buttonText}>Prev</Text></TouchableOpacityDB></View>;
		}
		if (_roundNext>0) {
			next = <View style={{paddingHorizontal:Helpers.GetScaledSize(26.67), width:Helpers.GetScaledSize(146.67)}}><TouchableOpacityDB style={styles.button} onPress={() => {RoundChange(_roundNext);}}><Text style={styles.buttonText}>Next</Text></TouchableOpacityDB></View>;
		}
	}

	let sliderProps = {
		style: {width:Helpers.GetScaledSize(293.33), marginHorizontal:Helpers.GetScaledSize(-20)},
		trackStyle: styles.sliderTrack,
		thumbStyle: styles.sliderThumb,
		minimumTrackTintColor: "#000000",
		maximumTrackTintColor: "#707070"
	};

	return (<SafeAreaView style={styles.contentContainer} forceInset={{top: "always"}}>
		<KeyboardAwareScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">

			<Modal animationType="fade" transparent={false} visible={_voteModalVisible}>
				<KeyboardAwareScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">
				<ModalView style={{alignItems:"center"}}>
					<Text style={[styles.textBold,{fontSize:Helpers.GetScaledSize(42)}]}>{lang.beer} #{_roundNum}</Text>
					<Text style={[styles.text,{fontSize:Helpers.GetScaledSize(16.33),paddingHorizontal:Helpers.GetScaledSize(25)}]}>{_desc}</Text>
					<View>
						<View style={{paddingTop: Helpers.GetScaledSize(26.67), paddingBottom:Helpers.GetScaledSize(46.67)}}>
							<Text style={styles.title1b}>{lang.taste} {_taste}<Text style={styles.textSmall}> of 20</Text></Text>
							<Slider minimumValue={1} maximumValue={20} step={1} value={_taste} {...sliderProps} onValueChange={value => _SetTaste(value)} />
						</View>
						<View style={{paddingBottom:Helpers.GetScaledSize(46.67)}}>
							<Text style={styles.title1b}>{lang.aroma} {_aroma}<Text style={styles.textSmall}> of 10</Text></Text>
							<Text style={{fontSize:Helpers.GetScaledSize(12.9)}}>{lang.aromaDesc}</Text>
							<Slider minimumValue={1} maximumValue={10} step={1} value={_aroma} {...sliderProps} onValueChange={value => _SetAroma(value)} />
						</View>
						<View style={{paddingBottom:Helpers.GetScaledSize(46.67)}}>
							<Text style={styles.title1b}>{lang.appearance} {_appearance}<Text style={styles.textSmall}> of 5</Text></Text>
							<Text style={{fontSize:Helpers.GetScaledSize(12.9)}}>{lang.appearanceDesc}</Text>
							<Slider minimumValue={1} maximumValue={5} step={1} value={_appearance} {...sliderProps} onValueChange={value => _SetAppearance(value)} />
						</View>
						<View style={{paddingBottom:Helpers.GetScaledSize(20)}}>
							<Text style={styles.title1b}>{lang.mouthfeel} {_mouthfeel}<Text style={styles.textSmall}> of 5</Text></Text>
							<Text style={{fontSize:Helpers.GetScaledSize(12.9)}}>{lang.mouthfeelDesc1}</Text>
							<Text style={{fontSize:Helpers.GetScaledSize(12.9)}}>{lang.mouthfeelDesc2}</Text>
							<Slider minimumValue={1} maximumValue={5} step={1} value={_mouthfeel} {...sliderProps} onValueChange={value => _SetMouthfeel(value)} />
						</View>
					</View>

					<Text style={[styles.title2b,{paddingBottom:Helpers.GetScaledSize(30)}]}>Total {(_taste + _aroma + _appearance + _mouthfeel)}</Text>

					<TextInput multiline
						style={[styles.inputBox, {height:Helpers.GetScaledSize(106.67), width:global.screen.width-Helpers.GetScaledSize(86.67), textAlignVertical:"top", paddingVertical:Helpers.GetScaledSize(4)}]}
						onChangeText={text => _SetComment(text)}
						placeholder={lang.beerComment} {...PlaceholderProps}
						value={_comment}
					/>

					<View style={{paddingBottom:Helpers.GetScaledSize(13.33)}} />
					<TouchableOpacityDB style={styles.button} onPress={SubmitVote}>
						<Text style={styles.buttonText}>{lang.submitVote}</Text>
					</TouchableOpacityDB>

					<View style={{paddingBottom:Helpers.GetScaledSize(20)}} />
					<TouchableOpacityDB style={styles.button} onPress={VoteModalClose}>
						<Text style={styles.buttonText}>{lang.cancel}</Text>
					</TouchableOpacityDB>
					<View style={{paddingBottom:Helpers.GetScaledSize(25)}} />
					{ _isLoading && <View style={styles.loading}>
						<ActivityIndicator {...ActivityIndicatorProps} />
					</View> }
				</ModalView></KeyboardAwareScrollView>
			</Modal>
			<FullScreenModal display={_roundComments} />

			<View style={styles.mainBox}>
				{ _game && <Text style={[styles.textBold,{fontSize:Helpers.GetScaledSize(25.67),paddingBottom:Helpers.GetScaledSize(26.67)}]}>{_game.category}</Text> }
				<Text style={[styles.textBold,{fontSize:Helpers.GetScaledSize(42)}]}>{lang.beer} #{_roundNum}</Text>
				<Text style={[styles.text,{fontSize:Helpers.GetScaledSize(16.33),paddingHorizontal:Helpers.GetScaledSize(25)}]}>{_desc}</Text>

				{_voterInfo}
				<View style={{paddingTop:Helpers.GetScaledSize(13.33)}}></View>

				<View style={{paddingTop:Helpers.GetScaledSize(13.33),flexDirection:"row"}}>
					{ prev }
					{ _myTeamId && <TouchableOpacityDB style={styles.button} onPress={VoteModalOpen}>
						<Text style={styles.buttonText}>{_voteButtonLabel}</Text>
					</TouchableOpacityDB> }
					{ next }
				</View>

				{ _adminControls }

			<ScoreAnimation game={_game} CloseMethod={CloseScreen} visible={(_showScores && !_roundComments)} />
		</View>
	</KeyboardAwareScrollView>
	</SafeAreaView>);
}

async function OnFocus() {
	console.log("ActiveGame - OnFocus");
	global.context.ScreenRefresh = RefreshDisplay;

	ContextLoad();
	await EnterRound(_game.activeRound);
}

async function OnBlur() {
	console.log("ActiveGame - OnBlur");
	global.context.ScreenRefresh = null;
	_season = null;
	_game = null;
}

function ContextLoad() {
	_season = global.context.season;
	_game = global.context.game;

	if (_game && global.context.isLeagueOwner) {
		_roundPrev = Helpers.RoundFindPrev(_game, _roundNum);
		_roundNext = Helpers.RoundFindNext(_game, _roundNum);
	}

	_myTeamId = null;
	for (let i=0; i<_season.teams.length; i++) {
		if (_season.teams[i].ownerIds.indexOf(global.context.userId)>=0) {
			_myTeamId = _season.teams[i]._id;
			break;
		}
	}
}

async function EnterRound(num) {
	const lastRoundNum = _roundNum;
	if (_roundNum==num) {
		return;
	}

	if (_voteModalVisible) {
		VoteModalClose();
	}

	await _SetRoundNum(num);

	for (let i=0; i<_game.rounds.length; i++) {
		if (_game.rounds[i].number==num) {
			_round = _game.rounds[i];
			break;
		}
	}

	let votes = UserFindVotes(global.context.userId);
	if (votes) {
		_SetVoteButtonLabel(lang.changeVote);
		_SetTaste(votes.taste);
		_SetAroma(votes.aroma);
		_SetAppearance(votes.appearance);
		_SetMouthfeel(votes.mouthfeel);
		if (votes.comment) {
			_SetComment(votes.comment);
		} else {
			_SetComment("");
		}
	} else {
		_SetVoteButtonLabel(lang.vote);
		_SetTaste(1);
		_SetAroma(1);
		_SetAppearance(1);
		_SetMouthfeel(1);
		_SetComment("");
	}

	_SetDesc(_round.description);
	DisplayVoters();
	if (num > lastRoundNum) {
		DisplayComments(lastRoundNum);
	}
}

function UserFindVotes(userId) {
	if (!_round) {
		return null;
	}

	for (let i=0; i<_round.votes.length; i++) {
		if (_round.votes[i].userId==userId) {
			return _round.votes[i];
		}
	}

	return null;
}

function TeamFindActiveUsers(teamId) {
	if (_activeUsers.length==0) {
		return ["unknown"];
	}

	let team = Helpers.FindTeam(_season.teams, teamId);

	let ids = [];
	for (let i=0; i<team.ownerIds.length; i++) {
		if (_activeUsers.indexOf(team.ownerIds[i])>=0) {
			ids.push(team.ownerIds[i]);
		}
	}

	if (ids.length==0) {
		return ["unknown"];
	}

	return ids;
}

function TeamFindVotes(teamId) {
	if (!_round) {
		return [];
	}

	let votes = [];
	for (let i=0; i<_round.votes.length; i++) {
		if (_round.votes[i].teamId==teamId) {
			votes.push(_round.votes[i]);
		}
	}

	return votes;
}

function TeamVoteStatus(teamId) {
	// 0=no vote, 1=partial, 2=full
	let votes = TeamFindVotes(teamId);

	if (votes.length==0) {
		return 0;
	}

	let userIds = TeamFindActiveUsers(teamId);
	console.log(votes.length + " votes for " + userIds.length + " users on " + teamId);
	if (votes.length<userIds.length) {
		return 1;
	} else {
		return 2;
	}
}

async function DisplayVoters() {
	let voters = [];
	_season.teams.sort((a, b) => (a.name > b.name) ? 1 : -1);

	for (let i=0; i<_season.teams.length; i++) {
		let team = _season.teams[i];
		let voteStatus = TeamVoteStatus(team._id);
		let voteStyle = styles.voteBad;
		let voteTextStyle = styles.voteBadText;

		if (voteStatus==1) {
			voteStyle=styles.votePartial;
			voteTextStyle=styles.votePartialText;
		} else if (voteStatus==2) {
			voteStyle=styles.voteGood;
			voteTextStyle=styles.voteGoodText;
		}

		voters.push(<View style={{flexDirection: "row", width:Helpers.GetScaledSize(266.67), paddingBottom:Helpers.GetScaledSize(6.67)}} key={team._id}>
			<View style={[styles.title2,voteStyle]}></View>
			<Text style={[styles.title2,voteTextStyle, {paddingLeft:Helpers.GetScaledSize(6.67)}]}>{team.name}</Text>
		</View>);
	}

	_SetVoterInfo(
		<View style={{flexDirection: "column"}}>
			<View style={{paddingTop: Helpers.GetScaledSize(13.33), alignItems:"center"}}>
				<Text style={[styles.title2b,{paddingBottom:Helpers.GetScaledSize(4)}]}>{lang.votingStatus}</Text>
				{voters}
			</View>
		</View>
	);

	SetAdminControls();
}

async function DisplayComments(roundNum) {
	console.log("DisplayComments");

	let comments = [];
	let round = null;
	for (let i=0; i<_game.rounds.length; i++) {
		if (_game.rounds[i].number==roundNum) {
			round = _game.rounds[i];
			break;
		}
	}

	if (!round || !round.votes) {
		return;
	}

	round.votes.sort((a, b) => (a.taste > b.taste) ? 1 : -1);
	for (let i=0; i<round.votes.length; i++) {
		if (round.votes[i].teamId==round.teamId) {
			console.log("skipping self vote");
			continue;
		}

		if (round.votes[i].comment && round.votes[i].comment.length>0) {
			comments.push(round.votes[i].comment);
		}
	}

	if (comments.length==0) {
		await _SetRoundComments(null);
		return;
	}

	await _SetRoundComments(<View style={[styles.box,{width:global.screen.width-Helpers.GetScaledSize(86.67)}]}>
		<View style={{ marginTop: Helpers.GetScaledSize(13.33) }} />
		<Text style={[styles.textBold,{fontSize:Helpers.GetScaledSize(36)}]}>Beer #{roundNum}</Text>
		<Text style={[styles.textBold,{fontSize:Helpers.GetScaledSize(36),paddingBottom:Helpers.GetScaledSize(26)}]}>Comments</Text>

		{ comments.map((comment, i) => (<View key={i}>
			<View style={{backgroundColor: "#808080", height:Helpers.GetScaledSize(1.5), width:global.screen.width}} />
			<Text style={[styles.title2, {marginHorizontal:Helpers.GetScaledSize(20), paddingVertical:Helpers.GetScaledSize(15)}]}>{comment}</Text>
		</View>))}
		<View style={{backgroundColor: "#808080", height:Helpers.GetScaledSize(1.5), width:global.screen.width}} />

		<View style={{ marginTop: Helpers.GetScaledSize(13.33) }} />
		<TouchableOpacityDB style={styles.button} onPress={CloseRoundComments}>
			<Text style={styles.buttonText}>{lang.close}{global.context.isLeagueOwner && " for All"}</Text>
		</TouchableOpacityDB>
	</View>);
}

function CloseRoundComments() {
	if (global.context.isLeagueOwner) {
		API.SeasonWS.VoteCommentsClose();
	} else {
		_SetRoundComments(null);
	}
}

function VoteModalOpen() {
	_SetVoteModalVisible(true);
}

async function SubmitVote() {
	let data = { "action": "vote", "gameId": _game._id, "key": _game.key, "round": _roundNum,
		"taste": _taste, "aroma": _aroma, "appearance": _appearance, "mouthfeel": _mouthfeel, "comment": _comment.trim() };

	console.log("voting send");
	_t1=(new Date()).valueOf();
	_savedForRound = _roundNum;

	await Helpers.SetIsLoading(_SetIsLoading);
	API.SeasonWS.VoteSave(data, VoteSaved);
}

async function RoundChange(roundNum) {
	_game.activeRound=roundNum;
	VoteRoundUpdate(_game._id, roundNum);
}

async function EndVoting() {
	_game.isClosed = true;
	VoteRoundUpdate(_game._id, null, true);
}

function VotingRoundUpdated(success) {
	console.log("VotingRoundUpdated call time is " + ((new Date()).valueOf()-_t1));

	if (!success) {
		Alert.alert(lang.error, "Unable to update voting round.", [{text: lang.ok}]);
	}
}

async function EndVotingConfirm() {
	Alert.alert(lang.endVoting, lang.endVotingText,
		[{text: lang.cancel, style: "cancel"}, {text: lang.endVoting, onPress: () => EndVoting()}]
	);
}

function VoteModalClose() {
	_SetVoteModalVisible(false);
}

function SetAdminControls() {
	if (global.context.isLeagueOwner) {
		_SetAdminControls(
			<View style={{paddingTop:Helpers.GetScaledSize(40),flexDirection:"row"}}>
				{ _roundNext==-1 && <View style={{paddingVertical:Helpers.GetScaledSize(2.67)}}><TouchableOpacityDB style={[styles.button,{backgroundColor:"#ff3030"}]} onPress={() => {EndVotingConfirm();}}><Text style={styles.buttonText}>{lang.endVoting}</Text></TouchableOpacityDB></View> }
			</View>
		);
	} else {
		_SetAdminControls();
	}
}

async function RefreshDisplay(updatedItems) {
	console.log("RefreshDisplay");
	//console.log(JSON.stringify(updatedItems));

	if (!_game) {
		console.log("not active, so not updating");
		return;
	}

	if (updatedItems.indexOf("voteCommentsClose")>=0) {
		console.log("got voteCommentsClose");
		_SetRoundComments(null);
		return;
	}

	_season = global.context.season;
	_game = Helpers.FindGame(_season.games, _game._id);
	_round = Helpers.FindRound(_game.rounds, _round.teamId);
	if (_season.activeUsers) {
		_activeUsers = _season.activeUsers;
	}

	if (updatedItems && updatedItems.indexOf("season")==-1) {
		console.log("ActiveGameScreen got an update but was NOT relevant");
		return;
	}

	if (_game.isClosed) {
		await DisplayComments(_roundNum);
		Helpers.ScoreSeason(_season);
		_SetShowScores(true);
		return;
	}

	if (_roundNum != _game.activeRound) {
		await EnterRound(_game.activeRound);
	}

	DisplayVoters();
}

function CloseScreen() {
	Helpers.Navigate("LeagueNav", { screen: "LeagueOverview"});
}

function VoteSaved(success) {
	console.log("VoteSaved " + success);
	if (success) {
		console.log("vote save time is " + ((new Date()).valueOf()-_t1));
		VoteModalClose();
		if (_roundNum==_savedForRound) {
			_SetVoteButtonLabel(lang.changeVote);
		}
	} else {
		Alert.alert(lang.error, lang.unableToSave, [{text: lang.ok}]);
	}
}

function VoteRoundUpdate(gameId, activeRound, isClosed) {
	let data = { "action": "gameSave", "_id": gameId };

	if (activeRound!=null) {
		data.activeRound = activeRound;
	}

	if (isClosed!=null) {
		data.isClosed = isClosed;
	}

	console.log("game update send");

	_t1=(new Date()).valueOf();
	API.SeasonWS.GameSave(data, VotingRoundUpdated);
}


ActiveGameScreen.navigationOptions = {
	header: null,
};
