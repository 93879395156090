import React from "react";
import { Platform, TouchableOpacity, View, ImageBackground } from "react-native";
import { Image, Text } from "react-native";

import DebounceComponent from "../classes/DebounceComponent";
const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import Helpers from "../classes/Helpers";

const lang = require("../constants/lang-en.json");


const font = "LibreFranklin";
const fontBold = "LibreFranklin-BoldItalic";
//const font = "sans-serif-thin";


const UIHelpers = {

Header: function(title, subtitle, padTop, padBottom) {
	if (padTop && padTop>0) {
		padTop = Helpers.GetScaledSize(padTop);
	}
	if (padBottom && padBottom>0) {
		padBottom = Helpers.GetScaledSize(padBottom);
	}

	let titleStyle = {
		fontFamily: fontBold,
		textAlign: "center",
		fontSize: Helpers.GetScaledSize(28),
		color:"#ffffff",
		textShadowColor: "#c17515",
		textShadowOffset: {width: Helpers.GetScaledSize(2.67), height: Helpers.GetScaledSize(4)},
		textShadowRadius: Helpers.GetScaledSize(5.33),
		textTransform: "uppercase"
	};

	let subStyle = {
		fontFamily: font,
		textAlign: "center",
		fontSize: Helpers.GetScaledSize(16.33),
		color: "#ffffff",
		textShadowColor: "#808080",
		textShadowOffset: {width: Helpers.GetScaledSize(1.33), height: Helpers.GetScaledSize(1.33)},
		textShadowRadius: Helpers.GetScaledSize(2.67),
	};


	let boxHeight = subtitle ? Helpers.GetScaledSize(80) : Helpers.GetScaledSize(53.33);

	return (<View style={{alignSelf:"stretch"}}>
		{ (padTop>0) && <View style={{paddingTop: padTop}} /> }
		<ImageBackground source={require("../assets/images/header_bg.jpg")} style={{width:"100%", height:boxHeight}}>
			<View style={{flex:1, alignItems: "center", justifyContent: "center", flexDirection:"column"}}>
				<Text style={titleStyle}>{title}</Text>
				{ subtitle && <Text style={subStyle}>{subtitle}</Text> }
			</View>
		</ImageBackground>
		{ (padBottom>0) && <View style={{paddingTop: padBottom}} /> }
	</View>);
},

} // const UIHelpers

export default UIHelpers;
