import { Platform } from "react-native";
import { Buffer } from "buffer";
import * as Updates from "expo-updates";

let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


import Helpers from "./Helpers";
const lang = require("../constants/lang-en.json");
const config = require("../config.json");


let _CurrentCallback = null;
let _connectFails = 0;
let _retryTimes = [2000,5000,8000,12000,20000,30000];

const API = {

Rest: {

TestAPI: async function() {
	console.log("inside TestAPI");
	try {
		if (!global.lastPing) {
			global.lastPing = { "timestamp": 0, "ping":0 } ;
		}

		let now=(new Date()).valueOf();
		if (now-global.lastPing.timestamp<900000) {
			return;
		}

		const t1=(new Date()).valueOf();
		const response = await fetch(global.apiBase + "test");
		const pingTime = (new Date()).valueOf()-t1;

		if (response.status==200) {
			//global.lastPing.timestamp = t1;
			//global.lastPing.ping = pingTime;
			//console.log("api call time is " + pingTime);
			API.SeasonWS.DebugInfo({ "ping": pingTime });
		} else {
			console.log(response.status + " " + await response.text());
		}
	} catch (error) {
		console.log(error);
	}
},

URLExists: async function(url) {
	try {
		const response = await fetch(url);
		return (response.status==200);
	} catch (error) {
		return false;
	}
},

FindBestServer: async function() {
	console.log("inside FindBestServer");
	/*if (!global.lastPing) {
		global.lastPing = { "timestamp": 0, "ping":0 } ;
	}*/

	if (!global.serverSpeeds || global.serverSpeeds.length!=config.apiServers.length) {
		global.serverSpeeds = [];
		for (let i=0; i<config.apiServers.length; i++) {
			global.serverSpeeds.push(0);
		}
	}
	global.serverNum=-1;
	for (let i=0; i<config.apiServers.length; i++) {
		this.CheckServerSpeed(i);
	}
},

CheckServerSpeed: async function(num) {
	/*if (num==2) {
		global.serverSpeeds[num]=7;
		if (global.serverNum==-1) {
			global.apiBase = config.apiServers[num];
			global.serverNum = num;
			console.log("setting server to " + num);
		}
		console.log("server " + num + " time is " + global.serverSpeeds[num]);
		return;
	}*/

	try {
		global.serverSpeeds[num]=0;
		const t1=(new Date()).valueOf();
		const response = await fetch(config.apiServers[num] + "test");
		//if (num==0) { await Helpers.Sleep(2000); }
		const pingTime = (new Date()).valueOf()-t1;

		if (response.status==200) {
			if (global.serverNum==-1) {
				global.apiBase = config.apiServers[num];
				global.imageBase = global.apiBase.replace("/api/v1/", "/");
				global.serverNum = num;
				console.log("setting server to " + num);
			}
			global.serverSpeeds[num]=pingTime;
			console.log("server " + num + " time is " + pingTime);

			/*let min = 0;
			for (let i=0; i<global.serverSpeeds.length; i++) {
				if (i!=num && global.serverSpeeds[i]>0 && global.serverSpeeds[i]<min) {
					min=global.serverSpeeds[i];
				}
			}
			if (min==pingTime && global.serverNum==-1) {
				console.log("setting server to " + num);
				global.apiBase = config.apiServers[num];
				global.serverNum = num;
			}*/
		} else {
			console.log("error calling server " + num + ": response " + response.status);// + " " + await response.text());
		}
	} catch (ex) {
		console.log("error calling server " + num);
		console.log(ex);
	}
},

CheckVersion: async function() {
	if (__DEV__ || Platform.OS=="web") {
		return;
	}

	try {
		const update = await Updates.checkForUpdateAsync();
		if (update.isAvailable) {
			await Updates.fetchUpdateAsync();
			await Updates.reloadAsync();
		}
	} catch (e) {
		console.log("CheckVersion error");
		console.log(e);
	}

	/*try {
		let now = (new Date()).valueOf();
		if (global.lastVersionCheck && (now-global.lastVersionCheck)<36000) { //3600000
			return;
		}

		let response = await fetch(global.apiBase + "version", { method: "GET",
			headers: { Accept: "application/json", "Content-Type": "application/json" } });

		if (response.status!=200) {
			return;
		}

		let info = await response.json();
		if (info && info.versionNum!=null && info.versionNum>config.versionNum) {
			Alert.alert(lang.error, "Please close and reopen the app", [{text: lang.ok}]);
		}
		global.lastVersionCheck = (new Date()).valueOf();
	} catch (error) {
		console.log(error);
	}*/
},

UserCreate: async function(firstName, lastName, email, password, countryValue, postalCode) {
	let userPost = {};

	userPost.firstName=firstName;
	userPost.lastName=lastName;
	userPost.email=email.toLowerCase();
	userPost.password=password;
	userPost.country=countryValue;
	userPost.postalCode=postalCode;

	try {
		let response = await fetch(global.apiBase + "auth/local/create", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(userPost)
		});

		if (response.status!=200) {
			let resp = await response.json();
			if (resp.error) {
				Alert.alert(lang.error, resp.error, [{text: lang.ok}]);
			}
			return false;
		}

		let createdUser = await response.json();
		if (!createdUser) {
			return false;
		}

		global.auth = "JAUTH " + Buffer.from(createdUser._id + ":" + createdUser.token).toString("base64");

		global.context.userId = createdUser._id;
		await API.Main.UserLeaguesLoad();
		return true;
	} catch (error) {
		Alert.alert("Network Error", "Unable to connect to server.", [{text: lang.ok}]);
		console.log(error);
		return false;
	}
},

UserLogin: async function(email, password, pushToken) {
	console.log("UserLogin");
	try {
		global.context.userId = null;

		let auth = "Basic " + Buffer.from(email + ":" + password).toString("base64");
		if (pushToken) {
			pushToken = "?pushToken=" + pushToken;
		} else {
			pushToken="";
		}

		let response = await fetch(global.apiBase + "auth/local" + pushToken, { method: "GET", headers: { "Authorization": auth } });
		if (response.status!=200) {
			return false;
		}

		let user = await response.json();

		if (!user || !user._id) {
			return false;
		}

		global.context.userId = user._id;
		global.auth = "JAUTH " + Buffer.from(user._id + ":" + user.token).toString("base64");

		return await API.Main.UserLeaguesLoad();
	} catch (error) {
		Alert.alert("Network Error", "Unable to connect to server.", [{text: lang.ok}]);
		console.log(error);
		return false;
	}
},

UserLoginToken: async function(userId, token) {
	console.log("UserLoginToken");

	try {
		if (userId) {
			global.auth = "JAUTH " + Buffer.from(userId + ":" + token).toString("base64");
			global.context.userId = userId;
		}

		if (!await API.Main.UserLeaguesLoad()) {
			Alert.alert("Login Error", "Invalid Login.", [{text: lang.ok}]);
			return false;
		}

		return true;
	} catch (error) {
		Alert.alert("Network Error", "Unable to connect to server.", [{text: lang.ok}]);
		console.log(error);
		return false;
	}
},

UserLoginFacebook: async function(token, pushToken) {
	if (pushToken) {
		pushToken = "?pushToken=" + pushToken;
	} else {
		pushToken="";
	}

	try {
		let response = await fetch(global.apiBase + "auth/facebook" + pushToken, {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			body: JSON.stringify({ "token": token })
		});

		if (response.status!=200) {
			Alert.alert("Login Error", "Invalid Login.", [{text: lang.ok}]);
			console.log(response.status);
			console.log(await response.json());
			return false;
		}

		console.log("getting user");
		let user = await response.json();
		if (!user) {
			console.log("no user");
			return false;
		}

		global.auth = "JAUTH " + Buffer.from(user._id + ":" + user.token).toString("base64");
		global.context.userId = user._id;

		return await API.Main.UserLeaguesLoad();
	} catch (error) {
		Alert.alert("Network Error", "Unable to connect to server.", [{text: lang.ok}]);
		console.log(error);
		return false;
	}
},

UserLoginUntappd: async function(token, pushToken) {
	console.log("inside UserLoginUntappd");
	if (pushToken) {
		pushToken = "?pushToken=" + pushToken;
	} else {
		pushToken="";
	}

	try {
		let response = await fetch(global.apiBase + "auth/untappd" + pushToken, {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			body: JSON.stringify({ "token": token })
		});

		if (response.status!=200) {
			Alert.alert("Login Error", "Invalid Login.", [{text: lang.ok}]);
			console.log(response.status);
			console.log(await response.json());
			return false;
		}

		console.log("getting user");
		let user = await response.json();
		if (!user) {
			console.log("no user");
			return false;
		}

		global.auth = "JAUTH " + Buffer.from(user._id + ":" + user.token).toString("base64");
		global.context.userId = user._id;

		return await API.Main.UserLeaguesLoad();
	} catch (error) {
		Alert.alert("Network Error", "Unable to connect to server.", [{text: lang.ok}]);
		console.log(error);
		return false;
	}
},

UserLoginApple: async function(data, pushToken) {
	if (pushToken) {
		pushToken = "?pushToken=" + pushToken;
	} else {
		pushToken="";
	}

	try {
		let response = await fetch(global.apiBase + "auth/apple" + pushToken, {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			body: JSON.stringify(data)
		});

		if (response.status!=200) {
			Alert.alert("Login Error", "Invalid Login.", [{text: lang.ok}]);
			console.log(response.status);
			console.log(await response.json());
			return false;
		}

		console.log("getting user");
		let user = await response.json();
		if (!user) {
			console.log("no user");
			return false;
		}

		global.auth = "JAUTH " + Buffer.from(user._id + ":" + user.token).toString("base64");
		global.context.userId = user._id;

		return await API.Main.UserLeaguesLoad();
	} catch (error) {
		Alert.alert("Network Error", "Unable to connect to server.", [{text: lang.ok}]);
		console.log(error);
		return false;
	}
},

UserLoad: async function() {
	try {
		let response = await fetch(global.apiBase + "user", { method: "GET",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth } });

		if (response.status==200) {
			return await response.json();
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
},

UserSave: async function(user) {
	user._id=global.context.userId;

	try {
		let response = await fetch(global.apiBase + "user", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(user)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			let resp = await response.json();
			if (resp.error=="email taken") {
				global.temp.error="Email is already in use.";
			}
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

UserSavePassword: async function(userId, password, key) {
	let userPost = { "_id": userId, "password": password, "passwordResetKey": key };

	try {
		let response = await fetch(global.apiBase + "user", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(userPost)
		});

		if (response.status==200) {
			return true;
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

UserInvitesLoad: async function(userId, userKey) {
	try {
		let response = await fetch(global.apiBase + "invite", { method: "GET",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth } });

		if (response.status==200) {
			return await response.json();
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
},

UserDelete: async function() {
	console.log("UserDelete " + _user._id);
	let postBody = { "_id": _user._id };
	try {
		//TODO implement
		/*let response = await fetch(global.apiBase + "user", {
			method: "DELETE",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		console.log(response.status);
		console.log(await response.json());

		_props.navigation.navigate("Home");*/
		//return responseJson;
	} catch (error) {
		console.log(error);
	}
},



LeagueCreate: async function(league) {
	if (!league.name || league.name.length<3) {
		//Alert.alert(lang.needName, lang.enterLeagueName, [{text: lang.ok}]);
		return false;
	}

	if (!league.location || league.location.length<3) {
		//Alert.alert(lang.needCity, lang.enterCity, [{text: lang.ok}]);
		return false;
	}

	try {
		let response = await fetch(global.apiBase + "league", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(league)
		});

		if (response.status==200) {
			let league = await response.json();

			global.context.isLeagueCreate = true;
			await API.Main.UserLeaguesLoad();
			await API.Rest.UI.LeagueSet(league._id, league.seasons[0]._id);

			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
	}
},

LeagueSave: async function(name, location, image) {
	let leaguePost = { "_id": global.context.league._id, "name": name };

	if (location) {
		leaguePost.location=location;
	}

	if (image) {
		leaguePost.image=image;
	}

	try {
		let response = await fetch(global.apiBase + "league", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(leaguePost)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

LeagueDelete: async function() {
	let postBody = { "_id": global.context.league._id };

	try {
		let response = await fetch(global.apiBase + "league", {
			method: "DELETE",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			global.context.league = null;
			global.context.season = null;
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else if (response.status==400) {
			//error might be "Cannot delete a league with votes"
			console.log(response.status);
			console.log(await response.json());
			return false;
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},


LeagueInvitesSend: async function(emails) {
	let addresses = [];
	for (let i=0; i<emails.length; i++) {
		if (emails[i]) {
			let address = emails[i].replace(/\s/g, "");
			if (address.length>0) {
				addresses.push(address);
			}
		}
	}

	if (addresses.length==0) {
		return true;
	}

	let postBody = { "_id": global.context.league._id, "seasonId": global.context.season._id, "emails": addresses };

	try {
		let response = await fetch(global.apiBase + "league?action=invite", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

LeagueInvitesLoad: async function() {
	try {
		let response = await fetch(global.apiBase + "invite?leagueId=" + global.context.league._id, {
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth }
		});

		if (response.status==200) {
			let invites = await response.json();

			// check that these are league invites (not team invites)
			for (let i=invites.length-1; i>=0; i--) {
				if (invites[i].teamId) {
					invites.splice(i,1);
				}
			}

			return invites;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
},

LeagueOwnerChange: async function(leagueId, user, makeOwner) {
	console.log("LeagueOwnerChange");
	let postBody = { "_id": leagueId, "userId": user._id };

	try {
		let response = await fetch(global.apiBase + "league?action=" + (makeOwner ? "addOwner" : "removeOwner"), {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});
		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},




InviteResend: async function(id) {
	let postBody = { "_id": id, "leagueId": global.context.league._id };

	try {
		let response = await fetch(global.apiBase + "invite?action=resend", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

InviteAccept: async function(inviteId) {
	console.log("main InviteAccept");
	let postBody = { "_id": inviteId };

	try {
		let response = await fetch(global.apiBase + "invite?action=accept", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			console.log("got 200 on invite accept: ");
			let resp = await response.json();
			console.log(resp);

			global.temp.leagueId = resp.leagueId;
			global.temp.seasonId = resp.seasonId;

			await API.Main.UserLeaguesLoad();
			return true;
		} else if (response.status==400) {
			Helpers.InviteProcessFailure(await response.json());
			return false;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log("InviteAccept failed with status code " + response.status);
			Alert.alert(lang.error, "Unknown error while processing invitation.", [{text: lang.ok}]);
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

InviteDelete: async function(id) {
	let postBody = { "_id": id };

	try {
		let response = await fetch(global.apiBase + "invite", {
			method: "DELETE",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},




SeasonLoad: async function(seasonId) {
	try {
		let response = await fetch(global.apiBase + "season?includeGames=1&id=" + seasonId + "&leagueId=" + global.context.league._id, {
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth }
		});

		if (response.status==200) {
			return await response.json();
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
},

SeasonLoadUsers: async function() {
	try {
		let response = await fetch(global.apiBase + "season?&getUsers=1&id=" + global.context.season._id + "&leagueId=" + global.context.league._id, {
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth }
		});

		if (response.status==200) {
			global.context.seasonUsers = await response.json();
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},



GameSave: async function(game, teamId, isDQ) {
	let postBody = {};

	if (game._id) {
		postBody._id = game._id;
	}

	postBody.leagueId=global.context.league._id;
	postBody.seasonId=global.context.season._id;
	postBody.category=game.category;
	postBody.location=game.location;
	postBody.startTime=game.startTime;

	if (game.activeRound!=null) {
		postBody.activeRound=game.activeRound;
	}

	if (game.teamsLocked!=null) {
		postBody.teamsLocked=game.teamsLocked;
	}

	if (game.isClosed!=null) {
		postBody.isClosed=game.isClosed;
	}

	if (teamId) {
		if (isDQ) {
			postBody.dq=teamId;
		} else {
			postBody.undq=teamId;
		}
	}

	try {
		let response = await fetch(global.apiBase + "game", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

GameDelete: async function(id) {
	console.log("GameDelete " + id);
	let postBody = { "_id": id, "leagueId": global.context.league._id, "seasonId": global.context.season._id };

	try {
		let response = await fetch(global.apiBase + "game", {
			method: "DELETE",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},


TeamSave: async function(id, name, image) {
	if (name.length<3) {
		console.log("Need Team Name");
		return false;
	}

	let teamPost = { "_id": id, "leagueId": global.context.league._id, "seasonId": global.context.season._id };

	teamPost.name=name;

	if (image) {
		teamPost.image=image;
	}

	try {
		let response = await fetch(global.apiBase + "team", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(teamPost)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

TeamAddRemoveBrewery: async function(leagueId, seasonId, team, breweryId, breweryName, isAdd) {
	console.log("TeamAddRemoveBrewery " + breweryId);
	let postBody = { "_id": team._id, "seasonId": seasonId, "leagueId": leagueId, "breweryId": breweryId };

	let idx = team.draftedBreweries.findIndex(e => e.id == breweryId);
	if (!isAdd && idx==-1) {
		return true;
	}

	try {
		let response = await fetch(global.apiBase + "team?action=" + (isAdd ? "add" : "remove") + "Brewery", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			if (isAdd) {
				team.draftedBreweries.push({ "id": breweryId, "name": breweryName });
			} else {
				team.draftedBreweries.splice(idx,1);
			}
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

TeamBeerSet: async function(gameId, beerId, description) {
	let postBody = { "_id": gameId, "seasonId": global.context.season._id, "beerId": beerId, "description": description };

	try {
		let response = await fetch(global.apiBase + "game?action=submitBeer", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		console.log(response.status);
		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},




ImageSave: async function(imageData) {
	try {
		console.log("saving image, length " + imageData.length);

		let t1=(new Date()).valueOf();
		let response = await fetch(global.apiBase + "image", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify({ "imageData": imageData })
		});

		console.log("image upload call time is " + ((new Date()).valueOf()-t1));

		if (response.status==200) {
			return await response.json();
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log (response.status);
			console.log (await response.json());
			return null;
		}
	} catch (error) {
		console.log(error);
		return null;
	}
},

TeamDelete: async function(id) {
	console.log("DELETING team " + id);

	let postBody = { "_id": id, "seasonId": global.context.season._id, "leagueId": global.context.league._id };

	try {
		let response = await fetch(global.apiBase + "team", {
			method: "DELETE",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},




BreweryLoad: async function(id) {
	try {
		let response = await fetch(global.apiBase + "brewery?id=" + id, { method: "GET",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth } });

		if (response.status==200) {
			return await response.json();
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			return "unknown";
		}
	} catch (ex) {
		console.log(ex);
		return "unknown";
	}
},

BrewerySearchCall: async function(search, retryCallback) {
	let text = search.toLowerCase();

	if (text.length<3) {
		return false;
	}

	console.log("calling untappd for [brewery] " + text);

	let response = await fetch(global.apiBase + "brewery?search=" + text, { method: "GET",
		headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth } });

	global.context.pendingSearch = { "method": retryCallback, "text": text };

	if (response.status==200) {
		global.context.pendingSearch = null;
		return await response.json();
	} else if (response.status==401) {
		global.context.pendingSearch = null;
		return Helpers.GoToLogin();
	} else {
		console.log(response.status);
		let msg = await response.json();
		console.log("msg is " + JSON.stringify(msg));
		if (msg.error=="need untappd login") {
			Helpers.UntappdCheckLogin(false);
		} else if (msg.error=="Too many Untappd requests for this hour.") {
			Alert.alert(lang.error, "Too many Untappd requests for this hour.", [{text: lang.ok}]);
		}
		return [];
	}
},


BeerSearchCall: async function(search, retryCallback) {
	let text = search.toLowerCase();

	if (text.length<4) {
		return false;
	}

	console.log("calling untappd for [beer] " + text);
	global.context.pendingSearch = { "method": retryCallback, "text": text };


	let response = await fetch(global.apiBase + "beer?search=" + text, { method: "GET",
		headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth } });

	if (response.status==200) {
		global.context.pendingSearch = null;
		return await response.json();
	} else if (response.status==401) {
		global.context.pendingSearch = null;
		return Helpers.GoToLogin();
	} else {
		let msg = await response.json();
		console.log("msg is " + JSON.stringify(msg));
		if (msg.error=="need untappd login") {
			Helpers.UntappdCheckLogin(false);
		} else if (msg.error=="Too many Untappd requests for this hour.") {
			Alert.alert(lang.error, "Too many Untappd requests for this hour.", [{text: lang.ok}]);
		}

		return [];
	}
},


PasswordReset: async function(email) {
	console.log("PasswordReset");

	if (email.length<6) {
		console.log("Need email");
		return false;
	}

	try {
		let response = await fetch(global.apiBase + "user", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			body: JSON.stringify({ "passwordReset": email })
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

EmailVerifyResend: async function() {
	try {
		let response = await fetch(global.apiBase + "user", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify({ "emailVerifyResend": "1" })
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},

VoteSave: async function(seasonId, gameId, key, round, taste, aroma, appearance, mouthfeel) {
	let postBody = {};

	postBody.seasonId=seasonId;
	postBody.gameId=gameId;
	postBody.key=key;

	postBody.round=round;

	postBody.taste=taste;
	postBody.aroma=aroma;
	postBody.appearance=appearance;
	postBody.mouthfeel=mouthfeel;

	try {
		let response = await fetch(global.apiBase + "vote", {
			method: "POST",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth },
			body: JSON.stringify(postBody)
		});

		if (response.status==200) {
			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			console.log(response.status);
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
},


UI: {

LeagueSet: async function(leagueId, seasonId) {
	console.log("leagueset " + leagueId + " " + seasonId);
	global.context.league = null;
	global.context.season = null;
	global.context.seasonUsers = null;


	if (global.context.leagues.length==0) {
		return;
	}

	if (leagueId) {
		for (let i=0; i<global.context.leagues.length; i++) {
			if (global.context.leagues[i]._id==leagueId) {
				if (!seasonId) {
					global.context.league = global.context.leagues[i];
					break;
				}
				for (let j=0; j<global.context.leagues[i].seasons.length; j++) {
					if (global.context.leagues[i].seasons[j]._id==seasonId) {
						global.context.league = global.context.leagues[i];
					}
				}
			}
		}
	}

	if (!seasonId) {
		seasonId = global.context.league.seasons[0]._id;
	}

	if (!global.context.league) {
		//no league set, so auto pick one
		global.context.league = global.context.leagues[0]; //TODO better logic
		seasonId = global.context.league.seasons[0]._id;
	}

	console.log("requesting season");
	API.Rest.UI.SeasonRequestChange(seasonId);

	global.context.isLeagueOwner = (global.context.league.ownerIds.indexOf(global.context.userId)>=0);
},

SeasonRequestChange: function(seasonId) {
	API.SeasonWS.SeasonChange(seasonId, API.Rest.UI.SeasonDataUpdate);
},

SeasonDataUpdate: async function() {
	console.log("SeasonDataUpdate");

	await Helpers.SaveContext();

	console.log("global.context.isLeagueCreate is " + global.context.isLeagueCreate);
	if (global.context.isLeagueCreate) {
		global.context.isLeagueCreate = null;
		Helpers.Navigate("LeagueNav", { screen: "LeagueInvite"});
	} else {
		Helpers.Navigate("LeagueNav", { screen: "LeagueOverview"});
	}
},


InviteAccept: async function(invite) {
	console.log("in regular? InviteAccept");
	if (await API.Rest.InviteAccept(invite._id)) {
		API.Rest.UI.LeagueSet(invite.leagueId, invite.seasonId);
	}
},

InviteAcceptById: async function(inviteId) {
	console.log("InviteAcceptById");
	if (await API.Rest.InviteAccept(inviteId)) {
		await API.Rest.UI.LeagueSet(global.temp.leagueId, global.temp.seasonId);
		global.temp.leagueId = null;
		global.temp.seasonId = null;
	}
},

/*ReloadUser: async function(leagueId, seasonId) {
	console.log("ReloadUser");
	console.log(leagueId);
	console.log(seasonId);
	if (await API.Rest.UserLoginToken(null, null, leagueId, seasonId)) {
		if (leagueId && seasonId) {
			Helpers.Navigate("LeagueNav", { screen: "LeagueOverview"});
		}
	} else {
		Alert.alert(lang.error, "Unable to load user.", [{text: lang.ok}]);
	}
},*/

}, // UI

}, // const API

SeasonWS: {

Connect: function() {
	global.seasonWS.isDisconnecting = false;

	if (global.seasonWS.isConnected && global.seasonWS.hasAuth) {
		console.log("already connected");
		return true;
	}

	if (global.seasonWS.isConnecting) {
		console.log("previous connection attempt");
		return true;
	}

	global.seasonWS.isConnecting = true;

	console.log("SeasonWS Connect");

	if (!global.seasonWS.isConnected) {
		console.log("creating socket");
		let wssServer = global.apiBase.replace("https://", "wss://").replace("/v1/", "/ws/season");
		console.log("serverName is " + wssServer);
		global.seasonWS.ws = new WebSocket(wssServer);
	}

	global.seasonWS.ws.onopen = async () => {
		console.log("on OPEN");
		global.seasonWS.isConnecting=false;
		_connectFails=0;

		//setTimeout(API.Rest.TestAPI, 5000);
	};

	global.seasonWS.ws.onmessage = async (msg) => {
		console.log("on message");
		/*console.log("msg: ");
		console.log(msg);*/
		//let e = Platform.OS=="web" ? msg2 : msg1;
		let data = null;

		try {
			//console.log(JSON.stringify(e));
			//console.log(msg.data);
			data = JSON.parse(msg.data);
		} catch (ex) {
			console.log("parsing failed");
			console.log(ex);
			return false;
		}

		//console.log(global.context.userId + " " + JSON.stringify(e.data));
		//console.log("action is " + data.action);

		switch (data.action) {
			case "needauth":
				console.log("got a needauth");
				if (!data.error) {
					global.seasonWS.isConnected = true;
					API.SeasonWS.UserAuth();
				} else {
					Alert.alert(lang.error, "Unable to connect.", [{text: lang.ok}]);
				}
				break;

			case "auth":
				if (!data.error) {
					global.seasonWS.hasAuth = true;
					API.SeasonWS.SendDebugInfo();
				} else {
					Alert.alert(lang.error, "Authentication issue. Please log in again.", [{text: lang.ok}]);
				}
				break;

			case "voteRoundUpdate":
				console.log("voteRoundUpdate call complete");

				if (data.error) {
					Alert.alert(lang.error, "Unable to update voting round.", [{text: lang.ok}]);
				}

			case "seasonChange":
				await API.SeasonWS.ClearLoading();
				API.SeasonWS.HandleDataUpdates(data, true);
				if (_CurrentCallback) {
					console.log("have callback");
					_CurrentCallback(!data.error);
					_CurrentCallback=null;
				}
				break;

			case "untappdAuth":
				console.log("got untappdAuth back from WS");
			case "vote":
			case "leagueUpdate":
			case "teamUpdate":
			case "gameSave":
			case "gameSubmitBeer":
				await API.SeasonWS.ClearLoading();
				console.log("checking for callback...");
				console.log(_CurrentCallback);
				if (_CurrentCallback) {
					console.log("have callback");
					_CurrentCallback(!data.error);
					_CurrentCallback=null;
				}
				break;

			case "dataUpdate":
				API.SeasonWS.HandleDataUpdates(data);
				break;
		}
	};

	global.seasonWS.ws.onerror = (e, extra) => {
		console.log("ON ERROR " + JSON.stringify(e));
		console.log("ON ERROR " + JSON.stringify(extra));
	};

	global.seasonWS.ws.onclose = (e) => {
		console.log("on CLOSE");

		global.seasonWS.isConnecting=false;
		global.seasonWS.isConnected=false;
		global.seasonWS.hasAuth=false;

		if (global.seasonWS.isDisconnecting) {
			global.seasonWS.isDisconnecting=false;
			return;
		}
		console.log("_connectFails is " + _connectFails);
		setTimeout(API.SeasonWS.Reconnect, _retryTimes[_connectFails]);
	};
},

SendDebugInfo: async function() {
	if (global.serverSpeeds) {
		API.SeasonWS.DebugInfo({ "ping": global.serverSpeeds });
	}

	if (!global.context.sentOSInfo) {
		API.SeasonWS.DebugInfo(global.dimensions);
		let info = { "os": Platform.OS, "version": Platform.Version };
		if (Platform.constants) {
			if (Platform.constants.Model) {
				info.model = Platform.constants.Model;
			}
			if (Platform.constants.Release) {
				info.release = Platform.constants.Release;
			}
			if (Platform.constants.uiMode) {
				info.uiMode = Platform.constants.uiMode;
			}
		}

		API.SeasonWS.DebugInfo(info);
		global.context.sentOSInfo = true;
	}
},

ClearLoading: async function() {
	if (global.context.SetIsLoading) {
		await global.context.SetIsLoading(false);
	}

	global.context.SetIsLoading = null;
},

HandleDataUpdates: function(updates, isSeasonChange) {
	//console.log("HandleDataUpdates");
	let updatedItems = [];

	if (!isSeasonChange && updates.seasonId!=global.context.season._id) {
		console.log("!!!GOT AN UPDATE FOR A DIFFERENT SEASON!!!!");
		console.log("!!!GOT AN UPDATE FOR A DIFFERENT SEASON!!!!");
		console.log("IGNORING");
		console.log("!!!GOT AN UPDATE FOR A DIFFERENT SEASON!!!!");
		console.log("!!!GOT AN UPDATE FOR A DIFFERENT SEASON!!!!");
		console.log("context is " + global.context.season._id + " and got " + updates.seasonId);
		return;
	}

	if (updates.league) {
		console.log("have league in update");
		if (updates.league.isPartial) {
			Helpers.UpdateObject(global.context.league, updates.league);
		} else {
			global.context.league = updates.league;
		}
		updatedItems.push("league");
	}

	if (updates.season) {
		//console.log("SEASON before " + JSON.stringify(global.context.season));
		console.log("have season in update");
		//Helpers.LogObject(global.context.season);
		if (updates.season.isPartial) {
			console.log("season partial update");
			//console.log("SEASON.GAMES before " + JSON.stringify(global.context.season.games));
			Helpers.UpdateObject(global.context.season, updates.season);
			//console.log("SEASON.GAMES after " + JSON.stringify(global.context.season.games));
		} else {
			console.log("full update");
			global.context.season = updates.season;
		}
		//Helpers.LogObject(global.context.season);
		//console.log("SEASON after " + JSON.stringify(global.context.season));

		global.context.team = Helpers.TeamGetByUser(global.context.season, global.context.userId);
		global.context.season.scored = false;
		updatedItems.push("season");
	}

	if (updates.game) {
		console.log("have game in update");
		//Helpers.LogObject(global.context.season);
		for (let i=0; i<global.context.season.games.length; i++) {
			if (global.context.season.games[i]._id==updates.game._id) {
				console.log("found...");
				//console.log("updates.game " + JSON.stringify(updates.game));
				//console.log("games[i]" + JSON.stringify(global.context.season.games[i]));
				//console.log("updating " + JSON.stringify(global.context.season.games[i]) + " to " + JSON.stringify(updates.game));
				Helpers.UpdateObject(global.context.season.games[i], updates.game);
				updatedItems.push("season");
				break;
			}
		}
		//Helpers.LogObject(global.context.season);
	}

	if (updates.round) {
		for (let i=0; i<global.context.season.games.length; i++) {
			if (global.context.season.games[i]._id!=updates.round.gameId) {
				continue;
			}

			Helpers.UpdateArray(global.context.season.games[i], "rounds", [ updates.round ], true);
			updatedItems.push("season");
			break;
		}
	}

	if (updates.activeUsers) {
		global.context.season.activeUsers = updates.activeUsers;
		updatedItems.push("season");
	}

	if (updates.seasonUsers) {
		console.log("have seasonUsers in update");
		//Helpers.UpdateObject(global.context.season, updates.season);
		global.context.seasonUsers = updates.seasonUsers;
		if (!API.Main.ForceValidatedEmail()) {
			return false;
		}
		updatedItems.push("seasonUsers");
	}

	if (updates.deletes) {
		const deletes = updates.deletes;

		if (deletes.gameIds) {
			Helpers.DeleteFromArray(global.context.season, "games", deletes.gameIds);
			updatedItems.push("season");
		}
	}

	if (updates.voteCommentsClose) {
		updatedItems.push("voteCommentsClose");
	}

	if (updatedItems.length>0) {
		console.log("WS updatedItems are " + JSON.stringify(updatedItems));
		if (global.context.season) {
			global.context.season.games.sort((a, b) => (a.startTime > b.startTime) ? 1 : -1);
		}

		if (global.context.ScreenRefresh) {
			global.context.ScreenRefresh(updatedItems);
		}
	}

	return true;
},

Reconnect: function() {
	if (++_connectFails>5) {
		return;
	}
	console.log("reconnect");
	API.SeasonWS.Connect();
},

Disconnect: function() {
	console.log("CLOSING");

	if (global.seasonWS && global.seasonWS.ws && global.seasonWS.ws.readyState==1) {
		global.seasonWS.isDisconnecting = true;
		console.log("connection is open, closing");
		try {
			global.seasonWS.ws.close();
		} catch (ex) {
		}
	}
},

UserAuth: function() {
	console.log("UserAuth");
	let authData = { "action": "auth", "auth": global.auth };
	if (global.context.league && global.context.season) {
		authData.leagueId = global.context.league._id;
		authData.seasonId = global.context.season._id;
	}

	global.seasonWS.ws.send(JSON.stringify(authData));
},

SeasonChange: function(seasonId, callback) {
	console.log("SeasonChange " + seasonId);
	console.log("state is " + global.seasonWS.ws.readyState);
	_CurrentCallback = callback;
	global.seasonWS.ws.send(JSON.stringify({ "action": "seasonChange", "leagueId": global.context.league._id, "seasonId": seasonId }));
},

LeagueUpdate: function(league, callback) {
	console.log("LeagueUpdate");
	_CurrentCallback = callback;
	global.seasonWS.ws.send(JSON.stringify({ "action": "leagueUpdate", "leagueId": global.context.league._id, "league": league }));
},

TeamUpdate: function(team, callback) {
	console.log("TeamUpdate");
	_CurrentCallback = callback;
	global.seasonWS.ws.send(JSON.stringify({ "action": "teamUpdate", "team": team }));
},

GameSave: function(game, callback) {
	console.log("GameSave");
	_CurrentCallback = callback;
	global.seasonWS.ws.send(JSON.stringify({ "action": "gameSave", "game": game }));
},

GameSubmitBeer: function(gameId, beerId, description, callback) {
	console.log("GameSubmitBeer " + gameId + " " + beerId);
	_CurrentCallback = callback;
	let data = { "action": "gameSubmitBeer", "gameId": gameId, "beerId": beerId };
	if (description) {
		data.description = description.trim();
	}
	global.seasonWS.ws.send(JSON.stringify(data));
},

GameDelete: function(gameId, callback) {
	console.log("GameDelete");
	_CurrentCallback = callback;
	global.seasonWS.ws.send(JSON.stringify({ "action": "gameDelete", "gameId": gameId }));
},

VoteSave: function(data, callback) {
	console.log("VoteSave");
	_CurrentCallback = callback;
	global.seasonWS.ws.send(JSON.stringify({ "action": "vote", ...data }));
},

VoteCommentsClose: function() {
	console.log("VoteCommentsClose");
	global.seasonWS.ws.send(JSON.stringify({ "action": "voteCommentsClose" }));
},

UntappdAuth: function(data, callback) {
	console.log("UntappdAuth");
	_CurrentCallback = callback;
	global.seasonWS.ws.send(JSON.stringify({ "action": "untappdAuth", ...data }));
},


DebugInfo: function(data) {
	if (typeof data=="string") {
		data = { "msg": data };
	}
	data.userId = global.context.userId;
	global.seasonWS.ws.send(JSON.stringify({ "action": "debugInfo", ...data }));
},

}, //const SeasonWS

Main: {

UserLeaguesLoad: async function() {
	console.log("UserLeaguesLoad");
	try {
		global.context.leagues = [];

		let count=0;
		while (!global.apiBase) {
			await Helpers.Sleep(350);
			if (count++>20) {
				return;
			}
		}
		let response = await fetch(global.apiBase + "league?seasons=all", { method: "GET",
			headers: { Accept: "application/json", "Content-Type": "application/json", "Authorization": global.auth } });

		if (response.status==200) {
			let leagues = await response.json();
			if (leagues) {
				global.context.leagues = leagues;
			}
			console.log("WS connect with leagueId");
			API.SeasonWS.Connect();

			await Helpers.SaveContext();

			return true;
		} else if (response.status==401) {
			return Helpers.GoToLogin();
		} else {
			Alert.alert(lang.error, "Error loading leagues.", [{text: lang.ok}]);
			console.log(response.status);
			console.log(await response.json());
			return false;
		}
	} catch (error) {
		Alert.alert("Network Error", "Unable to connect to server.", [{text: lang.ok}]);
		console.log(error);
		return false;
	}
},

ForceValidatedEmail: function() {
	let user = Helpers.UserFind(global.context.userId);
	if (!user) {
		return true;
	}

	if (!user.emailValidated) {
		let dateText = new Date().toLocaleDateString();
		let daysOld = (new Date().valueOf() - new Date(user.dateCreated).valueOf())/86400000;
		let buttons = null;
		let canContinue = false;

		if (daysOld<2) {
			return true;
		} else if (daysOld<15) {
			//only alert once per day
			if (dateText==global.context.emailValidatedNotifyDate) {
				return true;
			}

			global.context.emailValidatedNotifyDate=dateText;
			buttons = [
				{ text: lang.resendVerify, onPress: () => { API.Rest.EmailVerifyResend(); } },
				{ text: lang.ok }
			];
			canContinue = true;
		} else {
			buttons = [{ text: lang.ok, onPress: () => { API.Rest.EmailVerifyResend(); Helpers.GoToLogin(); } }];
		}

		setTimeout(() => {
			Alert.alert("Email Verification", "Please verify your email address.", buttons);
		}, 1000);

		return canContinue;
	}

	return true;
},

}, // const Main

};

export default API;
