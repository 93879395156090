import React, { Component } from "react";
import { Platform } from "react-native";


let appRoot = null;
let promptRoot = null;

if (Platform.OS=="web") {
	appRoot = document.getElementById("root");
	promptRoot = document.getElementById("prompt-root");
	if (promptRoot==null) {
		appRoot.insertAdjacentHTML("afterend", "<div id=\"prompt-root\"></div>");
		promptRoot = document.getElementById("prompt-root");
	}
}

class WebPrompt extends Component {
	/*constructor(props) {
		super(props);
		this.el = document.createElement("div");
	}*/
	static el;


	static prompt(title, message, inputPlaceholder, submitText, onSubmit, cancelText) {
		let parentDiv = document.createElement("div");
		parentDiv.style.alignItems = "stretch";
		parentDiv.style.border = "0 solid black";
		parentDiv.style.boxSizing = "border-box";
		parentDiv.style.display = "flex";
		parentDiv.style.marginTop = "0";
		parentDiv.style.marginRight = "0";
		parentDiv.style.marginBottom = "0";
		parentDiv.style.marginLeft = "0";
		parentDiv.style.paddingTop = "0";
		parentDiv.style.paddingRight = "0";
		parentDiv.style.paddingBottom = "0";
		parentDiv.style.paddingLeft = "0";
		parentDiv.style.position = "absolute";
		parentDiv.style.top = "0";
		parentDiv.style.left = "0";
		parentDiv.style.right = "0";
		parentDiv.style.bottom = "0";
		parentDiv.style.backgroundColor = "#00000090";
		parentDiv.style.fontFamily = "Libre Franklin";
		parentDiv.style.fontSize = "2vw";
		parentDiv.style.zIndex = 40;
		parentDiv.onclick = function() { parentDiv.style.display = "none"; };


		let mainDiv = document.createElement("div");
		mainDiv.style.width = "60%";
		mainDiv.style.padding = "1%";
		mainDiv.style.borderRadius = "12px";
		mainDiv.style.backgroundColor = "#ffffff";
		mainDiv.style.margin = "auto";
		mainDiv.onclick = function(event) { event.stopPropagation(); };


		let titleDiv = document.createElement("div");
		titleDiv.style.fontWeight = "bold";
		titleDiv.style.paddingBottom = "0.9vw";
		titleDiv.innerHTML = title;
		mainDiv.appendChild(titleDiv);

		let messageDiv = document.createElement("div");
		messageDiv.style.paddingBottom = "1.1vw";
		messageDiv.innerHTML = message;
		mainDiv.appendChild(messageDiv);


		let inputDiv = document.createElement("div");
		inputDiv.style.width = "100%";
		inputDiv.style.display = "flex";
		inputDiv.style.flexDirection = "row";
		let inputText = document.createElement("input");
		inputText.id = "inputText";
		inputText.placeholder = inputPlaceholder;
		inputText.style.width = "100%";
		inputText.style.marginBottom = "1.0vw";
		inputText.style.fontFamily = "Libre Franklin";
		inputText.style.fontSize = "2vw";
		inputDiv.appendChild(inputText);
		mainDiv.appendChild(inputDiv);


		let buttonDiv = document.createElement("div");
		buttonDiv.style.width = "100%";
		buttonDiv.style.display = "flex";
		buttonDiv.style.flexDirection = "row";
		buttonDiv.style.justifyContent = "flex-end";

		let buttonCancel = document.createElement("a");
		buttonCancel.innerHTML = cancelText;
		buttonCancel.onclick = function() { parentDiv.style.display = "none"; };
		buttonCancel.style.color = "blue";
		buttonCancel.style.paddingLeft = "7%";
		buttonCancel.href = "#";
		buttonDiv.appendChild(buttonCancel);

		let buttonSubmit = document.createElement("a");
		buttonSubmit.innerHTML = submitText;
		buttonSubmit.onclick = function() { parentDiv.style.display = "none"; onSubmit(document.getElementById("inputText").value); };
		buttonSubmit.style.color = "blue";
		buttonSubmit.style.paddingLeft = "7%";
		buttonSubmit.href = "#";
		buttonDiv.appendChild(buttonSubmit);

		mainDiv.appendChild(buttonDiv);

		parentDiv.appendChild(mainDiv);
		promptRoot.appendChild(parentDiv);
	}

	componentDidMount() {
	}

	componentWillUnmount() {
		//promptRoot.removeChild(this.el);
	}
}


export default WebPrompt;
