import React, { Component } from "react";
import { Platform } from "react-native";


let appRoot = null;
let alertRoot = null;

if (Platform.OS=="web") {
	appRoot = document.getElementById("root");
	alertRoot = document.getElementById("alert-root");
	if (alertRoot==null) {
		appRoot.insertAdjacentHTML("afterend", "<div id=\"alert-root\"></div>");
		alertRoot = document.getElementById("alert-root");
	}
}

class WebAlert extends Component {
	/*constructor(props) {
		super(props);
		this.el = document.createElement("div");
	}*/
	static el;

	static alert(title, message, buttons) {
		let parentDiv = document.createElement("div");
		parentDiv.style.alignItems = "stretch";
		parentDiv.style.border = "0 solid black";
		parentDiv.style.boxSizing = "border-box";
		parentDiv.style.display = "flex";
		parentDiv.style.marginTop = "0";
		parentDiv.style.marginRight = "0";
		parentDiv.style.marginBottom = "0";
		parentDiv.style.marginLeft = "0";
		parentDiv.style.paddingTop = "0";
		parentDiv.style.paddingRight = "0";
		parentDiv.style.paddingBottom = "0";
		parentDiv.style.paddingLeft = "0";
		parentDiv.style.position = "absolute";
		parentDiv.style.top = "0";
		parentDiv.style.left = "0";
		parentDiv.style.right = "0";
		parentDiv.style.bottom = "0";
		parentDiv.style.backgroundColor = "#00000090";
		parentDiv.style.fontFamily = "Libre Franklin";
		parentDiv.style.fontSize = "2vw";
		parentDiv.style.zIndex = 50;
		parentDiv.onclick = function() { parentDiv.style.display = "none"; };


		let mainDiv = document.createElement("div");
		mainDiv.style.width = "60%";
		mainDiv.style.padding = "1%";
		mainDiv.style.borderRadius = "12px";
		mainDiv.style.backgroundColor = "#ffffff";
		mainDiv.style.margin = "auto";
		mainDiv.onclick = function(event) { event.stopPropagation(); };


		let titleDiv = document.createElement("div");
		titleDiv.style.fontWeight = "bold";
		titleDiv.style.paddingBottom = "0.6vw";
		titleDiv.innerHTML = title;
		mainDiv.appendChild(titleDiv);

		let messageDiv = document.createElement("div");
		messageDiv.style.paddingBottom = "0.6vw";
		messageDiv.innerHTML = message;
		mainDiv.appendChild(messageDiv);

		let buttonDiv = document.createElement("div");
		//buttonDiv.style.paddingBottom = "0.6vw";
		buttonDiv.style.width = "100%";
		buttonDiv.style.display = "flex";
		buttonDiv.style.flexDirection = "row";
		buttonDiv.style.justifyContent = "flex-end";
		for (let i=0; i<buttons.length; i++) {
			if (!buttons[i].onPress) {
				buttons[i].onPress = function() { parentDiv.style.display = "none"; };
			} else {
				let f1 = buttons[i].onPress;
				buttons[i].onPress = function() { parentDiv.style.display = "none"; f1(); };
			}
			let ahref = document.createElement("a");
			ahref.onclick = buttons[i].onPress;
			ahref.innerHTML = buttons[i].text;
			ahref.style.color = "blue";
			ahref.style.paddingLeft = "7%";
			ahref.href="#";
			buttonDiv.appendChild(ahref);
		}
		mainDiv.appendChild(buttonDiv);

		parentDiv.appendChild(mainDiv);
		alertRoot.appendChild(parentDiv);
	}

	componentDidMount() {
	}

	componentWillUnmount() {
		//alertRoot.removeChild(this.el);
	}
}


export default WebAlert;
