import React, { useState } from "react";
import { Platform, ScrollView, TouchableOpacity, RefreshControl, View } from "react-native";
import { Image, Text, TextInput, ActivityIndicator } from "react-native";
import SafeAreaView from "react-native-safe-area-view";


let _isWeb = false;
let Alert = null;
let DialogInput = null;
let Modal = null;

if (Platform.OS=="web") {
	_isWeb = true;
	Alert = require("../components/web/WebAlert").default;
	Modal = require("../components/web/WebModal").default;
	DialogInput = require("../components/web/WebPrompt").default;
} else {
	Modal = require("react-native").Modal;
	Alert = require("react-native").Alert;
	DialogInput = require("react-native-dialog-input").default;
}


import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";
const TouchableOpacityDB = DebounceComponent(TouchableOpacity);
const ModalView = Platform.OS=="ios" ? SafeAreaView : View;
import { TastingList } from "../components/TastingList";
//import { ScoreAnimation } from "../components/ScoreAnimation"; //TODO debug


import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";

let styles = null;
const config = require("../config.json");
const lang = require("../constants/lang-en.json");


let _league = null;
let _season = null;

let _display = null;
let _SetDisplay = null;

let _activeTeam = null;

let _teamInfo = null;
let _SetTeamInfo = null;
let _teamBreweries = null;
let _SetTeamBreweries = null;
let _teamOwners = null;
let _SetTeamOwners = null;
let _isTeamOwner = false;

let _teamModalVisible = null;
let _SetTeamModalVisible = null;

let _teamModalInviteVisible = null;
let _SetTeamModalInviteVisible = null;

let _brewerySearchVisible = null;
let _SetBrewerySearchVisible = null;

let _oldBrewerySearchText = null;
let _brewerySearchText = null;
let _SetBrewerySearchText = null;

let _brewerySearchResults = null;
let _SetBrewerySearchResults = null;

let _isLoading = null;
let _SetIsLoading = null;

//let _showScores = null; //TODO debug
//let _SetShowScores = null; //TODO debug

let _actiontext = "";

let _lastUpdate = (new Date()).valueOf();
function ResetTimer() {
	_lastUpdate=(new Date()).valueOf();
}

let _noImage = require("../assets/icons/no_image.png");
let _locked = false;
let _untappdSize = {};
let _isSelfRemove = false;


export default function LeagueOverviewScreen(props) {
	styles = global.styles;
	[ _display, _SetDisplay ] = useState();
	[ _teamModalVisible, _SetTeamModalVisible] = useState(false);
	[ _teamModalInviteVisible, _SetTeamModalInviteVisible] = useState(false);
	[ _teamInfo, _SetTeamInfo] = useState();
	[ _teamBreweries, _SetTeamBreweries] = useState();
	[ _teamOwners, _SetTeamOwners] = useState();
	[ _brewerySearchVisible, _SetBrewerySearchVisible] = useState(false);
	[ _brewerySearchText, _SetBrewerySearchText ] = useState("");
	[ _brewerySearchResults, _SetBrewerySearchResults ] = useState(null);
	[ _isLoading, _SetIsLoading ] = useState(false);
	//[ _showScores, _SetShowScores ] = useState(false);  //TODO debug

	//console.log("LeagueOverviewScreen - render");

	React.useEffect(() => {
		_untappdSize.width = (global.screen.width-Helpers.GetScaledSize(53.33))*.65;
		_untappdSize.height = _untappdSize.width/4.14375

		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		const unsubscribe2 = props.navigation.addListener("blur", OnBlur);
		return unsubscribe;
	}, [props.navigation]);


	return (<View style={styles.contentContainer}>
	<ScrollView style={styles.webScrollViewFix} refreshControl={<RefreshControl refreshing={_isLoading} onRefresh={SeasonReload} />} keyboardShouldPersistTaps="always">
		{ !_isWeb && <DialogInput isDialogVisible={_teamModalInviteVisible && _teamModalVisible && !_brewerySearchVisible}
			title={lang.inviteToTeam}
			message={lang.enterInviteEmail}
			hintInput={lang.email}
			submitInput={(inputText) => { OwnerAdd(inputText); }}
			submitText={lang.sendInvite}
			closeDialog={() => {_SetTeamModalInviteVisible(false);}}>
		</DialogInput> }

		<Modal animationType="fade" transparent={false} visible={_teamModalVisible && !_brewerySearchVisible && !_teamModalInviteVisible}>
			<ModalView style={styles.contentContainer}><ScrollView>
				{UIHelpers.Header(lang.team, null, 0)}
				<View style={styles.box}>
					<View style={{alignSelf:"stretch", alignItems:"center"}}>
						{_teamInfo}
					</View>
				</View>

				{UIHelpers.Header(lang.breweries, null, 20)}
				<View style={styles.box}>
					<View style={{alignSelf:"stretch", alignItems:"center"}}>
						{_teamBreweries}
					</View>
				</View>

				{UIHelpers.Header(lang.ownedBy, null, 20)}
				<View style={styles.box}>
					<View style={{alignSelf:"stretch", alignItems:"center"}}>
						{_teamOwners}
						{/*<View style={{paddingTop:Helpers.GetScaledSize(13.33)}}></View>*/}
						{ _isTeamOwner && <TouchableOpacityDB style={[styles.button,{marginTop:Helpers.GetScaledSize(13.33)}]} onPress={OwnerAddModalOpen}><Text style={styles.buttonText}>{lang.addOwner}</Text></TouchableOpacityDB> }
					</View>
				</View>

				<View style={{alignItems:"center", paddingTop:Helpers.GetScaledSize(13.33)}}><TouchableOpacityDB style={[styles.button]} onPress={TeamModalClose}>
					<Text style={styles.buttonText}>{lang.close}</Text>
				</TouchableOpacityDB></View>

				<View style={{ marginTop: Helpers.GetScaledSize(24) }} />
			</ScrollView></ModalView>
		</Modal>

		<Modal animationType="fade" transparent={false} visible={_brewerySearchVisible}>
			<ModalView style={{flex:1}}>
				<TextInput style={[styles.inputBox,{width:global.screen.width+Helpers.GetScaledSize(26.67)}]} autoFocus={true}
					onChangeText={text => BrewerySearch(text)}
					placeholder={lang.searchForBrewery} {...PlaceholderProps}
					value={_brewerySearchText}
				/>

				{_brewerySearchResults}

				<TouchableOpacityDB style={styles.button} onPress={BrewerySearchClose}>
					<Text style={styles.buttonText}>{lang.cancel}</Text>
				</TouchableOpacityDB>
				<View style={{alignItems:"center", paddingTop:Helpers.GetScaledSize(13.33), paddingBottom:Helpers.GetScaledSize(6.67)}}><Image source={require("../assets/images/untappd.png")} style={_untappdSize} /></View>
			</ModalView>
		</Modal>


		<View style={{alignSelf:"stretch"}}>
			{_display}
		</View>
		{ _isLoading && <View style={styles.loading}>
			<ActivityIndicator {...ActivityIndicatorProps} />
		</View> }
	</ScrollView>
	</View>);
}


async function OnFocus() {
	console.log("Overview - OnFocus");
	_activeTeam = null;
	global.context.ScreenRefresh = DataInit;
	DataInit();
}

function OnBlur() {
	console.log("Overview - OnBlur");
	global.context.ScreenRefresh = null;
}

async function SeasonReload() {
	//TODO if keeping, ensure league/season ids are used, but should move code to WS to just resend season/users
	/*await _SetIsLoading(true);

	const now = new Date().valueOf();
	const diff = now - _lastUpdate;

	if (diff>5000) {
		try {
			await API.Rest.UserLoginToken(null, null, _league._id, _season._id);
			DataInit();
			ResetTimer();
		} catch (ex) {
		}
	}

	_SetIsLoading(false);*/
}

function DataInit(updatedItems) {
	console.log("LeagueOverview - DataInit");
	if (updatedItems && updatedItems.indexOf("league")==-1 && updatedItems.indexOf("season")==-1) {
		console.log("LeagueOverview got an update but was NOT relevant");
		return;
	}

	_league = global.context.league;
	_season = global.context.season;
	Helpers.ScoreSeason(_season);

	if (_activeTeam) {
		SetActiveTeam(_activeTeam._id);
		DisplayTeam(_activeTeam);
	}
	DisplayData();
}

function DisplayData() {
	//let showScheduleLink = global.context.isLeagueOwner || (_season && _season.games && _season.games.length>0);

	_SetDisplay(<View style={{alignSelf:"stretch", paddingBottom:Helpers.GetScaledSize(26.67)}}>
		{DisplayLeaderboard()}
		{DisplayOwners()}

		{ /*<TouchableOpacityDB onPress={RunAnimation}>
			<Text>[test ending]</Text>
		</TouchableOpacityDB>
		<ScoreAnimation game={_season.games[0]} CloseMethod={CloseScores} visible={_showScores} /> */ }


		{UIHelpers.Header(lang.nextGame, null, 20)}
		<View style={styles.box}>
			<View style={{alignSelf:"stretch", alignItems:"center"}}>
				{DisplaySession()}
			</View>
		</View>

		{ /* showScheduleLink && <Text style={styles.textLink} onPress={()=>{Helpers.Navigate("LeagueNav", {screen: "SeasonSchedule"});}}>{lang.viewFullSchedule}</Text> */ }

		{DisplayAdmin()}
	</View>);
}

//TODO debug
/*async function RunAnimation() {
	console.log("RunAnimation");
	await _SetShowScores(true);
	DataInit();
}

async function CloseScores() {
	console.log("CloseScores");
	await _SetShowScores(false);
	DataInit();
}*/

function DisplayLeaderboard() {
	if (!_season || !_season.teams || _season.teams.length==0) {
		return <View><Text style={styles.title2}>{_league.name}</Text><Text style={styles.title2}>{lang.noTeams}</Text></View>;
	}

	for (let i=0; i<_season.teams.length; i++) {
		if (Helpers.IsTeamOwner(_season.teams[i])) {
			_season.teams[i].isMine=true;
		}
		//config.imageServers[_season.teams[i].image.loc]
		if (_season.teams[i].image) {
			_season.teams[i].imageSrc = { uri: global.imageBase + _season.teams[i].image.name + ".jpg" };
		}

		if (_season.teams[i].isMine) {
			_season.teams[i].style={backgroundColor:"#ffe0a0"};
		}

		/*if (_season.teams[i].isMine) {
			_season.teams[i].style={backgroundColor:"#d0ffd0"};
		} else if (i%2==0) {
			_season.teams[i].style={backgroundColor:"#e5e5e5"};
		} else {
			_season.teams[i].style={backgroundColor:"#ffffff"};
		}*/
	}

	let completeGames = 0;
	for (let i=0; i<_season.games.length; i++) {
		if (_season.games[i].isClosed) {
			completeGames++;
		}
	}

	/*let teamId = null;
	for (let i=0; i<_season.teams.length; i++) {
		if (Helpers.IsTeamOwner(_season.teams[i])) {
			teamId = _season.teams[i]._id;
			break;
		}
	}*/

	for (let i=0; i<_season.teams.length; i++) {
		_season.teams[i].canEdit = (global.context.isLeagueOwner || Helpers.IsTeamOwner(_season.teams[i]));
	}

	return <View>
		<View style={{flexDirection: "row", justifyContent:"center", alignItems:"center"}}>
			<View style={{flexDirection: "row", justifyContent:"center", alignItems:"center"}}>
				<Text style={[styles.title1b,{textAlign: "center", justifyContent:"center"}]}>{_league.name}</Text>
			</View>
		</View>
		<View style={{alignItems:"center", width:global.screen.width}}>
			{ _season.beers && _season.beers.best && <View style={{flexDirection:"row", alignItems:"center"}}>
				<Text style={[styles.text, {fontSize:Helpers.GetScaledSize(16),maxWidth:global.screen.width-Helpers.GetScaledSize(40)}]}><Text style={[styles.textBold, {fontSize:Helpers.GetScaledSize(14)}]}>{lang.bestBeer}:</Text> {_season.beers.best.name} ({_season.beers.best.score.toFixed(1)})</Text>
			</View> }
			{ _season.beers && _season.beers.worst && <View style={{flexDirection:"row", alignItems:"center"}}>
				<Text style={[styles.text, {fontSize:Helpers.GetScaledSize(16),maxWidth:global.screen.width-Helpers.GetScaledSize(40)}]}><Text style={[styles.textBold, {fontSize:Helpers.GetScaledSize(14)}]}>{lang.worstBeer}:</Text> {_season.beers.worst.name} ({_season.beers.worst.score.toFixed(1)})</Text>
			</View> }
		</View>

		{UIHelpers.Header(lang.leaderboard, "after " + completeGames + " tasting" + (completeGames!=1 ? "s": ""), 13.33)}
		<View style={styles.box}>
			{ /*<View style={{flexDirection: "row", justifyContent:"center"}}>
				<View style={{width: 40}}><Text style={styles.title2b}>{lang.rank}</Text></View>
				<View style={{width: 25}}></View>
				<View style={{width: 170, paddingHorizontal: 6}}><Text style={styles.title2b}>{lang.team}</Text></View>
				<View style={{width: 35}}><Text style={styles.title2b}>{lang.points}</Text></View>
			</View> */ }

			<View style={{flexDirection:"column", justifyContent:"center"}}>
				{ _season.teams.map((item) => (<TeamDisplay team={item} key={item._id} />))}
			</View>
		</View>
	</View>;
}

function TeamDisplay({team}) {
	let rank = null;
	let name = null;
	let score = null;

	if (global.showScores) {
		rank = <View style={[{width: Helpers.GetScaledSize(46.67), alignItems:"center" }]}><Text style={styles.title2b}>{team.rank}</Text></View>;
		name = <View style={[team.style,{ flexDirection: "row", width: global.screen.width-Helpers.GetScaledSize(186.67) }]}>
			<Text style={[styles.title2, {paddingRight:Helpers.GetScaledSize(13.33), paddingLeft:Helpers.GetScaledSize(6.67), width:global.screen.width-Helpers.GetScaledSize(186.67)}]}>{team.name}</Text>
		</View>;
		score = <View style={[team.style,{width: Helpers.GetScaledSize(66.67), justifyContent:"flex-end"}]}><Text style={[styles.title2b, {textAlign:"right"}]}>{team.score.toFixed(1)}</Text></View>;
	} else {
		rank = <View style={[{width: Helpers.GetScaledSize(20), alignItems:"center" }]}><Text style={styles.title2b}></Text></View>;
		name = <View style={[team.style,{ flexDirection: "row", width: global.screen.width-Helpers.GetScaledSize(93.33) }]}>
			<Text style={[styles.title2, {paddingRight:Helpers.GetScaledSize(13.33), paddingLeft:Helpers.GetScaledSize(6.67), width:global.screen.width-Helpers.GetScaledSize(93.33)}]}>{team.name}</Text>
		</View>;
	}

	return <TouchableOpacityDB onPress={()=>{TeamModalOpen(team._id);}}>
		<View style={[team.style, { flexDirection:"row", justifyContent:"center", alignItems:"center", paddingVertical:Helpers.GetScaledSize(2.67), marginBottom:Helpers.GetScaledSize(4)}]}>
			{ rank }
			{ team.imageSrc && <Image source={team.imageSrc} style={{width: Helpers.GetScaledSize(53.33), height: Helpers.GetScaledSize(53.33), borderRadius: Helpers.GetScaledSize(26.67)}} onError={ImageError} /> }
			{ !team.imageSrc && <Image source={_noImage} style={{width: Helpers.GetScaledSize(53.33), height: Helpers.GetScaledSize(53.33), borderRadius: Helpers.GetScaledSize(26.67)}} />}
			{ name }
			{ score }
			<View style={[team.style,{width: Helpers.GetScaledSize(20)}]}></View>
		</View>
	</TouchableOpacityDB>;
}

function TeamEdit(team) {
	global.context.team = team;
	TeamModalClose();
	Helpers.Navigate("TeamSettings");
}

function DisplaySession() {
	let nextGame = { "_id": "none" };

	if (_season && _season.games && _season.games.length>0) {
		for (let i=0; i<_season.games.length; i++) {
			if (!_season.games[i].isClosed) {
				nextGame=_season.games[i];
				break;
			}
		}
	}

	return <TastingList gameId={nextGame._id} SeasonReload={SeasonReload} />;
}

function DisplayAdmin() {
	if (!global.context.isLeagueOwner) {
		return <View></View>;
	}

	return <View>
		{UIHelpers.Header(lang.administration, null, 20)}
		<View style={styles.box}>
			<TouchableOpacityDB style={styles.button} onPress={()=>{Helpers.Navigate("LeagueNav", {screen: "LeagueInvite"});}}><Text style={styles.buttonText}>{lang.manageInvites}</Text></TouchableOpacityDB>
			<View style={{paddingTop: Helpers.GetScaledSize(17.33)}}></View>
			<TouchableOpacityDB style={styles.button} onPress={()=>{Helpers.Navigate("LeagueNav", {screen: "LeagueSettings"});}}><Text style={styles.buttonText}>{lang.editLeagueInfo}</Text></TouchableOpacityDB>
		</View>
	</View>;
}

function GetUserName(id) {
	for (let i=0; i<global.context.seasonUsers.length; i++) {
		if (global.context.seasonUsers[i]._id==id) {
			return global.context.seasonUsers[i].firstName + " " + global.context.seasonUsers[i].lastName;
		}
	}

	return "unknown";
}

function DisplayOwners() {
	let nameText = GetUserName(_league.ownerIds[0]);
	let nameLabel = (_league.ownerIds.length==1 ? lang.commissioner : lang.commissioners)
	for (let i=1; i<_league.ownerIds.length; i++) {
		nameText += ", " + GetUserName(_league.ownerIds[i]);
	}

	return <View><Text style={[styles.title2, {textAlign:"center"}]}>{nameLabel}: {nameText}</Text></View>;
}

function SetActiveTeam(id) {
	for (let i=0; i<_season.teams.length; i++) {
		if (_season.teams[i]._id==id) {
			_activeTeam = _season.teams[i];
			break;
		}
	}
}

function TeamModalOpen(id) {
	SetActiveTeam(id);

	if (_activeTeam) {
		DisplayTeam(_activeTeam);
		_SetTeamModalVisible(true);
	}
}

function TeamModalClose() {
	_SetTeamModalVisible(false);
	_SetTeamInfo();
	_SetTeamBreweries();
	_isTeamOwner = false;
	_SetTeamOwners();
}

async function DisplayTeam(team) {
	let image = null;
	if (team.image) { //config.imageServers[team.image.loc]
		image={ uri: global.imageBase + team.image.name + ".jpg" };
	} else {
		image=_noImage;
	}

	let nameWidth = global.screen.width-Helpers.GetScaledSize(206.67);
	let teamEdit = null;
	if (team.canEdit) {
		nameWidth -= Helpers.GetScaledSize(32);
		teamEdit = <TouchableOpacityDB style={{paddingRight:Helpers.GetScaledSize(5.33), paddingTop:Helpers.GetScaledSize(5.33), alignSelf:"flex-start" }} onPress={() => {TeamEdit(team)}}>
			<Image source={require("../assets/icons/edit.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
		</TouchableOpacityDB>;
	}

	_SetTeamInfo(
		<View style={{flexDirection: "column", justifyContent:"flex-start"}}>
			<View style={{flexDirection: "row", width:global.screen.width-Helpers.GetScaledSize(26.67), alignItems:"center"}}>
				<Image source={image} style={{width: Helpers.GetScaledSize(173.33), height: Helpers.GetScaledSize(173.33), borderRadius: Helpers.GetScaledSize(86.67)}} />
				<Text style={[styles.title1,{paddingLeft:Helpers.GetScaledSize(6.67),width:nameWidth}]}>{team.name}</Text>
				{teamEdit}
			</View>
		</View>
	);

	DisplayTeamBreweries(team);
	DisplayTeamOwners(team);
}

function DisplayTeamBreweries(team) {
	let breweries = [];

	if (!team.draftedBreweries || team.draftedBreweries.length==0) {
		breweries.push(<Text style={styles.title2} key="0">{lang.none}</Text>);
	} else {
		for (let i=0; i<team.draftedBreweries.length; i++) {
			breweries.push(<View style={{flexDirection: "row", paddingBottom:Helpers.GetScaledSize(4), alignItems:"center"}} key={team.draftedBreweries[i].id}>
				<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(18.67), paddingRight:Helpers.GetScaledSize(4)}]}>{team.draftedBreweries[i].name}</Text>
				{ global.context.isLeagueOwner && <TouchableOpacityDB onPress={()=>{BreweryDeleteConfirm(team.draftedBreweries[i]);}}>
					<Image source={require("../assets/icons/delete.png")} style={{width: Helpers.GetScaledSize(20), height: Helpers.GetScaledSize(20)}} />
				</TouchableOpacityDB> }
			</View>);
		}
	}

	if (global.context.isLeagueOwner) {
		breweries.push(<View style={{paddingTop:Helpers.GetScaledSize(13.33)}} key="pad1"></View>);
		breweries.push(<TouchableOpacityDB key="btn1" style={styles.button} onPress={BrewerySearchOpen}><Text style={styles.buttonText}>{lang.addBrewery}</Text></TouchableOpacityDB>);
	}

	_SetTeamBreweries(breweries);
}

function DisplayTeamOwners(team) {
	let users = [];
	_isTeamOwner = (team.ownerIds.indexOf(global.context.userId)>=0) || global.context.isLeagueOwner;

	let numActive = 0;
	for (let i=0; i<team.ownerIds.length; i++) {
		if (team.ownerIds[i].substring(0,2)=="u:") {
			numActive++;
		}
	}
	let canDelete = numActive>1;

	for (let i=0; i<team.ownerIds.length; i++) {
		let user = Helpers.UserFind(team.ownerIds[i]);

		if (user) {
			let userName = user.firstName + " " + user.lastName;
			let imageSrc = null;
			if (user.image) { //config.imageServers[user.image.loc]
				imageSrc={ uri: global.imageBase + user.image.name + ".jpg" };
			} else {
				imageSrc=_noImage;
			}

			let leagueOwnerAdmin = null;
			if (global.context.isLeagueOwner) {
				if (IsLeagueOwner(user._id)) {
					leagueOwnerAdmin = <View style={{paddingLeft: Helpers.GetScaledSize(16.67)}}><TouchableOpacityDB onPress={() => {LeagueOwnerChangeConfirm(user, false)}}>
						<Image source={require("../assets/icons/admin_remove.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
					</TouchableOpacityDB></View>;
				} else {
					leagueOwnerAdmin = <View style={{paddingLeft: Helpers.GetScaledSize(16.67)}}><TouchableOpacityDB onPress={() => {LeagueOwnerChangeConfirm(user, true)}}>
						<Image source={require("../assets/icons/admin_add.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
					</TouchableOpacityDB></View>;
				}
			}

			let removeOwner = null;
			if (_isTeamOwner && canDelete) {
				removeOwner = <View style={{paddingLeft: Helpers.GetScaledSize(16.67)}}><TouchableOpacityDB onPress={()=>{OwnerDeleteConfirm(team.ownerIds[i], userName);}}>
					<Image source={require("../assets/icons/delete.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
				</TouchableOpacityDB></View>;
			}

			users.push(<View key={user._id} style={{flexDirection: "row", width:global.screen.width-Helpers.GetScaledSize(26.67), paddingTop: Helpers.GetScaledSize(13.33), alignItems:"center"}}>
				<Image source={imageSrc} style={{width: Helpers.GetScaledSize(173.33), height: Helpers.GetScaledSize(173.33), borderRadius: Helpers.GetScaledSize(86.67)}} />
				<View style={{flexDirection: "row",width:global.screen.width-Helpers.GetScaledSize(240)}}>
					<Text style={[styles.title1, {paddingLeft:Helpers.GetScaledSize(6.67)}]}>{userName}</Text>
					{removeOwner}
					{leagueOwnerAdmin}
				</View>
			</View>);
		}
	}

	if (_isTeamOwner) {
		for (let i=0; i<team.ownerIds.length; i++) {
			let user = Helpers.UserFind(team.ownerIds[i]);

			if (!user) {
				users.push(<View key={team.ownerIds[i]} style={{flexDirection: "row", width:global.screen.width-Helpers.GetScaledSize(26.67), paddingTop: Helpers.GetScaledSize(13.33), alignItems:"center"}}>
					<View style={{flexDirection: "row"}}>
						<Text style={[styles.title3, {paddingLeft:Helpers.GetScaledSize(6.67),flexWrap:"wrap"}]}><Text style={{fontSize:Helpers.GetScaledSize(15)}}>[invited]</Text> {team.ownerIds[i]}</Text>
						<View style={{paddingLeft:Helpers.GetScaledSize(16.67)}}/>
						<TouchableOpacityDB onPress={()=>{InviteDeleteConfirm(team.ownerIds[i]);}}>
							<Image source={require("../assets/icons/delete.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
						</TouchableOpacityDB>
						<TouchableOpacityDB style={{paddingLeft:Helpers.GetScaledSize(16.67)}} onPress={()=>{InviteResendConfirm(team.ownerIds[i]);}}>
							<Image source={require("../assets/icons/email_send.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
						</TouchableOpacityDB>
					</View>
				</View>);
			}
		}
	}

	_SetTeamOwners(<View>{users}</View>);
}

async function BrewerySearch(text) {
	_SetBrewerySearchText(text);

	if (_oldBrewerySearchText==text) {
		return;
	}
	_oldBrewerySearchText = text;

	if (text.length>=3) {
		setTimeout(async () => {
			if (text==_brewerySearchText) {
				BrewerySearchCall(_brewerySearchText);
			}
		}, (global.context.goodUntappd ? 900 : 1600));
	}
}

async function BrewerySearchCall(text) {
	let results = await API.Rest.BrewerySearchCall(text, BrewerySearchCall);
	if (results) {
		_SetBrewerySearchResults(BreweryDataDisplay(results));
	}
}

function BreweryDataDisplay(data) {
	for (let i=0; i<data.length; i++) {
		//data[i]._id = data[i].brewery_id;
		if (i%2==0) {
			data[i].style=styles.rowAlt;
		} else {
			data[i].style=styles.rowNormal;
		}
	}

	return (<ScrollView style={styles.webScrollViewFix} keyboardShouldPersistTaps="always">{ data.map((brewery) => (
		<TouchableOpacityDB key={brewery._id} onPress={()=>{TeamAddRemoveBrewery(brewery._id, true);}}>
		<View style={[{paddingLeft: Helpers.GetScaledSize(6.67), borderColor: "#808080", borderWidth: 1}, brewery.style]}>
			<View style={{flexDirection:"row"}}>
				{ brewery.brewery_label && <Image style={{width: Helpers.GetScaledSize(106.67), height: Helpers.GetScaledSize(106.67)}} source={{uri: brewery.brewery_label}} /> }

				<View style={{flexDirection:"column", justifyContent:"center" }}>
					<Text style={styles.title2b}>{brewery.brewery_name}</Text>
					<Text style={styles.title2}>{brewery.country_name}{(brewery.location && brewery.location.brewery_state) && (" / " + brewery.location.brewery_state)}</Text>
					<Text style={styles.title2}>{lang.totalBeers} {brewery.beer_count}</Text>
				</View>
			</View>
		</View></TouchableOpacityDB>
	))}</ScrollView>);
}

async function TeamAddRemoveBrewery(breweryId, isAdd) {
	if (_locked) {
		return;
	}

	// check if team (or another team) has brewery
	let teamHas = (_activeTeam.draftedBreweries.findIndex(e => e.id == breweryId)>=0);

	let anotherHas = false;

	if (!teamHas) {
		for (let i=0; i<_season.teams.length; i++) {
			if (_season.teams[i].draftedBreweries.findIndex(e => e.id == breweryId)>=0) {
				anotherHas = true;
				break;
			}
		}
	}

	if (isAdd) {
		if (teamHas) {
			//silent success
			BrewerySearchClose();
			return;
		} else if (anotherHas) {
			Alert.alert(lang.error, lang.breweryAlreadyAssigned, [{text: lang.ok}]);
			return;
		}
	} else {
		if (!teamHas) {
			//silent fail
			return;
		}
	}

	_locked=true;

	let teamUpdate = { "_id": _activeTeam._id };
	if (isAdd) {
		teamUpdate.addBrewery = breweryId;
	} else {
		teamUpdate.removeBrewery = breweryId;
	};

	_actiontext = (isAdd ? lang.breweryUnableAdd : lang.breweryUnableRemove);
	API.SeasonWS.TeamUpdate(teamUpdate, TeamUpdated);
	_locked=false;
}


async function TeamUpdated(success) {
	if (!success) {
		Alert.alert(lang.error, _actiontext, [{text: lang.ok}]);
		return;
	}

	BrewerySearchClose();
	OwnerAddModalClose();

	if (_isSelfRemove) {
		if (!global.context.isLeagueOwner) {
			for (let i=0; i<global.context.leagues.length; i++) {
				if (global.context.leagues[i]._id==global.context.league._id) {
					global.context.leagues.splice(i,1);
					break;
				}
			}
		}
		global.context.league = null;
		global.context.season = null;
		global.context.seasonUsers = null;
		Helpers.Navigate("LeagueNav", { screen: "Leagues" });
	} else {
		SetActiveTeam(_activeTeam._id);
		DisplayTeam(_activeTeam);
	}
}


function BrewerySearchOpen() {
	Helpers.UntappdCheckLogin(null, BrewerySearchOpenModal);
}

function BrewerySearchOpenModal() {
	_SetBrewerySearchVisible(true);
}

function BrewerySearchClose() {
	_oldBrewerySearchText = null;
	_SetBrewerySearchText("");
	_SetBrewerySearchResults(<View><Text></Text></View>);
	_SetBrewerySearchVisible(false);
}

async function BreweryDeleteConfirm(brewery) {
	Alert.alert(lang.removeBrewery, lang.removeThisBrewery + " (" + brewery.name + ")?",
		[{text: lang.cancel, style: "cancel"}, {text: lang.remove, onPress: () => TeamAddRemoveBrewery(brewery.id, false)}],
		{cancelable: false}
	);
}

async function LeagueOwnerChangeConfirm(user, makeOwner) {
	let alertText = makeOwner ? lang.add + " " + user.firstName + " " + user.lastName + " to the league commissioners?" : lang.remove + " " + user.firstName + " " + user.lastName + " from the league commissioners?";
	Alert.alert(lang.leagueCommissioners, alertText,
		[{text: lang.cancel, style: "cancel"}, {text: (makeOwner ? lang.add : lang.remove), onPress: async () => {
			if (await API.Rest.LeagueOwnerChange(_league._id, user, makeOwner)) {
				let alertText = makeOwner ? lang.added + " " + user.firstName + " " + user.lastName + " to the league commissioners." : lang.removed + " " + user.firstName + " " + user.lastName + " from the league commissioners.";
				Alert.alert(lang.success, alertText, [{text: lang.ok}]);
				TeamModalClose();
				LeagueOwnerLocalChange(user._id, makeOwner);
				DisplayData();
			} else {
				Alert.alert(lang.error, lang.errorSettingCommissioners, [{text: lang.ok}]);
			}
		}}]
	);
}

function LeagueOwnerLocalChange(id, makeOwner) {
	if (makeOwner) {
		_league.ownerIds.push(id);
	} else {
		let idx = _league.ownerIds.indexOf(id);

		if (idx>=0) {
			_league.ownerIds.splice(idx,1);
		}
	}
}

function OwnerAddModalOpen() {
	if (_isWeb) {
		DialogInput.prompt(lang.inviteToTeam, lang.enterInviteEmail, lang.email, lang.sendInvite, OwnerAdd, lang.cancel);
	} else {
		_SetTeamModalInviteVisible(true);
	}
}

function OwnerAddModalClose() {
	_SetTeamModalInviteVisible(false);
}

function OwnerAdd(email) {
	email=email.trim();
	if (Helpers.ValidateEmail(email)) {
		OwnerAddModalClose();
		TeamAddRemoveOwner(email, true);
	}
}

async function TeamAddRemoveOwner(userInfo, isAdd, isResend) {
	if (_locked) {
		return;
	}

	// check if team has owner
	let teamHas = (_activeTeam.ownerIds.indexOf(userInfo)>=0);
	_isSelfRemove = false;

	if (isAdd) {
		if (teamHas && !isResend) {
			//silent success
			OwnerAddModalClose();
			return;
		}
	} else {
		if (!teamHas) {
			//silent fail
			OwnerAddModalClose();
			return;
		} else {
			if (userInfo==global.context.userId) {
				_isSelfRemove = true;
			}
		}
	}

	_locked=true;

	let teamUpdate = { "_id": _activeTeam._id };
	if (isAdd) {
		teamUpdate.addOwner = userInfo;
	} else {
		teamUpdate.removeOwner = userInfo;
	};

	_actiontext = (isAdd ? lang.ownerUnableAdd : lang.ownerUnableRemove);
	API.SeasonWS.TeamUpdate(teamUpdate, TeamUpdated);
	_locked=false;
}

async function OwnerDeleteConfirm(userId, name) {
	Alert.alert(lang.removeOwner, "Remove " + name + " as a team owner?",
		[{text: lang.cancel, style: "cancel"}, {text: "Remove", onPress: () => { TeamAddRemoveOwner(userId, false); }}],
		{cancelable: false},
	);
}

async function InviteDeleteConfirm(email) {
	Alert.alert(lang.deleteInvite, "Delete invite for " + email + "?",
		[{text: lang.cancel, style: "cancel"}, {text: "Delete", onPress: () => { TeamAddRemoveOwner(email, false); }}],
		{cancelable: false},
	);
}

async function InviteResendConfirm(email) {
	Alert.alert(lang.resendInvite, "Resend invite for " + email + "?",
		[{text: lang.cancel, style: "cancel"}, {text: "Resend", onPress: () => { TeamAddRemoveOwner(email, true, true); }}],
		{cancelable: false},
	);
}

function IsLeagueOwner(userId) {
	return (_league.ownerIds.indexOf(userId)>=0);
}

function ImageError(e) {
	console.log("ImageError");
	//console.log(e.nativeEvent.error);

	let url = e.nativeEvent.error;
	url = url.substring(url.indexOf("https://"));
	let idx = url.indexOf(" ");
	if (idx==-1) {
		idx = url.indexOf("}");
	}
	url = url.substring(0, idx);

	WaitForURL(url);
}

async function WaitForURL(url) {
	await Helpers.Sleep(2000);

	for (let tries=1; tries<5; tries++) {
		console.log("attempt " + tries);
		if (await API.Rest.URLExists(url)) {
			_SetDisplay(null);
			DisplayData();
			break;
		}
		await Helpers.Sleep(3000*tries);
	}
}

LeagueOverviewScreen.navigationOptions = {
	header: null,
};
