import React from "react";
import { Platform, TouchableWithoutFeedback, Image, Text, View } from "react-native";

import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList, DrawerItem } from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";

import LeaguesScreen from "../screens/LeaguesScreen";
import LeagueOverviewScreen from "../screens/LeagueOverviewScreen";
import LeagueSettingsScreen from "../screens/LeagueSettingsScreen";
import LeagueInviteScreen from "../screens/LeagueInviteScreen";

import UserSettingsScreen from "../screens/UserSettingsScreen";
import ActiveGameScreen from "../screens/ActiveGameScreen";

import SeasonScheduleScreen from "../screens/SeasonScheduleScreen";

import TeamSettingsScreen from "../screens/TeamSettingsScreen";
import Helpers from "../classes/Helpers";

let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


const config = require("../config.json");
const lang = require("../constants/lang-en.json");
let styles = null;

let _props = null;


function MenuButton(props) {
	return (<TouchableWithoutFeedback onPress={() => { props.navigation.openDrawer(); }}>
		<View style={{flexDirection:"row"}}>
			<View style={{paddingLeft:Helpers.GetScaledSize(16)}} />
			<Image source={require("../assets/icons/menu.png")} style={{width: Helpers.GetScaledSize(35), height: Helpers.GetScaledSize(35)}} />
			<View style={{paddingLeft:Helpers.GetScaledSize(16)}} />
		</View>
	</TouchableWithoutFeedback>);
}

function StackWrap(props, screen, name) {
	const Stack1 = createStackNavigator();
	let androidStyle = (Platform.OS!="ios") ? {marginLeft:Helpers.GetScaledSize(45)} : null;

	return (<Stack1.Navigator headerMode="screen">
		<Stack1.Screen name={name} component={screen}
			options={{ headerLeft: () => { return MenuButton(props); }, headerTitleStyle: [styles.title1b, androidStyle], headerStyle: { height: Helpers.GetScaledSize(42)+30 } }} />
	</Stack1.Navigator>);
}

async function NavigateTo(screen) {
	_props.navigation.closeDrawer();
	setTimeout(() => { _props.navigation.navigate("LeagueNav", { screen: screen }); }, 10);
}

function CustomDrawerContent(props) {
	_props=props;
	const dividerStyle = { borderColor:"#505050", borderStyle:"solid", borderTopWidth:1, borderRadius:0 };

	const hasLeague = (global.context.league!=null);
	const isLeagueOwner = hasLeague && (global.context.isLeagueOwner==true);
	const dprop = { marginVertical: Helpers.GetScaledSize(3), paddingVertical:0 };
	const divprop = { marginVertical: Helpers.GetScaledSize(2), paddingVertical:0 }; //5.33

	return (<View style={{flex:1}}>
		<DrawerContentScrollView style={divprop} dividerStyle={{paddingVertical:0,marginVertical:0}}>
			<DrawerItem label={lang.leaguesAndInvites} labelStyle={styles.title2b} style={dprop} onPress={() => { NavigateTo("Leagues"); } } />
			{ hasLeague && <DrawerItem label={lang.leagueDashboard} labelStyle={styles.title2b} style={dprop} onPress={() => NavigateTo("LeagueOverview")} /> }
			{ hasLeague && <DrawerItem label={lang.fullSchedule} labelStyle={styles.title2b} style={dprop} onPress={() => NavigateTo("SeasonSchedule")} /> }
			{ /*isLeagueOwner && <DrawerItem label={lang.leagueSettings} labelStyle={styles.title2b} onPress={() => NavigateTo("LeagueSettings")} />*/ }
			{ /*isLeagueOwner && <DrawerItem label={lang.leagueInvites} labelStyle={styles.title2b} onPress={() => NavigateTo("LeagueInvite")} />*/ }
			{ hasLeague && <DrawerItem label={lang.teamSettings} labelStyle={styles.title2b} style={dprop} onPress={() => NavigateTo("TeamSettings")} /> }
			<DrawerItem label={lang.userSettings} labelStyle={styles.title2b} style={dprop} onPress={() => NavigateTo("UserSettings")} />
		</DrawerContentScrollView>

		<View style={{ position: "absolute", bottom: 0, width:"100%" }}>
			{/*<DrawerItem label="Getting Started Guide" onPress={() => Helpers.OpenURL(lang.urlGuide) } />*/}
			<DrawerItem label={lang.legalInfo} labelStyle={styles.title2b} style={[dividerStyle, dprop]} onPress={() => Helpers.OpenURL(lang.urlLegal)} />
			<DrawerItem label={lang.help} labelStyle={styles.title2b} style={dprop} onPress={() => Helpers.OpenURL(lang.urlHelp)} />
			<DrawerItem label={lang.logout} labelStyle={styles.title2b} style={dprop} onPress={() => Alert.alert(lang.logout, lang.logoutPrompt,
				[{text: lang.cancel, style: "cancel" },
				{text: lang.logout, onPress: () => { Helpers.GoToLogin(); }}],
				{ cancelable: false })
			}/>
		</View>

		<View style={{ position: "absolute", bottom: Helpers.GetScaledSize(9), right: Helpers.GetScaledSize(20) }}>
			<Text style={[styles.text, {fontSize:Helpers.GetScaledSize(15.17), color:"#a0a0a0"}]}>{config.version}</Text>
		</View>
	</View>);
}

export default function MainDrawerNavigator(props) {
	styles = global.styles;
	Helpers.SetNavigator(props.navigation);
	const Drawer = createDrawerNavigator();

	return (<Drawer.Navigator initialRouteName="Leagues" headerMode="screen"
			drawerStyle={{backgroundColor:"#ffffff", width:"67%"}}
			drawerContent={props => CustomDrawerContent(props)}
			drawerContentOptions={{labelStyle: styles.title2b, activeBackgroundColor:"#f6a518", activeTintColor:"#000000"}}>

		<Drawer.Screen name="Leagues" options={{ drawerLabel: lang.leaguesAndInvites}}>{(props) => {return StackWrap(props, LeaguesScreen, lang.leaguesAndInvites);}}</Drawer.Screen>
		<Drawer.Screen name="LeagueOverview" options={{ drawerLabel: lang.leagueDashboard}}>{(props) => {return StackWrap(props, LeagueOverviewScreen, lang.leagueDashboard);}}</Drawer.Screen>
		<Drawer.Screen name="SeasonSchedule" options={{ drawerLabel: lang.fullSchedule }}>{(props) => {return StackWrap(props, SeasonScheduleScreen, lang.fullSchedule)}}</Drawer.Screen>
		<Drawer.Screen name="LeagueSettings" options={{ drawerLabel: lang.leagueSettings }}>{(props) => {return StackWrap(props, LeagueSettingsScreen, lang.leagueSettings)}}</Drawer.Screen>
		<Drawer.Screen name="LeagueInvite" options={{ drawerLabel: lang.leagueInvites }}>{(props) => {return StackWrap(props, LeagueInviteScreen, lang.leagueInvites)}}</Drawer.Screen>
		<Drawer.Screen name="TeamSettings" options={{ drawerLabel: lang.teamSettings }}>{(props) => {return StackWrap(props, TeamSettingsScreen, lang.teamSettings)}}</Drawer.Screen>
		<Drawer.Screen name="UserSettings" options={{ drawerLabel: lang.userSettings }}>{(props) => {return StackWrap(props, UserSettingsScreen, lang.userSettings)}}</Drawer.Screen>
	</Drawer.Navigator>);
}
