import React, { useState } from "react";
import { Platform, ScrollView, TouchableOpacity, View, ActivityIndicator } from "react-native";
import { Image, Text, TextInput } from "react-native";
import SafeAreaView from "react-native-safe-area-view";
import * as Location from "expo-location";

let DateTimePicker = null;
let Modal = null;
let KASV = null;
let Alert = null;
let _isWeb = false;

if (Platform.OS=="web") {
	_isWeb = true;
	Modal = require("./web/WebModal").default;
	Alert = require("../components/web/WebAlert").default;
	KASV = ScrollView;
	//DateTimePicker = require("./web/WebDateTimePicker").default;
} else {
	Modal = require("react-native").Modal;
	Alert = require("react-native").Alert;
	KASV = require("react-native-keyboard-aware-scroll-view").KeyboardAwareScrollView;
	DateTimePicker = require("react-native-modal-datetime-picker").default;
}

import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";

const TouchableOpacityDB = DebounceComponent(TouchableOpacity);
const ModalView = Platform.OS=="ios" ? SafeAreaView : View;


let styles = null;
const lang = require("../constants/lang-en.json");


//check if the location seems like an actual address
let _addressRE = /^.*\d+\s+.*\D+.*\D+.*$/;
let _onClose = null;
let _SeasonReload = null;
let _season = null;
let _team = null;
let _activeGameId = null;
let _untappdSize = {};
let _beerSelectionCategory = "";

let _display = null;
let _SetDisplay = null;

let _gameModalVisible = null;
let _SetGameModalVisible = null;

let _gameId = null;
let _gameTime = null;
let _gameTimeDate = new Date();
let _gameTimeTime = new Date();
let _gameModalTitle = null;
let _SetGameModalTitle = null;
let _gameCategory = null;
let _SetGameCategory = null;
let _gameLocation = null;
let _SetGameLocation = null;

let _showDatePicker = null;
let _SetShowDatePicker = null;
let _showTimePicker = null;
let _SetShowTimePicker = null;

let _date = null;
let _SetDate = null;
let _time = null;
let _SetTime = null;


let _beerModalVisible = null;
let _SetBeerModalVisible = null;

let _beerSearchVisible = null;
let _SetBeerSearchVisible = null;

let _oldBeerSearchText = null;
let _beerSearchText = null;
let _SetBeerSearchText = null;

let _beerSearchResults = null;
let _SetBeerSearchResults = null;

let _breweryInfo = null;
let _selectedBeer = null;
let _breweryName = null;
let _SetBreweryName = null;
let _beerName = null;
let _SetBeerName = null;
let _beerDescription = null;
let _SetBeerDescription = null;

let _isLoading = null;
let _SetIsLoading = null;

let _isSingle = false;
let _gameInfo = {};


//hold values for lock action alerts
let _lockText = "";
let _lockMoreInfo = "";


export function TastingList(props) {
	styles = global.styles;

	_onClose = props.onClose;
	_SeasonReload = props.SeasonReload;

	_season = global.context.season;
	_isSingle = (props.gameId!=null);
	if (_isSingle) {
		_activeGameId = props.gameId;
	}


	[ _display, _SetDisplay ] = useState(null);
	[ _isLoading, _SetIsLoading ] = useState(false);

	[ _date, _SetDate] = useState("");
	[ _time, _SetTime] = useState("");
	[ _showDatePicker, _SetShowDatePicker] = useState(false);
	[ _showTimePicker, _SetShowTimePicker] = useState(false);

	[ _gameModalVisible, _SetGameModalVisible] = useState(false);
	[ _gameModalTitle, _SetGameModalTitle] = useState(false);
	[ _gameCategory, _SetGameCategory ] = useState("");
	[ _gameLocation, _SetGameLocation ] = useState("");

	[ _beerModalVisible, _SetBeerModalVisible] = useState(false);
	[ _beerSearchText, _SetBeerSearchText ] = useState("");
	[ _beerSearchResults, _SetBeerSearchResults ] = useState(null);
	[ _beerSearchVisible, _SetBeerSearchVisible ] = useState(false);
	[ _breweryName, _SetBreweryName ] = useState("");
	[ _beerName, _SetBeerName ] = useState("");
	[ _beerDescription, _SetBeerDescription ] = useState("");


	//place to store extra game info
	for (let i=0; i<_season.games.length; i++) {
		_gameInfo[_season.games[i]._id] = {};
	}

	_team = Helpers.FindTeamByUserId(_season.teams, global.context.userId);
	//console.log("TastingItem gameId " + props.gameId);

	let locked = null;
	let extraInfo = "";
	let activeRound = null;
	let game = null;
	let missingTeams = null;
	if ((_isSingle || _activeGameId) && props.gameId!="none" && _team) {
		let gameId = props.gameId;
		if (_activeGameId) {
			gameId = _activeGameId;
		}
		game = Helpers.FindGame(_season.games, gameId);
		if (game) {
			activeRound = game.activeRound;
			locked = game.teamsLocked;
			if (global.context.isLeagueOwner && game.teamsLocked) {
				_gameInfo[game._id].missingTeams=GetMissingTeams(game);
			}

			if (_gameInfo[game._id].missingTeams) {
				missingTeams = _gameInfo[game._id].missingTeams.join(", ");
			}

			let round = Helpers.FindRound(game.rounds, _team._id);
			if (!round) {
				extraInfo = game.category + " " + game.startTime + " " + game.location;
			} else {
				extraInfo = game.category + " " + game.startTime + " " + game.location + " " + round.beerId + " " + round.description;
			}
		}
	}

	React.useEffect(() => {
		_untappdSize.width = (global.screen.width-Helpers.GetScaledSize(53.33))*.65;
		_untappdSize.height = _untappdSize.width/4.14375

		console.log("TastingItem - use effect");
		DisplayData(_activeGameId);
	}, [props.seasonId, props.gameId, locked, game, extraInfo, activeRound, _season.games.length, missingTeams]);

	const webPickerStyle = { ...styles.text, fontSize: Helpers.GetScaledSize(16), backgroundColor:"#eeeeee" };


	return <View style={{alignSelf:"stretch"}}>
		<Modal animationType="fade" transparent={false} visible={_gameModalVisible}>
			<ModalView style={{alignItems:"center"}}>
				{UIHelpers.Header(_gameModalTitle)}

				<View style={styles.box}>
					<TextInput style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33),marginRight:Helpers.GetScaledSize(33.33)}]}
						onChangeText={(value) => { _SetGameCategory(value); }}
						autoFocus={true}
						value={_gameCategory}
						placeholder={lang.category} {...PlaceholderProps}
					/>

					<View style={{flexDirection:"row"}}>
						<TextInput style={styles.inputBox}
							onChangeText={(value) => { _SetGameLocation(value); }}
							value={_gameLocation}
							placeholder={lang.addressLong} {...PlaceholderProps}
						/>

						<TouchableOpacityDB onPress={GetLocation} style={{paddingLeft:Helpers.GetScaledSize(6.67)}}>
							<Image source={require("../assets/icons/location.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
						</TouchableOpacityDB>
					</View>

					<View style={{ marginTop: Helpers.GetScaledSize(13.33) }} />

					<View style={{flexDirection: "row", paddingRight:Helpers.GetScaledSize(33.33)}}>
						{ !_isWeb && <DateTimePicker isVisible={_showDatePicker} date={_gameTimeDate} mode="date" onConfirm={HandleDatePicked} onCancel={DatePickerHide} /> }
						{ !_isWeb && <DateTimePicker isVisible={_showTimePicker} date={_gameTimeTime} mode="time" is24Hour={false} onConfirm={HandleTimePicked} onCancel={TimePickerHide} /> }

						{ !_isWeb && <TouchableOpacityDB onPress={DatePickerShow}>
							<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(25.67), backgroundColor:"#eeeeee"}]}>{_date}</Text>
						</TouchableOpacityDB> }
						{ _isWeb && <input type="date" id="dtpDate" name="dtpDate" defaultValue={_date} onChange={HandleDatePicked} style={webPickerStyle}></input> }

						<View style={{paddingLeft:Helpers.GetScaledSize(18.67)}} />

						{ !_isWeb && <TouchableOpacityDB onPress={TimePickerShow}>
							<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(25.67), backgroundColor:"#eeeeee"}]}>{_time}</Text>
						</TouchableOpacityDB> }
						{ _isWeb && <input type="time" id="dtpTime" name="dtpTime" defaultValue={_time} onChange={HandleTimePicked} style={webPickerStyle}></input> }
					</View>
					{ !_isWeb && <Text style={[styles.text, {paddingRight:Helpers.GetScaledSize(33.33)}]}>{lang.tapDateOrTime}</Text> }
				</View>

				<View style={{ marginTop: Helpers.GetScaledSize(24) }} />

				<View style={{flexDirection: "row", paddingRight:Helpers.GetScaledSize(33.33)}}>
					<TouchableOpacityDB style={styles.button} onPress={GameModalClose}>
						<Text style={styles.buttonText}>{lang.cancel}</Text>
					</TouchableOpacityDB>

					<View style={{paddingLeft:Helpers.GetScaledSize(26.67)}} />

					<TouchableOpacityDB style={styles.button} onPress={SaveGame}>
						<Text style={styles.buttonText}>{lang.save}</Text>
					</TouchableOpacityDB>
				</View>
			</ModalView>
			{ _isLoading && <View style={styles.loading}>
				<ActivityIndicator {...ActivityIndicatorProps} />
			</View> }
		</Modal>

		<Modal animationType="fade" transparent={false} visible={_beerModalVisible && !_beerSearchVisible}>
			<KASV style={{alignSelf:"stretch"}} keyboardShouldPersistTaps="always">
			<ModalView style={{flex:1, alignItems:"center"}}>
				{UIHelpers.Header(lang.breweries)}
				<View style={styles.box}>
					<View style={{alignSelf:"stretch", alignItems:"center"}}>
						{_breweryInfo}
					</View>
				</View>

				{UIHelpers.Header(lang.beerSelection, null, 20)}
				<View style={styles.box}>
					<View style={{flexDirection:"row", paddingBottom:Helpers.GetScaledSize(5.33), width:global.screen.width-Helpers.GetScaledSize(86.67)}}>
						<Image source={require("../assets/icons/category.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
						<Text style={[styles.title2,{paddingLeft:Helpers.GetScaledSize(2.67)}]}>{_beerSelectionCategory}</Text>
					</View>
					<TouchableOpacityDB onPress={BeerSearchOpen}>
						<View style={{flexDirection:"row", width:global.screen.width-Helpers.GetScaledSize(86.67)}}>
							<Image source={require("../assets/icons/beer.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
							<Text style={[styles.textLink,{paddingLeft:Helpers.GetScaledSize(2.67)}]}>{_breweryName}{_beerName}</Text>
						</View>
					</TouchableOpacityDB>

					<View style={{paddingTop: Helpers.GetScaledSize(10.33)}}/>

					<TextInput multiline
						style={[styles.inputBox, {height:Helpers.GetScaledSize(106.67), width:global.screen.width-Helpers.GetScaledSize(86.67), textAlignVertical:"top", paddingVertical:Helpers.GetScaledSize(4)}]}
						onChangeText={text => _SetBeerDescription(text)}
						placeholder={lang.beerDescriptionForLeague} {...PlaceholderProps}
						value={_beerDescription}
					/>

					<View style={{flexDirection:"row", paddingTop:Helpers.GetScaledSize(20)}}>
						<TouchableOpacityDB style={styles.button} onPress={BeerModalClose}>
							<Text style={styles.buttonText}>{lang.cancel}</Text>
						</TouchableOpacityDB>

						<View style={{paddingLeft:Helpers.GetScaledSize(26.67)}} />

						<TouchableOpacityDB style={styles.button} onPress={BeerSubmit}>
							<Text style={styles.buttonText}>{lang.save}</Text>
						</TouchableOpacityDB>
					</View>
				</View>
			</ModalView>
			{ _isLoading && <View style={styles.loading}>
				<ActivityIndicator {...ActivityIndicatorProps} />
			</View> }
			</KASV>
		</Modal>

		<Modal animationType="fade" transparent={false} visible={_beerSearchVisible}>
			<ModalView style={{flex:1}}>
				<TextInput style={[styles.inputBox,{width:global.screen.width+Helpers.GetScaledSize(26.67)}]} autoFocus={true}
					onChangeText={text => BeerSearch(text)}
					placeholder={lang.searchForBeer} {...PlaceholderProps}
					value={_beerSearchText}
				/>

				{_beerSearchResults}

				<TouchableOpacityDB style={styles.button} onPress={BeerSearchClose}>
					<Text style={styles.buttonText}>{lang.cancel}</Text>
				</TouchableOpacityDB>

				<View style={{alignItems:"center", paddingTop:Helpers.GetScaledSize(13.33), paddingBottom:Helpers.GetScaledSize(6.67)}}><Image source={require("../assets/images/untappd.png")} style={_untappdSize} /></View>
			</ModalView>
		</Modal>


		{_display}

		{ (!_isSingle || _activeGameId=="none") && <View style={{paddingTop:Helpers.GetScaledSize(10.33)}}/> }

		{ !_isSingle && <View style={{flexDirection:"row", justifyContent:"center",marginBottom:Helpers.GetScaledSize(20)}}>
			{ global.context.isLeagueOwner && <View style={{paddingRight:Helpers.GetScaledSize(20)}}>
				<TouchableOpacityDB style={styles.button} onPress={GameModalOpen}>
					<Text style={styles.buttonText}>{lang.addGame}</Text>
				</TouchableOpacityDB>
			</View>}

			<TouchableOpacityDB style={styles.button} onPress={_onClose}>
				<Text style={styles.buttonText}>{lang.close}</Text>
			</TouchableOpacityDB>
		</View> }

		{ _isSingle && _activeGameId=="none" && global.context.isLeagueOwner && <View style={{flexDirection:"row", justifyContent:"center"}}>
			<TouchableOpacityDB style={styles.button} onPress={GameModalOpen}>
				<Text style={styles.buttonText}>{lang.addGame}</Text>
			</TouchableOpacityDB>
		</View> }
	</View>;
}

async function DatePickerShow() {
	await _SetShowDatePicker(true);
}

async function DatePickerHide() {
	await _SetShowDatePicker(false);
}

async function TimePickerShow() {
	await _SetShowTimePicker(true);
}

async function TimePickerHide() {
	await _SetShowTimePicker(false);
}

function DisplayData(gameId) {
	_SetDisplay(<View style={{alignItems:"center"}}>
		{ _isSingle && _activeGameId=="none" && <View><Text style={styles.title2}>{lang.noGame}</Text></View> }
		{ _isSingle && _activeGameId!="none" && TastingItem(Helpers.FindGame(_season.games, gameId)) }
		{ !_isSingle && DisplaySchedule(gameId) }
	</View>);
}


function TastingItem(game) {
	if (!game) {
		return;
	}

	if (game.empty) {
		return game.empty;
	}

	_gameInfo[game._id].dateDisplay=Helpers.FormatFullDate(game.startTime);

	game.canEdit = (global.context.isLeagueOwner && (game.activeDisplay || _isSingle) && !game.isClosed);
	if (game.activeDisplay) {
		game.style=styles.rowHighlight;
	} else {
		game.style=styles.rowNormal;
	}

	if (_addressRE.test(game.location)) {
		_gameInfo[game._id].locationInfo=<TouchableOpacityDB onPress={() => {Helpers.MapOpen(game.location);}}>
			<View style={[styles.gameRow,{paddingLeft:Helpers.GetScaledSize(26.67)}]}>
				<Image source={require("../assets/icons/location.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
				<Text style={[styles.textLink,{paddingLeft:Helpers.GetScaledSize(2.67)}]}>{game.location}</Text>
			</View>
		</TouchableOpacityDB>;
	} else {
		_gameInfo[game._id].locationInfo=<View style={[styles.gameRow,{paddingLeft:Helpers.GetScaledSize(26.67)}]}>
			<Image source={require("../assets/icons/location.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
			<Text style={[styles.title2,{paddingLeft:Helpers.GetScaledSize(2.67)}]}>{game.location}</Text>
		</View>;
	}

	let round = null;
	if (_team) {
		round = Helpers.FindRound(game.rounds, _team._id);
	}

	let mainWidth = global.screen.width-Helpers.GetScaledSize(100);

	let beerText = lang.noBeerSelected;
	if (round && round.beerId) {
		beerText = round.breweryName + " - " + round.beerName;
	}

	let beerLink =	<TouchableOpacityDB onPress={()=>{BeerModalOpen(round, game.category);}}>
		<Text style={[styles.text,styles.textLink,{paddingLeft:Helpers.GetScaledSize(2.67),fontSize: Helpers.GetScaledSize(16.33),width:mainWidth}]}>{beerText}</Text>
	</TouchableOpacityDB>;

	if (game.rounds && game.rounds.length>0 && !round) {
		beerLink = <Text style={[styles.text,{paddingLeft:Helpers.GetScaledSize(2.67),fontSize: Helpers.GetScaledSize(16.33),width:mainWidth}]}>{lang.cannotSubmitBeer}</Text>;
	}

	_gameInfo[game._id].beerData = <Text style={styles.title2}>{lang.didNotSubmitBeer}</Text>;
	if ((!game.isClosed && game.teamsLocked) || (round && round.beerId)) {
		_gameInfo[game._id].beerData = <View>
			{ (round && round.number) &&
			<View style={[styles.gameRow, {paddingLeft:Helpers.GetScaledSize(26.67), paddingTop:Helpers.GetScaledSize(13.33)}]}>
				<Image source={require("../assets/icons/tag.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
				<Text style={[styles.text,{paddingLeft:Helpers.GetScaledSize(2.67), fontSize: Helpers.GetScaledSize(16.33)}]}>Your beer is #{round.number}</Text>
			</View> }
			<View style={[styles.gameRow, {paddingLeft:Helpers.GetScaledSize(26.67)}]}>
				<Image source={require("../assets/icons/beer.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
				{beerLink}
			</View>
			{ (round && round.beerId && round.description!=null && round.description.length>0) &&
			<View style={[styles.gameRow, {paddingLeft:Helpers.GetScaledSize(26.67)}]}>
				<Image source={require("../assets/icons/description.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
				<Text style={[styles.text,{paddingLeft:Helpers.GetScaledSize(2.67),fontSize:Helpers.GetScaledSize(16.33),width:mainWidth}]}>{round && round.description}</Text>
			</View> }
		</View>
	}

	/*let team = global.context.team;
	let teamInfo = null;
	if (team) {
		for (let i=0; i<nextGame.rounds.length; i++) {
			if (nextGame.rounds[i].teamId==team._id) {
				teamInfo = <View style={{paddingTop: 10}}>
					{ !nextGame.rounds[i].beerId && <Text style={styles.textWarning}>{lang.noBeerSelected}</Text> }
				</View>;
			}
		}
	}*/

	_gameInfo[game._id].lockIcon = null;

	if (game.isClosed) {
		_gameInfo[game._id].beerData = ScoreDisplay(game);
	} else {
		if (game.teamsLocked) {
			if (CanUnlockGame(game)) {
				_gameInfo[game._id].lockIcon = <TouchableOpacityDB style={{paddingLeft:Helpers.GetScaledSize(20)}} onPress={()=>{TeamsLockConfirm(game, false);}}>
					<Image source={require("../assets/icons/unlock.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
				</TouchableOpacityDB>;
			}
		} else {
			_gameInfo[game._id].lockIcon = <TouchableOpacityDB style={{paddingLeft:Helpers.GetScaledSize(20)}} onPress={()=>{TeamsLockConfirm(game, true);}}>
				<Image source={require("../assets/icons/lock.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
			</TouchableOpacityDB>;

			_gameInfo[game._id].beerData = <View>
				<Text style={[styles.text,{marginLeft:Helpers.GetScaledSize(26.67),width:global.screen.width-Helpers.GetScaledSize(33.33)}]}>{lang.teamsMustBeLocked}</Text>
			</View>;
		}
	}

	if (global.context.isLeagueOwner && game.teamsLocked && !game.activeRound && !game.isClosed) {
		_gameInfo[game._id].votingLink = <View style={{alignItems:"center",paddingTop:Helpers.GetScaledSize(10.33)}}><TouchableOpacityDB style={styles.button} onPress={()=>{StartSessionConfirm(game);}}><Text style={styles.buttonText}>{lang.startSession}</Text></TouchableOpacityDB></View>;
	} else if (game.teamsLocked && game.activeRound && !game.isClosed) {
		_gameInfo[game._id].votingLink = <View style={{alignItems:"center",paddingTop:Helpers.GetScaledSize(10.33)}}><TouchableOpacityDB style={styles.button} onPress={()=>{OpenSession(true, game);}}><Text style={styles.buttonText}>{lang.openSession}</Text></TouchableOpacityDB></View>;
	} else if (!game.teamsLocked) {
		//game.votingLink = <Text style={[styles.textWarning,styles.title2]}>{lang.needToLockTeams}</Text>;
	}

	let borderStyle = _isSingle ? { borderWidth: 0 } : {};
	let widthOffset = (_gameInfo[game._id].lockIcon==null) ? Helpers.GetScaledSize(42.67) : 0;

	let gameView = <View style={[styles.gameMain, game.style, borderStyle]}>
		<View style={[styles.gameRow,{width:global.screen.width}]}>
			{ !_isSingle && _gameInfo[game._id].expand }
			{ !_isSingle && <Text style={[styles.title1b,{width:global.screen.width-Helpers.GetScaledSize(148)+widthOffset}]}>Tasting {game.num}</Text> }
			{ _isSingle && <View style={[styles.gameRow,{paddingLeft:Helpers.GetScaledSize(26.67)}]}>
				<Image source={require("../assets/icons/category.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
				<Text style={[styles.title2, {width:global.screen.width-Helpers.GetScaledSize(189.33)+widthOffset, paddingLeft:Helpers.GetScaledSize(2.67)}]}>{game.category}</Text>
			</View> }
			{ game.canEdit && <TouchableOpacityDB onPress={()=>{GameDeleteConfirm(game);}}>
				<Image source={require("../assets/icons/delete.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
			</TouchableOpacityDB> }
			{ game.canEdit && _gameInfo[game._id].lockIcon }
			{ game.canEdit && <TouchableOpacityDB onPress={()=>{GameModalOpen(game);}} style={{paddingLeft:Helpers.GetScaledSize(20)}}>
				<Image source={require("../assets/icons/edit.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
			</TouchableOpacityDB>}
		</View>
		{ !_isSingle && <View style={[styles.gameRow,{paddingLeft:Helpers.GetScaledSize(26.67)}]}>
			<Image source={require("../assets/icons/category.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
			<Text style={[styles.title2,{paddingLeft:Helpers.GetScaledSize(2.67)}]}>{game.category}</Text>
		</View> }
		<View style={[styles.gameRow,{paddingLeft:Helpers.GetScaledSize(26.67), width:global.screen.width-Helpers.GetScaledSize(86.67)}]}>
			<Image source={require("../assets/icons/calendar.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
			<View style={{paddingVertical: Helpers.GetScaledSize(2.67)}}><Text style={[styles.title2,{paddingLeft:Helpers.GetScaledSize(2.67)}]}>{_gameInfo[game._id].dateDisplay}</Text></View>
		</View>
		{ _gameInfo[game._id].locationInfo }
		{ (game.activeDisplay || _isSingle) && <View>{_gameInfo[game._id].beerData}</View> }
		{ _isSingle && _gameInfo[game._id].missingTeams!=null && _gameInfo[game._id].missingTeams.length>0 &&
			<View style={[styles.gameRow,{paddingLeft:Helpers.GetScaledSize(26.67), paddingTop:Helpers.GetScaledSize(20), width:global.screen.width-Helpers.GetScaledSize(86.67)}]}>
				<Image source={require("../assets/icons/warning.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
				<View style={{paddingVertical: Helpers.GetScaledSize(2.67)}}><Text style={[styles.text,{color:"red", paddingLeft:Helpers.GetScaledSize(2.67)}]}>Teams without a beer: {_gameInfo[game._id].missingTeams.join(", ")}</Text></View>
			</View> }
		{ _isSingle && _gameInfo[game._id].votingLink!=null && _gameInfo[game._id].votingLink }
	</View>;

	if (_isSingle) {
		return gameView;
	} else {
		return <TouchableOpacity onPress={()=>{ScheduleToggle(game);}} key={game._id} style={{paddingBottom: Helpers.GetScaledSize(6.67)}}>
			{gameView}
		</TouchableOpacity>;
	}
}

async function GameDeleteConfirm(item) {
	//TODO prompt when this fails for having votes
	//"Cannot delete a tasting with votes"

	Alert.alert(lang.deleteTasting, "Are you sure you want to delete this tasting (" + item.category + ")?",
		[{text: lang.cancel, style: "cancel"}, {text: lang.delete, onPress: () => { API.SeasonWS.GameDelete(item._id, GameSaved); }}]
	);
}

function ScheduleToggle(game) {
	let gameId = game.activeDisplay ? null : game._id;
	DisplayData(gameId);
}

function CanUnlockGame(game) {
	if (!game.teamsLocked) {
		return false;
	}

	if (game.isClosed || (game.rounds && game.rounds.length>0)) {
		//beer numbers have been assigned
		return false;
	}

	return true;
}


async function TeamsLockConfirm(game, isLock) {
	_lockText = isLock ? "Lock" : "Unlock";
	let lockDesc = isLock ? "Once you lock the teams, new teams cannot join this tasting. Are you sure you want to do this?" : "Are you sure you want to unlock the teams?";

	_lockMoreInfo = "Unable to " + _lockText.toLowerCase() + " teams.";
	if (!isLock) {
		_lockMoreInfo = _lockMoreInfo + " Cannot unlock once beers have been submitted.";
	}

	Alert.alert(_lockText + " Teams", lockDesc,
		[{text: lang.cancel, style: "cancel"}, {text: _lockText, onPress: async () => {
			game.teamsLocked=isLock;

			let gameUpdate = { "_id": game._id, "category": game.category, "location": game.location, "teamsLocked": isLock, "startTime": game.startTime };
			API.SeasonWS.GameSave(gameUpdate, TeamsLocked);
		}
	}]);
}

function TeamsLocked(success) {
	if (success) {
		Alert.alert(lang.success, "Teams are now " + _lockText.toLowerCase() + "ed.", [{text: lang.ok}]);
		DisplayData(_activeGameId);
	} else {
		Alert.alert(lang.error, _lockMoreInfo, [{text: lang.ok}]);
	}
}

function ScoreDisplay(game) {
	if (!game.rounds) {
		return null;
	}

	for (let i=0; i<game.rounds.length; i++) {
		game.rounds[i].scoreStyle=game.rounds[i].isDQ ? {textDecorationLine: "line-through"} : null;
		if (!game.rounds[i].breweryName) {
			game.rounds[i].breweryName=lang.noBeerSubmitted;
		}

		if (i%2==0) { //fbl oranges c17515, f6a518, fbc36e, fee2ba
			game.rounds[i].style={backgroundColor:"#fff0da", borderColor:"#909090", borderStyle:"solid", borderTopWidth:1 }; //styles.rowAlt b5ffb5
		} else {
			game.rounds[i].style={backgroundColor:"#fff0da", borderColor:"#909090", borderStyle:"solid", borderTopWidth:1 }; //styles.rowAlt b5ffb5
			//game.rounds[i].style={backgroundColor:"#fee2ba"}; // fbc36e styles.rowNormal dafada
		}
	}

	for (let i=0; i<game.rounds.length; i++) {
		game.rounds[i].gameId = game._id;
	}

	return (<View style={{alignSelf:"stretch"}}>{game.rounds.map(RoundItem)}</View>);
}

function RoundItem(round) {
	//Helpers.LogObject(round);
	if (isNaN(round.voteAvg)) {
		round.voteAvg=0;
	}

	return <View style={[{flexDirection:"row", alignItems:"center"}, round.style]} key={(round.teamId)}>
		<Text style={[styles.title1,{paddingTop:Helpers.GetScaledSize(4), paddingBottom:Helpers.GetScaledSize(4), width:Helpers.GetScaledSize(33.33), textAlign:"center"}]}>{!round.isDQ && round.voteAvg>0 && round.voteRank}</Text>
		<View style={{flexDirection:"column", width: global.screen.width-Helpers.GetScaledSize(120)}}>
			<Text style={[styles.textBold,{fontSize: Helpers.GetScaledSize(15.17)}]}>{Helpers.FindTeam(_season.teams, round.teamId).name}</Text>
			<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(15.17)}]}>{round.breweryName}</Text>
			<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(15.17)}]}>{round.beerName}</Text>
		</View>
		<Text style={[styles.title1, {width:Helpers.GetScaledSize(57.33)}, round.scoreStyle]}>{round.voteAvg!=null && round.voteAvg.toFixed(1)}</Text>
		{ global.context.isLeagueOwner && round.voteAvg>0 && !round.isDQ && <TouchableOpacityDB onPress={()=>{ScoreDQConfirm(round.gameId, round.teamId, true);}}>
			<Image source={require("../assets/icons/delete.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
		</TouchableOpacityDB> }
		{ global.context.isLeagueOwner && round.voteAvg>0 && round.isDQ && <TouchableOpacityDB onPress={()=>{ScoreDQConfirm(round.gameId, round.teamId, false);}}>
			<Image source={require("../assets/icons/add.png")} style={{width: Helpers.GetScaledSize(22.67), height: Helpers.GetScaledSize(22.67)}} />
		</TouchableOpacityDB> }
	</View>;
}

async function ScoreDQConfirm(gameId, teamId, isDQ) {
	let term = isDQ ? lang.disqualify : lang.allow;
	Alert.alert(term + " " + lang.score, "Are you sure you want to " + term.toLowerCase() + " the score for " + Helpers.FindTeam(_season.teams, teamId).name + "?",
		[{text: lang.cancel, style: "cancel"}, {text: term, onPress: () => ScoreDQ(gameId, teamId, isDQ)}]
	);
}

async function ScoreDQ(gameId, teamId, isDQ) {
	//TODO find team and disquality in UI
	//let round = Helpers.FindRound(game.rounds, teamId);
	//_activeGameId = game._id;
	//round.isDQ = isDQ;

	let postBody = { "_id": gameId };
	if (isDQ) {
		postBody.dq=teamId;
	} else {
		postBody.undq=teamId;
	}

	API.SeasonWS.GameSave(postBody, ScoreDQed);
}

function ScoreDQed(success) {
	Helpers.ScoreRounds(Helpers.FindGame(_season.games, _activeGameId));
	DisplayData(_activeGameId);
}

async function SaveGame() {
	if (_gameCategory.length<2) {
		Alert.alert(lang.invalidCategory, lang.invalidCategoryText, [{text: lang.ok}]);
		return;
	}

	if (_gameLocation.length<2) {
		Alert.alert(lang.invalidAddress, lang.invalidAddressText, [{text: lang.ok}]);
		return;
	}

	let game = { "_id": _gameId, "category": _gameCategory, "location": _gameLocation, "startTime": Helpers.GetGameDate(_gameTime) };

	await Helpers.SetIsLoading(_SetIsLoading);
	API.SeasonWS.GameSave(game, GameSaved);
}

function GameSaved(success) {
	if (success) {
		GameModalClose();
	} else {
		Alert.alert(lang.error, lang.unableToSave, [{text: lang.ok}]);
	}
}

async function HandleDatePicked(date) {
	if (_isWeb) {
		date = new Date(date.currentTarget.value.replace(/-/g, "/"));
	} else {
		await DatePickerHide();
	}

	_gameTime.year = date.getFullYear();
	_gameTime.month = date.getMonth()+1;
	_gameTime.day = date.getDate()
	_gameTimeDate = date;

	if (!_isWeb) {
		await _SetDate(Helpers.FormatDate(_gameTime.year, _gameTime.month, _gameTime.day));
	}
}

async function HandleTimePicked(date) {
	if (_isWeb) {
		let d1 = _gameTimeDate;
		d1.setHours(date.currentTarget.value.substr(0,2));
		d1.setMinutes(date.currentTarget.value.substr(3,2));
		date = d1;
	} else {
		await TimePickerHide();
	}

	_gameTime.hour = date.getHours();
	_gameTime.minute = date.getMinutes();
	_gameTimeTime = date;

	if (!_isWeb) {
		await _SetTime(Helpers.FormatTime(_gameTime.hour, _gameTime.minute));
	}
}

function GameModalOpen(item) {
	let curDate = (!item || !item.startTime) ? new Date() : new Date(item.startTime);

	_gameTime = {
		year: curDate.getFullYear(),
		month: curDate.getMonth()+1,
		day: curDate.getDate(),
		hour: curDate.getHours(),
		minute: curDate.getMinutes()
	}

	if (!item || !item.startTime) {
		_gameId=null;
		_gameTime.hour = 19;
		_gameTime.minute = 30;

		let d1 = new Date();
		d1.setFullYear(_gameTime.year);
		d1.getMonth(_gameTime.month);
		d1.setDate(_gameTime.day);
		d1.setHours(_gameTime.hour);
		d1.setMinutes(_gameTime.minute);
		d1.setSeconds(0);

		_gameTimeDate = d1;
		_gameTimeTime = d1;

		_SetGameModalTitle(lang.addAGame)
		_SetGameCategory("");
		_SetGameLocation("");
	} else {
		_gameId=item._id;
		_SetGameModalTitle(lang.editGame)
		_SetGameCategory(item.category);
		_SetGameLocation(item.location);
	}

	if (_isWeb) {
		_SetDate(_gameTime.year + "-" + Helpers.PadNumber(_gameTime.month,2) + "-" + Helpers.PadNumber(_gameTime.day,2));
		_SetTime(Helpers.PadNumber(_gameTime.hour,2) + ":" + Helpers.PadNumber(_gameTime.minute,2));
	} else {
		_SetDate(Helpers.FormatDate(_gameTime.year, _gameTime.month, _gameTime.day));
		_SetTime(Helpers.FormatTime(_gameTime.hour, _gameTime.minute));
	}

	_SetGameModalVisible(true);
}

function GameModalClose() {
	_SetGameModalVisible(false);
}

async function BeerSearch(text) {
	_SetBeerSearchText(text);

	if (_oldBeerSearchText==text) {
		return;
	}
	_oldBeerSearchText = text;

	if (text.length>=4) {
		setTimeout(() => {
			if (text==_beerSearchText) {
				BeerSearchCall(text);
			}
		}, (global.context.goodUntappd ? 900 : 1600));
	}
}

async function BeerSearchCall(text) {
	let results = await API.Rest.BeerSearchCall(text, BeerSearchCall);
	_SetBeerSearchResults(BeerDataDisplay(results));
}

function BeerDataDisplay(data) {
	for (let i=0; i<data.length;  i++) {
		if (i%2==0) {
			data[i].style=styles.rowAlt;
		} else {
			data[i].style=styles.rowNormal;
		}

		if (_team.draftedBreweries.findIndex(e => e.id == data[i].brewery_id)==-1) {
			data[i].style=styles.rowBad;
		}
	}


	return (<ScrollView style={styles.webScrollViewFix} keyboardShouldPersistTaps="always">
		{ data.map((beer) => (
			<TouchableOpacityDB key={beer._id} onPress={()=>{BeerSelect(beer);}}>
			<View style={[{borderColor: "#808080", borderWidth: 1}, beer.style]}>
				<View style={{flexDirection:"row"}}>
					{ beer.beer_label && <Image style={{width: Helpers.GetScaledSize(106.67), height: Helpers.GetScaledSize(106.67)}} source={{uri: beer.beer_label}} /> }

					<View style={{flexDirection:"column", justifyContent:"center"}}>
						<Text style={styles.title3b}>{beer.beer_name}</Text>
						<Text style={styles.title3}>{beer.beer_style}</Text>
						<Text style={styles.title3}>ABV: {beer.beer_abv}{beer.beer_ibu>0 && (", IBU: " + beer.beer_ibu)}</Text>
						<Text style={styles.title3}>brewed by {beer.brewery_name}</Text>
					</View>
				</View>
			</View></TouchableOpacityDB>
		))}
	</ScrollView>);
}

function BeerSelect(beer) {
	if (!_team.draftedBreweries || _team.draftedBreweries.findIndex(e => e.id == beer.brewery_id)==-1) {
		Alert.alert("Beer Issue", "You do not have the brewery for this beer.", [{text: lang.ok}]);
		return;
	}

	_selectedBeer = beer;
	_SetBreweryName(beer.brewery_name + " - ");
	_SetBeerName(beer.beer_name);

	BeerSearchClose();
}

async function BeerSubmit() {
	if (!_selectedBeer || _selectedBeer._id==0) {
		Alert.alert("Need More Info", "Please select a beer.", [{text: lang.ok}]);
		return;
	}

	if (_beerDescription.length==0) {
		Alert.alert("Need More Info", "Please type a description for your beer.", [{text: lang.ok}]);
		return;
	}

	if (_beerDescription.length<3) {
		Alert.alert("Need More Info", "Please enter at least 3 characters for the description.", [{text: lang.ok}]);
		return;
	}

	await Helpers.SetIsLoading(_SetIsLoading);
	API.SeasonWS.GameSubmitBeer(_activeGameId, _selectedBeer._id, _beerDescription, BeerSubmitted);
}

function BeerSubmitted(success) {
	if (success) {
		BeerModalClose();
	} else {
		Alert.alert(lang.error, lang.errorSubmittingBeer, [{text: lang.ok}]);
	}
}

function BeerSearchOpen() {
	Helpers.UntappdCheckLogin(null, BeerSearchOpenModal);
}

function BeerSearchOpenModal() {
	_SetBeerSearchVisible(true);
}


function BeerSearchClose() {
	_oldBeerSearchText = null;
	_SetBeerSearchText("");
	_SetBeerSearchResults(<View><Text></Text></View>);
	_SetBeerSearchVisible(false);
}

function BeerModalOpen(round, category) {
	if (!round || !round.beerId) {
		_SetBreweryName(lang.noBeerSelected);
		_SetBeerName("");
		_SetBeerDescription("");
	} else {
		_selectedBeer = { "_id": round.beerId }
		_SetBreweryName(round.breweryName + " - ");
		_SetBeerName(round.beerName);
		_SetBeerDescription(round.description);
	}

	_beerSelectionCategory = category;
	_breweryInfo = [];

	if (!_team.draftedBreweries || _team.draftedBreweries.length==0) {
		_breweryInfo.push(<Text style={styles.title2} key="0">{lang.none}</Text>);
	} else {
		for (let i=0; i<_team.draftedBreweries.length; i++) {
			_breweryInfo.push(<View style={{flexDirection: "row", width:global.screen.width-Helpers.GetScaledSize(46.67), paddingBottom:Helpers.GetScaledSize(4), alignItems:"center", justifyContent:"center"}} key={_team.draftedBreweries[i].id}>
				<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(16.33), paddingRight:Helpers.GetScaledSize(4) }]}>{_team.draftedBreweries[i].name}</Text>
			</View>);
		}
	}

	_SetBeerModalVisible(true);
}

function BeerModalClose() {
	_SetBeerModalVisible(false);
}


function DisplaySchedule(gameId) {
	_activeGameId=gameId;

	if (!_season || !_season.games || _season.games.length==0) {
		return <View><Text style={styles.title2}>{lang.noGame}</Text></View>;
	}

	let gamesUpcoming = [];
	let gamesCompleted = [];

	if (!_team && !global.context.isLeagueOwner) {
		Alert.alert(lang.error, lang.cannotFindTeam, [{text: lang.ok}]);
		return;
	}

	for (let i=0; i<_season.games.length; i++) {
		let game = _season.games[i];

		if (game.isClosed) {
			Helpers.ScoreRounds(game);
		}

		game.num=i+1;
		game.activeDisplay = (game._id==_activeGameId);

		if (game.activeDisplay) {
			_gameInfo[game._id].expand = <Image source={require("../assets/icons/box_minus.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />;
		} else {
			_gameInfo[game._id].expand = <Image source={require("../assets/icons/box_plus.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67), paddingLeft:Helpers.GetScaledSize(4) }} />;
		}

		if (game.isClosed) {
			gamesCompleted.push(game);
		} else {
			gamesUpcoming.push(game);
		}
	}

	if (gamesUpcoming.length==0) {
		gamesUpcoming.push({ "empty": <View key="emptygame" style={[styles.gameRow, {alignItems:"center"}]}><Text style={[styles.title2, {textAlign:"center", width:global.screen.width}]}>{lang.noGame}</Text></View> });
	}

	/*for (let i=0; i<games.length; i++) {
		if (i%2==0) {
			games[i].style=styles.rowAlt;
		} else {
			games[i].style=styles.rowNormal;
		}
	}*/

	return <View>
		{ UIHelpers.Header(lang.upcomingGames) }
		<View style={styles.box}>
			{ gamesUpcoming.map(game => { return TastingItem(game); }) }
		</View>

		{ (gamesCompleted.length>0) && UIHelpers.Header(lang.completedGames, null, 20) }
		{ (gamesCompleted.length>0) && <View style={styles.box}>{
			gamesCompleted.map(game => { return TastingItem(game); })
		}</View> }
	</View>;
}

function GetMissingTeams(game) {
	let missing=[];
	for (let i=0; i<_season.teams.length; i++) {
		if (!HasBeer(game, _season.teams[i]._id)) {
			missing.push(_season.teams[i].name);
		}
	}

	return missing;
}

async function StartSessionConfirm(game) {
	let missing = GetMissingTeams(game);
	if (missing.length==0) {
		//all teams have a beer selected
		StartSession(game);
		return;
	}

	if (_season.teams.length-missing.length>=2) {
		Alert.alert(lang.needBeerSelections, "Not all teams have submitted their beers (" + missing + "). Do you want to continue without them?",
			[{text: lang.cancel, style: "cancel"}, {text: "Start Without Teams", onPress: () => StartSession(game)}]
		);
	} else {
		Alert.alert(lang.needBeerSelections, "Teams need to select their beers. (" + missing + ")", [{text: lang.ok}]);
	}
}

async function StartSession(game) {
	game.activeRound=Helpers.RoundFindNext(game, 0);

	console.log("StartSession activeRound is " + game.activeRound);
	let gameUpdate = { "_id": game._id, "category": game.category, "location": game.location, "startTime": game.startTime, "activeRound": game.activeRound };

	global.context.game = game;
	API.SeasonWS.GameSave(gameUpdate, OpenSession);
}

function HasBeer(game, teamId) {
	let round = null;
	if (!game.rounds) {
		return false;
	}
	for (let i=0; i<game.rounds.length; i++) {
		if (game.rounds[i].teamId==teamId) {
			round = game.rounds[i];
			break;
		}
	}

	return (round && round.beerId && round.beerId>0);
}

async function OpenSession(success, game) {
	if (game) {
		global.context.game = game;
	}

	if (success) {
		//console.log("opening session with " + JSON.stringify(global.context.game));
		Helpers.Navigate("ActiveGame");
	} else {
		global.context.game = null;
		Alert.alert(lang.error, lang.errorStartingSession, [{text: lang.ok}]);
	}
}

async function GetLocation() {
	console.log("requesting location...");
	let { status } = await Location.requestPermissionsAsync();
	console.log(status);
	if (status!="granted") {
		console.log("did NOT get it... " + status);
		return;
	}

	let location = null;
	try {
		if (Platform.OS=="web") {
			Location.setApiKey("AIzaSyCULudFkA9_3xakNX4MpABi2Z54xlJw9pg");
		}

		console.log("req1");
		location = await Location.getLastKnownPositionAsync();
		console.log("location1 getLastKnownPositionAsync");
	} catch(ex) {
		console.log("error getting getLastKnownPositionAsync");
	}

	if (!location) {
		try {
			console.log("req2");
			location = await Location.getCurrentPositionAsync({});
			console.log("location2 getCurrentPositionAsync");
		} catch(ex) {
			console.log("error getting getCurrentPositionAsync");
		}
	}

	console.log(location);
	if (!location) {
		return;
	}

	let locInfo = await Location.reverseGeocodeAsync(location.coords);
	if (locInfo.length>0) {
		locInfo=locInfo[0];
	}
	console.log(locInfo);

	let cityText = "";

	if (locInfo.isoCountryCode=="US") {
		cityText = locInfo.city + ", " + Helpers.GetStateAbbr(locInfo.region) + " " + locInfo.postalCode;
	} else if (locInfo.isoCountryCode=="CA") {
		cityText = locInfo.city + ", " + Helpers.GetStateAbbr(locInfo.region) + " " + locInfo.postalCode + ", Canada";
	} else if (locInfo.isoCountryCode=="GB") {
		if (locInfo.city) {
			cityText = locInfo.city + ", ";
		}
		cityText += locInfo.region;
		if (locInfo.postalCode) {
			cityText += " " + locInfo.postalCode;
		}
	} else {
		if (locInfo.city) {
			cityText = locInfo.city + ", ";
		} else if (locInfo.region) {
			cityText = locInfo.region + ", ";
		}
		cityText += locInfo.country;
		if (locInfo.postalCode) {
			cityText += " " + locInfo.postalCode;
		}

	}

	let streetText = "";
	if (locInfo.name && locInfo.street && locInfo.name.indexOf(locInfo.street)<0) {
		streetText = locInfo.name + " " + locInfo.street;
	} else if (locInfo.name) {
		streetText = locInfo.name;
	} else if (locInfo.street) {
		streetText = locInfo.street;
	}

	let locText = "";
	if (streetText.length>0) {
		locText = streetText + ", " + cityText;
	} else {
		locText = cityText;
	}

	_SetGameLocation(locText);
}
