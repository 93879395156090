import React from "react";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import * as Linking from "expo-linking";

let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


const _monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
const _dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const lang = require("../constants/lang-en.json");

let _navigator = null;


const Helpers = {

SetNavigator: function(nav) {
	_navigator = nav;
},

Navigate: function(routeName, params) {
	if (!_navigator) {
		return;
	}

	console.log("navigating to " + routeName + " with " + JSON.stringify(params));
	_navigator.navigate(routeName, params);
},

Sleep: function(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
},

PadNumber(num, length) {
	var str = "0000000000" + num;
	return str.substr(str.length-length);
},

FormatFullDate: function(textDate) {
	return Helpers.FormatDate(textDate) + ", " + Helpers.FormatTime(textDate);
},

FormatDate: function(yyyy, mm, dd) {
	let date = (mm==null) ? new Date(yyyy) : new Date(yyyy, mm-1, dd);

	return _dayNames[date.getDay()] + " " + _monthNames[date.getMonth()] + " " + date.getDate();
},

FormatTime: function(hour, minute) {
	//check if passing a text time instead of separated hour/min
	if (minute==null) {
		let date = new Date(hour);

		hour = date.getHours();
		minute = date.getMinutes();
	}

	let ampm = (hour<12) ? "am" : "pm";
	if (hour==0) { hour=12; }
	if (hour>12) { hour-=12; }

	let minText = (minute<10) ? "0" + minute : minute;
	if (minute==0) {
		return hour + ampm;
	} else {
		return hour + ":" + minText + ampm;
	}
},

GetGameDate: function(gametime) {
	return new Date(gametime.year, gametime.month-1, gametime.day, gametime.hour, gametime.minute);
},

TeamGetByUser: function(season, userId) {
	for (let i=0; i<season.teams.length; i++) {
		if (season.teams[i].ownerIds.indexOf(userId)>=0) {
			return season.teams[i];
		}
	}

	return null;
},

HandlePushNotify: async function(msg) {
	//{"userText":null,"actionId":null,"remote":true,"isMultiple":false,"notificationId":-691150868,"data":{"json1":"val1"},"origin":"received"}
	//console.log(JSON.stringify(msg));
	console.log("got push notification");

	if (!msg.data || !msg.data.pushAction) {
		console.log("invalid push notification");
		return;
	}

	console.log("action is " + msg.data.pushAction);

	/*if (msg.data.pushAction=="ActiveGame") {
		//console.log("msg.data.game is " + JSON.stringify(msg.data.game));
		ActiveGamePushUpdate(msg.data);
	}*/
},

ScoreSeason: function(season) {
	if (season.scored) {
		return;
	}

	let teamScores = {};
	for (let i=0; i<season.teams.length; i++) {
		teamScores[season.teams[i]._id]=0;
	}

	let beers = {};

	for (let i=0; i<season.games.length; i++) {
		Helpers.ScoreRounds(season.games[i], teamScores, beers);
	}

	for (let i=0; i<season.teams.length; i++) {
		season.teams[i].score = teamScores[season.teams[i]._id];
	}

	Helpers.TeamsSort(season.teams);

	let rank=1;
	let lastScore=-1;
	for (let i=0; i<season.teams.length; i++) {
		let team = season.teams[i];
		if (i>0 && team.score!=lastScore) {
			rank++;
		}

		lastScore=team.score;
		team.rank=rank;
	}

	if (beers.best) {
		season.beers=beers;
	}

	season.scored=true;
},

ScoreRounds: function(game, teamScores, beers) {
	if (!game.isClosed || !game.rounds) {
		return;
	}

	for (let i=0; i<game.rounds.length; i++) {
		let round = game.rounds[i];
		let total = 0;
		let count = 0;

		for (let j=0; j<round.votes.length; j++) {
			let vote = round.votes[j];
			if (vote.teamId!=round.teamId) {
				total+=(vote.taste+vote.aroma+vote.appearance+vote.mouthfeel);
				count++;
			}
		}

		round.voteTotal=total;
		round.voteCount=count;
		round.voteAvg=(total/count);

		if (!round.beerId) {
			round.voteTotal=0;
			round.voteCount=0;
			round.voteAvg=0;
		}

		if (beers && !round.isDQ && round.voteAvg>0) {
			if (!beers.best || round.voteAvg>beers.best.score) {
				beers.best = { "name": round.beerName, "brewery": round.breweryName, "score": round.voteAvg };
			}
			if (!beers.worst || round.voteAvg<beers.worst.score) {
				beers.worst = { "name": round.beerName, "brewery": round.breweryName, "score": round.voteAvg };
			}
		}
	}

	Helpers.RoundsSort(game.rounds);

	let rank=1;
	let lastAvg=-1;
	for (let i=0; i<game.rounds.length; i++) {
		let round = game.rounds[i];

		if (round.isDQ || round.voteAvg==0) {
			//finish list tied with rank
			rank=i+1;
			for (; i<game.rounds.length; i++) {
				game.rounds[i].voteRank=rank;
			}
			break;
		}

		if (round.voteAvg!=lastAvg) {
			rank=i+1;
		}

		lastAvg=round.voteAvg;
		round.voteRank=rank;
	}

	if (teamScores) {
		let votedRounds=game.rounds.length;
		/*for (let i=0; i<game.rounds.length; i++) {
			if (!game.rounds[i].isDQ && game.rounds[i].voteAvg>0) {
				votedRounds++;
			}
		}*/

		for (let i=0; i<game.rounds.length; i++) {
			if (game.rounds[i].isDQ || !game.rounds[i].beerId) {
				break; //stop when we get to DQed or no beer
			}

			let ties = Helpers.TieCount(game.rounds, game.rounds[i].voteRank);
			let score = votedRounds - game.rounds[i].voteRank - (ties/2.0) + 1;
			for (let j=0; j<=ties; j++) {
				teamScores[game.rounds[i+j].teamId] += score;
			}
			i+=ties;
		}
	}
},

RoundsSort: function(rounds) {
	rounds.sort(function(a, b) {
		if (a.isDQ==null) {
			a.isDQ=false;
		}
		if (b.isDQ==null) {
			b.isDQ=false;
		}

		if (a.isDQ==b.isDQ) {
			return 0;
		}

		return (a.isDQ>b.isDQ ? 1: -1);
	}); //sort DQ to bottom

	rounds.sort(function(a, b) {
		if (a.isDQ!=b.isDQ) {
			return 0;
		}

		if (a.voteAvg < b.voteAvg) {
			return 1;
		} else if (a.voteAvg > b.voteAvg) {
			return -1;
		}
		return 0;
	}); // sort highest to lowest inside normal and DQ
},

TeamsSort: function(teams) {
	teams.sort((a, b) => (a.score < b.score) ? 1 : -1); //sort by score

	teams.sort(function(a, b) {
		if (a.score!=b.score) {
			return 0;
		}

		const n1 = a.name.toLowerCase();
		const n2 = b.name.toLowerCase();
		if (n1>n2) {
			return 1;
		} else if (n1<n2) {
			return -1;
		}
		return 0;
	}); // sort team names for ties
},

TieCount: function(rounds, rank) {
	let count=-1;

	for (let i=0; i<rounds.length; i++) {
		if (!rounds[i].isDQ && rounds[i].voteRank==rank) {
			count++;
		}
	}

	return count;
},

IsTeamOwner: function(team, userId) {
	if (!userId) {
		userId=global.context.userId;
	}
	return (team.ownerIds.indexOf(userId)>=0);
},

ValidateEmail: function(email) {
	if (!email || email.length==0) {
		Alert.alert(lang.needEmail, lang.enterEmail, [{text: lang.ok}]);
		return false;
	}
	email = email.trim();

	if (email.length<6 || !emailRE.test(email)) {
		Alert.alert(lang.invalidEmail, lang.correctEmail, [{text: lang.ok}]);
		return false;
	}

	return true;
},

MapOpen: function(address) {
	let daddr = encodeURIComponent(address);

	if (Platform.OS=="ios") {
		Helpers.OpenURL(`http://maps.apple.com/?daddr=${daddr}`);
	} else {
		Helpers.OpenURL(`http://maps.google.com/?daddr=${daddr}`);
	}
},

OpenURL: function(url, useSameTab) {
	if (Platform.OS=="web") {
		if (useSameTab) {
			window.open(url, "_self");
		} else {
			window.open(url, "_blank");
		}
	} else {
		Linking.openURL(url);
	}
},

FindTeam: function(teams, teamId) {
	for (let i=0; i<teams.length; i++) {
		if (teams[i]._id==teamId) {
			return teams[i];
		}
	}

	return {};
},

FindTeamByUserId: function(teams, userId) {
	for (let i=0; i<teams.length; i++) {
		if (teams[i].ownerIds.indexOf(userId)>=0) {
			return teams[i];
		}
	}

	return null;
},

FindGame: function(games, gameId) {
	if (!games) {
		return null;
	}

	for (let i=0; i<games.length; i++) {
		if (games[i]._id==gameId) {
			return games[i];
		}
	}

	return null;
},

FindRound: function(rounds, teamId) {
	if (rounds==null) {
		return null;
	}

	for (let i=0; i<rounds.length; i++) {
		if (rounds[i].teamId==teamId) {
			return rounds[i];
		}
	}

	return null;
},

UserFind: function(userId) {
	for (let i=0; i<global.context.seasonUsers.length; i++) {
		if (global.context.seasonUsers[i]._id==userId) {
			return global.context.seasonUsers[i];
		}
	}

	return null;
},



RoundFindNext: function(game, number) {
	if (game.rounds==null) {
		return -1;
	}

	for (let i=number; i<game.rounds.length; i++) {
		if (game.rounds[i].beerId) {
			return game.rounds[i].number;
		}
	}
	return -1;
},

RoundFindPrev: function(game, number) {
	for (let i=number-2; i>=0; i--) {
		if (game.rounds[i].beerId) {
			return game.rounds[i].number;
		}
	}
	return -1;
},

InviteProcessFailure: function(resp) {
	console.log("InviteProcessFailure");
	if (!resp.error) {
		Alert.alert(lang.error, "Unknown error while processing invitation.", [{text: lang.ok}]);
		return;
	}

	let error = resp.error.toLowerCase();
	console.log("error is " + error);

	if (error.indexOf("already has a team")>=0) {
		Alert.alert(lang.error, "You already have a team in this league.", [{text: lang.ok}]);
		return;
	}

	Alert.alert(lang.error, "Unable to process invitation. It may no longer be valid.", [{text: lang.ok}]);
},

GetScaledSize: function(size) {
	if (!global.screen || !global.screen.scaleConversion || isNaN(size)) {
		return size;
	}

	return size * global.screen.scaleConversion;
},

UpdateObject: function(object, updates) {
	//console.log("UpdateObject " + JSON.stringify(updates));
	for (let key in updates) {
		if (key=="isPartial") {
			continue;
		}

		if (updates[key].constructor.name=="Array") {
			console.log(key + " is array, going deeper");
			Helpers.UpdateArray(object, key, updates[key]);
		} else {
			//console.log("setting ." + key + " from " + object[key] + " to " + updates[key]);
			object[key] = updates[key];
		}
	}
},

UpdateArray: function(obj, key, updates, isPartial) {
	//console.log("UpdateArray " + JSON.stringify(updates));
	let idField = "_id";
	if (updates.length==0 || (updates[0]._id==null && updates[0].id==null && updates[0].number==null)) {
		console.log("array is empty or has no ids, copying value");
		obj[key] = updates;
		return;
	}

	if (updates[0].id!=null) {
		idField = "id";
	} else if (updates[0].number!=null) {
		idField = "number";
	}

	let validIds = [];
	let usedIds = [];
	for (let i=0; i<updates.length; i++) {
		validIds.push(updates[i][idField]);
	}
	//console.log("valid ids are " + JSON.stringify(validIds));

	//deletes
	//TODO move deletes to deleted ids?
	if (!isPartial) {
		for (let i=obj[key].length-1; i>=0; i--) {
			if (validIds.indexOf(obj[key][i][idField])==-1) {
				console.log("!!! DELETE !!! DELETE !!! ");
				console.log("!!! DELETING !!! DELETING !!! " + obj[key][i][idField]);
				console.log("!!! DELETE !!! DELETE !!! ");
				obj[key].splice(i,1);
			}
		}
	}

	if (!obj[key]) {
		obj[key]=[];
	}

	//updates
	for (let i=0; i<obj[key].length; i++) {
		for (let j=0; j<updates.length; j++) {
			if (obj[key][i][idField]==updates[j][idField]) {
				usedIds.push(updates[j][idField]);
				Helpers.UpdateObject(obj[key][i],updates[j]);
				break;
			}
		}
	}

	//additions
	for (let i=0; i<updates.length; i++) {
		if (usedIds.indexOf(updates[i][idField])==-1) {
			obj[key].push(updates[i]);
		}
	}
},

DeleteFromArray: function(obj, key, ids) {
	for (let i=0; i<ids.length; i++) {
		for (let j=0; j<obj[key].length; j++) {
			if (obj[key][j]._id==ids[i]) {
				obj[key].splice(j,1);
				break;
			}
		}
	}
},

UntappdCheckLogin: function(setValue, callnext) {
	console.log("UntappdCheckLogin");
	let user = this.UserFind(global.context.userId);

	if (setValue!=null) {
		user.untappdAuth = setValue;
	}

	global.context.goodUntappd = user.untappdAuth;

	if (user.untappdAuth) {
		if (callnext) { callnext(); }
	} else {
		console.log("no auth... alerting");
		Alert.alert("Untappd Search", "Please link your Untappd account for searching beers and breweries.",
			[{ text: lang.ok, onPress: () => {
					Helpers.UntappdOpenAuth();
					if (callnext) { callnext(); }
				}
			}]
		);
	}
},

UntappdOpenAuth: async function() {
	const redirectUrl = "https://web.playfbl.com/inapp/untappd_login.html";
	const url = "https://untappd.com/oauth/authenticate/?client_id=9ADAFC40814D73397ACDE5B8B20DFB4F2788378F&response_type=code&redirect_url=" + redirectUrl;

	if (Platform.OS=="web") {
		await Helpers.SaveContext();
	}
	Helpers.OpenURL(url, true);
},

GetStateAbbr: function(state) {
	switch (state) {
		case "Alabama": return "AL";
		case "Alaska": return "AK";
		case "American Samoa": return "AS";
		case "Arizona": return "AZ";
		case "Arkansas": return "AR";
		case "California": return "CA";
		case "Colorado": return "CO";
		case "Connecticut": return "CT";
		case "Delaware": return "DE";
		case "District of Columbia": return "DC";
		case "Federated States of Micronesia": return "FM";
		case "Florida": return "FL";
		case "Georgia": return "GA";
		case "Guam": return "GU";
		case "Hawaii": return "HI";
		case "Idaho": return "ID";
		case "Illinois": return "IL";
		case "Indiana": return "IN";
		case "Iowa": return "IA";
		case "Kansas": return "KS";
		case "Kentucky": return "KY";
		case "Louisiana": return "LA";
		case "Maine": return "ME";
		case "Marshall Islands": return "MH";
		case "Maryland": return "MD";
		case "Massachusetts": return "MA";
		case "Michigan": return "MI";
		case "Minnesota": return "MN";
		case "Mississippi": return "MS";
		case "Missouri": return "MO";
		case "Montana": return "MT";
		case "Nebraska": return "NE";
		case "Nevada": return "NV";
		case "New Hampshire": return "NH";
		case "New Jersey": return "NJ";
		case "New Mexico": return "NM";
		case "New York": return "NY";
		case "North Carolina": return "NC";
		case "North Dakota": return "ND";
		case "Northern Mariana Islands": return "MP";
		case "Ohio": return "OH";
		case "Oklahoma": return "OK";
		case "Oregon": return "OR";
		case "Palau": return "PW";
		case "Pennsylvania": return "PA";
		case "Puerto Rico": return "PR";
		case "Rhode Island": return "RI";
		case "South Carolina": return "SC";
		case "South Dakota": return "SD";
		case "Tennessee": return "TN";
		case "Texas": return "TX";
		case "Utah": return "UT";
		case "Vermont": return "VT";
		case "Virgin Islands": return "VI";
		case "Virginia": return "VA";
		case "Washington": return "WA";
		case "West Virginia": return "WV";
		case "Wisconsin": return "WI";
		case "Wyoming": return "WY";
		case "Newfoundland and Labrador": return "NL";
		case "Prince Edward Island": return "PE";
		case "Nova Scotia": return "NS";
		case "New Brunswick": return "NB";
		case "Quebec": return "QC";
		case "Ontario": return "ON";
		case "Manitoba": return "MB";
		case "Saskatchewan": return "SK";
		case "Alberta": return "AB";
		case "British Columbia": return "BC";
		case "Yukon": return "YT";
		case "Northwest Territories": return "NT";
		case "Nunavut": return "NU";
	}
	return state;
},

SetIsLoading: async function(SetIsLoading, timeout) {
	if (!timeout) {
		timeout = 15000;
	}
	await SetIsLoading(true);
	global.context.SetIsLoading = SetIsLoading;
	setTimeout(function() { Helpers.CancelIsLoading(SetIsLoading); }, timeout);
},

CancelIsLoading: function(lastValue) {
	if (global.context && global.context.SetIsLoading && global.context.SetIsLoading==lastValue) {
		global.context.SetIsLoading(false);
		global.context.SetIsLoading = null;
	}
},

SaveContext: async function() {
	console.log("SaveContext");

	if (global.auth) {
		await AsyncStorage.setItem("auth", global.auth);
	}

	await AsyncStorage.setItem("context", JSON.stringify(global.context, function(key, val) {
		if (key=="parent" || key=="children") { return; }
		return val;
	}));
},

GoToLogin: async function() {
	global.auth = null;
	global.context = {};
	global.temp = {};

	try {
		if (global.seasonWS.ws && global.seasonWS.ws.readyState==1) {
			global.seasonWS.isDisconnecting = true;
			console.log("connection is open, closing");
			try {
				global.seasonWS.ws.close();
			} catch (ex) {
			}
		}

		await AsyncStorage.setItem("auth", "");
		await AsyncStorage.setItem("context", "");
		await AsyncStorage.clear();
	} catch (ex) {
		console.log("logout exception");
		console.log(ex);
	}
	Helpers.Navigate("SignIn");
	return false;
},

LogProperties: function(obj) {
	for (let key in obj) {
		let type1 = typeof obj[key];
		if (type1=="function") continue;
		console.log(key + " " + (type1=="object" ? "" : obj[key]));
		for (let key2 in obj[key]) {
			let type2 = typeof obj[key][key2];
			if (type2=="function") continue;
			console.log("\t" + key2 + " " + (type2=="object" ? "" : obj[key][key2]));
		}
	}
},

LogObject: function(obj) {
	//return;
	let seen = [];
	console.log(JSON.stringify(obj, function(key, val) {
		if (key=="parent" || key=="children") {
			return;
		}

		if (val!=null && typeof val=="object") {
			if (seen.indexOf(val)>=0) { return; }
			seen.push(val);
		}
		return val;
	}));
},


}; // const Helpers

export default Helpers;
