import { AppLoading } from "expo";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import React, { useState, Component } from "react";
import { Platform, StatusBar, Dimensions, View, Modal, Text } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
//import SafeAreaView from "react-native-safe-area-view";
//import * as SplashScreen from "expo-splash-screen";


import Helpers from "./classes/Helpers";
import AppNavigator from "./navigation/AppNavigator";

const config = require("./config.json");
import { styles } from "./styles/main";

const { width, height, fontScale } = Dimensions.get("window");

let _loadingComplete = false;
let _SetLoadingComplete = null;


export default function App(props) {
	[_loadingComplete, _SetLoadingComplete] = useState(false);


	console.log("RESETTING - App.js");
	console.log(config.version);
	console.log("screen size: " + width + " x " + height);

	global.seasonWS = {};
	global.seasonWS.ws = null;
	global.seasonWS.isConnected = false;
	global.seasonWS.hasAuth = false;

	global.screen = { "width": width, "height": (height-10), "scaleConversion": (width/480/fontScale) };
	let viewStyle = {flex:1};

	if (width>height) {
		//viewStyle = { flex:1, paddingLeft:"28%", paddingRight:"28%", backgroundColor:"#000000" };
		global.screen.scaleConversion = (width/2.2727273/480/fontScale);
	}
	console.log(global.screen.scaleConversion);
	global.dimensions = Dimensions.get("window");

	SetLanguage();
	SetGlobalStyles(styles);
	global.styles.rootView = viewStyle;

	if (!_loadingComplete && !props.skipLoadingScreen) {
		return (<AppLoading
			startAsync={LoadResourcesAsync}
			onError={(error) => { console.log(error); }}
			onFinish={() => { _SetLoadingComplete(true); }}
		/>);
	}

	return (<SafeAreaProvider style={viewStyle}>
		{Platform.OS=="ios" && <StatusBar barStyle="default" />}
		<AppNavigator />
	</SafeAreaProvider>);
}

function SetLanguage() {
	global.lang = require("./constants/lang-en.json");

	let replacements = null;
	global.showScores = true;

	/*if (Platform.OS=="ios") { //TODO ios debug
		replacements = require("./constants/lang-en_ios.json");
		global.showScores = false;
	}*/

	if (replacements) {
		for (let key in replacements) {
			global.lang[key] = replacements[key];
		}
	}
}

function SetGlobalStyles() {
	if (!global.styles) {
		ScaleSizes(styles);
		global.styles = styles;
	}
}

function ScaleSizes(node) {
	for (let key in node) {
		if (key=="fontSize" || key=="width" || key=="height" || key=="maxWidth" || key=="maxHeight"
			|| (key.length>=7 && key.substring(0,7)=="padding")
			|| (key.length>=6 && key.substring(0,6)=="margin")) {
			//console.log("changing " + key + " " + node[key] + " to " + Helpers.GetScaledSize(node[key]));
			node[key] = Helpers.GetScaledSize(node[key]);
		} else {
			if (node[key].constructor.name=="Object") {
				ScaleSizes(node[key]);
			}
			//console.log("entering " + key);
			//ScaleSizes(node[key]);
		}
	}
}

async function LoadResourcesAsync() {
	const images = [
		require("./assets/images/bg_dark.jpg"),
		require("./assets/images/header_bg.jpg"),
		require("./assets/images/untappd.png"),
		require("./assets/icons/add.png"),
		require("./assets/icons/admin_add.png"),
		require("./assets/icons/admin_remove.png"),
		require("./assets/icons/beer.png"),
		require("./assets/icons/box_minus.png"),
		require("./assets/icons/box_plus.png"),
		require("./assets/icons/calendar.png"),
		require("./assets/icons/category.png"),
		require("./assets/icons/delete.png"),
		require("./assets/icons/description.png"),
		require("./assets/icons/edit.png"),
		require("./assets/icons/email_send.png"),
		require("./assets/icons/location.png"),
		require("./assets/icons/lock.png"),
		require("./assets/icons/next.png"),
		require("./assets/icons/no_image.png"),
		require("./assets/icons/previous.png"),
		require("./assets/icons/refresh.png"),
		require("./assets/icons/tag.png"),
		require("./assets/icons/unlock.png"),
		require("./assets/icons/warning.png"),
		require("./assets/icons/menu.png")
	];

	const cacheImages = images.map((image) => {
		return Asset.fromModule(image).downloadAsync();
	});

	return Promise.all([
		cacheImages,
		Font.loadAsync({
			"LibreFranklin": require("./assets/fonts/LibreFranklin-Regular.ttf"),
			"LibreFranklin-Bold": require("./assets/fonts/LibreFranklin-Bold.ttf"),
			"LibreFranklin-Italic": require("./assets/fonts/LibreFranklin-Italic.ttf"),
			"LibreFranklin-BoldItalic": require("./assets/fonts/LibreFranklin-BoldItalic.ttf")
		})
	]);
}
