import React, { useState } from "react";
import { Platform, ScrollView, TouchableOpacity, View } from "react-native";
import SafeAreaView from "react-native-safe-area-view";

const ModalView = Platform.OS=="ios" ? SafeAreaView : View;


let _isWeb = false;
let Modal = null;

if (Platform.OS=="web") {
	_isWeb = true;
	Modal = require("../components/web/WebModal").default;
} else {
	Modal = require("react-native").Modal;
}


//let _visible = null;
//let _SetVisible = null;

export function FullScreenModal(props) {
	//[ _visible, _SetVisible ] = useState(false);

	console.log("FullScreenModal");
	React.useEffect(() => {
		console.log("FullScreenModal - use effect");
		//Init(props);
	}, [props.display]); //props.visible

	let isVisible = (props.display!=null);
	if (!isVisible) {
		return null
	}
	return <Modal animationType="fade" transparent={false} visible={isVisible}>
		<ScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">
			<ModalView style={{alignItems:"center"}}>
				{ props.display }
			</ModalView>
		</ScrollView>
	</Modal>;
}
