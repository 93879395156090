import React, { useState } from "react";
import { Platform, ScrollView, TouchableOpacity, View } from "react-native";
import { Image, Text, Animated } from "react-native";
import SafeAreaView from "react-native-safe-area-view";
import Confetti from "react-native-confetti";


let Modal = null;
if (Platform.OS!="web") {
	Modal = require("react-native").Modal;
} else {
	Modal = require("./web/WebModal").default;
}


import Helpers from "../classes/Helpers";
import DebounceComponent from "../classes/DebounceComponent";

const TouchableOpacityDB = DebounceComponent(TouchableOpacity);
const ModalView = Platform.OS=="ios" ? SafeAreaView : View;

let styles = null;
const lang = require("../constants/lang-en.json");


let _CloseMethod = null;
let _season = null;
let _game = null;
let _timeout = null;

let _rounds = null;

let _minRound = 0;
let _addDelay = 7500;
let _initDelay = 2000;

let _scoreOpacity = null;
let _SetScoreOpacity = null;

let _titleOpacity = null;
let _SetTitleOpacity = null;

let _visible = null;
let _SetVisible = null;

let _cannon1a = null;
let _cannon1b = null;
let _cannon1c = null;
let _cannon2 = null;
let _cannon3 = null;


export function ScoreAnimation(props) {
	styles = global.styles;
	_CloseMethod = props.CloseMethod;

	_season = global.context.season;
	[ _scoreOpacity, _SetScoreOpacity ] = useState(new Animated.Value(0));
	[ _titleOpacity, _SetTitleOpacity ] = useState(new Animated.Value(0));
	[ _visible, _SetVisible ] = useState(false);

	let gameId = props.game ? props.game._id : null;

	React.useEffect(() => {
		console.log("ScoreAnimation - use effect");
		Init(props);
	}, [props.visible, gameId]);

	return <Modal animationType="fade" transparent={false} visible={_visible}>
		<Confetti confettiCount={31} size={3} duration={2600} timeout={3} ref={ref => (_cannon1a = ref)} />
		<Confetti confettiCount={22} size={2} duration={4000} timeout={3} ref={ref => (_cannon1b = ref)} />
		<Confetti confettiCount={37} size={1} duration={2200} timeout={3} ref={ref => (_cannon1c = ref)} />
		<Confetti confettiCount={25} size={2} duration={3200} timeout={1} ref={ref => (_cannon2 = ref)} />
		<Confetti confettiCount={6} duration={4000} timeout={50} ref={ref => (_cannon3 = ref)} />

		<ScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">
			<ModalView style={{alignItems:"center"}}>
				<Animated.View style={[styles.box,{opacity:_titleOpacity}]}>
					<View style={{ marginTop: Helpers.GetScaledSize(13.33) }} />
					<Text style={[styles.textBold,{fontSize:Helpers.GetScaledSize(42),paddingBottom:Helpers.GetScaledSize(13.33)}]}>Tasting Results</Text>
					<TouchableOpacityDB style={styles.button} onPress={Close}>
						<Text style={styles.buttonText}>{lang.close}</Text>
					</TouchableOpacityDB>
				</Animated.View>

				<View style={{ marginTop: Helpers.GetScaledSize(24) }} />

				{ (_rounds!=null) && _rounds.map(RoundItem) }
			</ModalView>
		</ScrollView>
	</Modal>;
}

async function Init(props) {
	if (!props.game || !props.game.rounds || props.game.rounds.length==0 || !props.game.isClosed) {
		return;
	}

	_game = props.game;
	_minRound = _game.rounds.length-1;
	for (let i=0; i<_game.rounds.length; i++) {
		_game.rounds[i].isTie = IsTie(_game.rounds, i);
	}

	StopAnimation();
	_rounds = [];
	await _SetVisible(props.visible);

	if (props.visible) {
		await _SetTitleOpacity(new Animated.Value(0));
		Animated.timing(_titleOpacity, { toValue: 1, duration: _initDelay, useNativeDriver: true }).start();
		_timeout = setTimeout(AddRound, _initDelay-1000);
	}
}

async function Close() {
	await _SetVisible(false);
	StopAnimation();
	_CloseMethod();
}

function StopAnimation() {
	if (_cannon1a && _cannon1b && _cannon1c && _cannon2 && _cannon3) {
		_cannon1a.stopConfetti();
		_cannon1b.stopConfetti();
		_cannon1c.stopConfetti();
		_cannon2.stopConfetti();
		_cannon3.stopConfetti();
	}

	if (_timeout) {
		clearTimeout(_timeout);
		_timeout = null;
	}
}

async function AddRound() {
	if (!_visible) {
		return;
	}

	let rounds = [];
	for (let i=_minRound; i<_game.rounds.length; i++) {
		_game.rounds[i].isFirst=(i==_minRound);
		_game.rounds[i].isLast=(i==_game.rounds.length-1);
		rounds.push(_game.rounds[i]);
	}

	_rounds = rounds;
	await _SetScoreOpacity(new Animated.Value(0));
	Animated.timing(_scoreOpacity, { toValue: 1, duration: _addDelay-3000, useNativeDriver: true }).start();

	if (_minRound==0) {
		_cannon1a.startConfetti();
		_cannon1b.startConfetti();
		_cannon1c.startConfetti();
	}
	if (_minRound==1) {
		_cannon2.startConfetti();
	}
	if (_minRound==2) {
		_cannon3.startConfetti();
	}


	_minRound--;
	if (_minRound>=0) {
		_timeout = setTimeout(AddRound, _addDelay);
	}
}

function IsTie(rounds, i) {
	if (i>0 && rounds[i].voteAvg==rounds[i-1].voteAvg) {
		return true;
	}

	if (i<rounds.length-1 && rounds[i].voteAvg==rounds[i+1].voteAvg) {
		return true;
	}

	return false;
}

function RoundItem(round) {
	let ViewType = View;

	if (round.isFirst) {
		ViewType = Animated.View;
		round.style = { opacity: _scoreOpacity };
	} else {
		round.style = null;
	}

	return <ViewType style={[{width:global.screen.width-Helpers.GetScaledSize(120), alignItems:"center"}, round.style]} key={(round.teamId)}>
		<Text style={[styles.title1b,{paddingTop:Helpers.GetScaledSize(4), paddingBottom:Helpers.GetScaledSize(4), textAlign:"center"}]}>{!round.isDQ && round.voteAvg>0 && (GetOrdinal(round.voteRank) + " Place")}{round.isTie && " (tie)"}</Text>
		<View style={{flexDirection:"row", paddingBottom:Helpers.GetScaledSize(33.33)}}>
			<View style={{flexDirection:"column", width: global.screen.width-Helpers.GetScaledSize(120)}}>
				<Text style={[styles.textBold,{fontSize: Helpers.GetScaledSize(15.17)}]}>{Helpers.FindTeam(_season.teams, round.teamId).name}</Text>
				<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(15.17)}]}>Beer #{round.number} - {round.beerName}</Text>
				<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(15.17)}]}>{round.breweryName}</Text>
				<Text style={[styles.text,{fontSize: Helpers.GetScaledSize(15.17)}]}>{round.description}</Text>
			</View>
			<Text style={[styles.title1, {width:Helpers.GetScaledSize(57.33)}, round.scoreStyle]}>{round.voteAvg!=null && round.voteAvg.toFixed(1)}</Text>
		</View>
		{ (round.isLast) && <View style={{paddingBottom:Helpers.GetScaledSize(13.33)}} /> }
		{ (round.isLast) && (_minRound==0) && <TouchableOpacityDB style={styles.button} onPress={Close}>
			<Text style={styles.buttonText}>{lang.close}</Text>
		</TouchableOpacityDB> }
	</ViewType>;
}

function GetOrdinal(num) {
	let lastDigit = num % 10;
	if (lastDigit==1 && num!=11) { return num + "st"; }
	if (lastDigit==2 && num!=12) { return num + "nd"; }
	if (lastDigit==3 && num!=13) { return num + "rd"; }
	return num + "th";
}
