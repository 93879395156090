import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";

import AuthLoadingScreen from "../screens/AuthLoadingScreen";
import SignInScreen from "../screens/SignInScreen";
import SignUpScreen from "../screens/SignUpScreen";
import PasswordResetScreen from "../screens/PasswordResetScreen";

import LeagueCreateScreen from "../screens/LeagueCreateScreen";

import MainDrawerNavigator from "./MainDrawerNavigator";

import ActiveGameScreen from "../screens/ActiveGameScreen";


export default function AppNavigator() {
	const AuthStack = createStackNavigator();

	return (<NavigationContainer>
		<AuthStack.Navigator initialRouteName="AuthLoading" headerMode="none">
			<AuthStack.Screen name="AuthLoading" component={AuthLoadingScreen} />
			<AuthStack.Screen name="SignIn" component={SignInScreen} />
			<AuthStack.Screen name="SignUp" component={SignUpScreen} />
			<AuthStack.Screen name="PasswordReset" component={PasswordResetScreen} />
			<AuthStack.Screen name="LeagueCreate" component={LeagueCreateScreen} />
			<AuthStack.Screen name="ActiveGame" component={ActiveGameScreen} />

			<AuthStack.Screen name="LeagueNav">
				{(props) => MainDrawerNavigator(props)}
			</AuthStack.Screen>
		</AuthStack.Navigator>
	</NavigationContainer>);
}
