import React, { useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { Text, TextInput, Image } from "react-native";
import SafeAreaView from "react-native-safe-area-view";
import * as Location from "expo-location";


let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}

let styles = null;

import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";
const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";

const lang = require("../constants/lang-en.json");


let _props = null;

let _name = null;
let _SetName = null;

let _location = null;
let _SetLocation = null;



export default function LeagueCreateScreen(props) {
	styles = global.styles;
	_props = props;
	[ _name, _SetName ] = useState("");
	[ _location, _SetLocation ] = useState("");


	return (<SafeAreaView style={styles.contentContainer} forceInset={{top: "always"}}>
		<View style={styles.mainBox}>

			{ UIHelpers.Header(lang.createALeague) }
			<View style={styles.box}>
				<TextInput placeholder={lang.leagueName} {...PlaceholderProps} autoCapitalize="none"
					style={[styles.inputBox,{marginBottom:Helpers.GetScaledSize(13.33),marginRight:Helpers.GetScaledSize(33.33)}]}
					value={_name} onChangeText={(value) => {_SetName(value);}} />
				<View style={{flexDirection:"row"}}>
					<TextInput placeholder={lang.city} {...PlaceholderProps} autoCapitalize="none"
						style={[styles.inputBox,{marginBottom:Helpers.GetScaledSize(13.33)}]}
						value={_location} onChangeText={(value) => {_SetLocation(value);}} />
					<TouchableOpacityDB onPress={GetLocation} style={{paddingLeft:Helpers.GetScaledSize(6.67)}}>
						<Image source={require("../assets/icons/location.png")} style={{width: Helpers.GetScaledSize(26.67), height: Helpers.GetScaledSize(26.67)}} />
					</TouchableOpacityDB>
				</View>
				<View style={{paddingTop: Helpers.GetScaledSize(10.67)}} />

				<View style={{flexDirection:"row"}}>
					<TouchableOpacityDB style={styles.button} onPress={()=>{Helpers.Navigate("LeagueNav", { screen: "Leagues" });}}>
						<Text style={styles.buttonText}>{lang.cancel}</Text>
					</TouchableOpacityDB>

					<View style={{paddingRight:Helpers.GetScaledSize(20)}} />

					<TouchableOpacityDB style={styles.button} onPress={LeagueCreate}>
						<Text style={styles.buttonText}>{lang.createLeague}</Text>
					</TouchableOpacityDB>
				</View>
			</View>
		</View>
	</SafeAreaView>);
}

async function GetLocation() {
	console.log("requesting location...");

	let { status } = await Location.requestPermissionsAsync();
	console.log(status);
	if (status!="granted") {
		console.log("did NOT get it... " + status);
		return;
	}

	let location = null;
	try {
		if (Platform.OS=="web") {
			Location.setApiKey("AIzaSyCULudFkA9_3xakNX4MpABi2Z54xlJw9pg");
		}

		location = await Location.getLastKnownPositionAsync();
		console.log("location1 getLastKnownPositionAsync");
	} catch(ex) {
		console.log("error getting getLastKnownPositionAsync");
	}

	if (!location) {
		try {
			location = await Location.getCurrentPositionAsync({});
			console.log("location2 getCurrentPositionAsync");
		} catch(ex) {
			console.log("error getting getCurrentPositionAsync");
		}
	}

	console.log(location);
	if (!location) {
		return;
	}

	let locInfo = await Location.reverseGeocodeAsync(location.coords);
	if (locInfo.length>0) {
		locInfo=locInfo[0];
	}
	console.log(locInfo);

	let locText = "";

	if (locInfo.isoCountryCode=="US") {
		locText = locInfo.city + ", " + Helpers.GetStateAbbr(locInfo.region) + " " + locInfo.postalCode;
	} else if (locInfo.isoCountryCode=="CA") {
		locText = locInfo.city + ", " + Helpers.GetStateAbbr(locInfo.region) + " " + locInfo.postalCode + ", Canada";
	} else if (locInfo.isoCountryCode=="GB") {
		if (locInfo.city) {
			locText = locInfo.city + ", ";
		}
		locText += locInfo.region;
		if (locInfo.postalCode) {
			locText += " " + locInfo.postalCode;
		}
	} else {
		if (locInfo.city) {
			locText = locInfo.city + ", ";
		} else if (locInfo.region) {
			locText = locInfo.region + ", ";
		}
		locText += locInfo.country;
		if (locInfo.postalCode) {
			locText += " " + locInfo.postalCode;
		}

	}

	_SetLocation(locText);
}


async function LeagueCreate() {
	let league = {};

	let leagueName = _name.trim();
	if (leagueName.length<3) {
		Alert.alert(lang.needName, lang.enterLeagueName, [{text: lang.ok}]);
		return false;
	}

	let location = _location.trim();
	if (location.length<3) {
		Alert.alert(lang.needCity, lang.enterCity, [{text: lang.ok}]);
		return;
	}

	league.name=leagueName;
	if (location.length>0) { league.location=location; }

	if (!await API.Rest.LeagueCreate(league)) {
		Alert.alert(lang.error, "Error creating league.", [{text: lang.ok}]);
	}
}

LeagueCreateScreen.navigationOptions = {
	header: null,
};
