import React, { useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { Text, TextInput } from "react-native";

let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}


import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";
const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";

let styles = null;
const config = require("../config.json");
const lang = require("../constants/lang-en.json");


let _props = null;

let _league = null;

let _name = null;
let _SetName = null;

let _location = null;
let _SetLocation = null;


export default function LeagueSettingsScreen(props) {
	styles = global.styles;
	_props = props;
	[ _name, _SetName ] = useState("");
	[ _location, _SetLocation ] = useState("");

	console.log("LeagueSettingsScreen - render");

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		return unsubscribe;
	}, [props.navigation]);

	return (<View style={styles.contentContainer}>
		<View style={styles.mainBox}>

			{UIHelpers.Header(lang.leagueSettings)}
			<View style={styles.box}>
				<TextInput placeholder={lang.leagueName} {...PlaceholderProps} autoCapitalize="none"
				style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_name} onChangeText={(value) => { _SetName(value); }} />
				<TextInput placeholder={lang.location} {...PlaceholderProps} autoCapitalize="none"
					style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_location} onChangeText={(value) => { _SetLocation(value); }} />

				<View style={{flexDirection: "row"}}>
					<TouchableOpacityDB style={styles.button} onPress={()=>{Helpers.Navigate("LeagueNav", { screen: "LeagueOverview"});}}>
						<Text style={styles.buttonText}>Cancel</Text>
					</TouchableOpacityDB>

					<View style={{paddingLeft:Helpers.GetScaledSize(26.67)}} />
					<TouchableOpacityDB style={styles.button} onPress={SaveLeague}>
						<Text style={styles.buttonText}>Save</Text>
					</TouchableOpacityDB>
				</View>
			</View>

			{UIHelpers.Header(lang.administration, null, 20)}
			<View style={styles.box}>
				<TouchableOpacityDB style={styles.button} onPress={LeagueDeleteConfirm}>
					<Text style={styles.buttonText}>{lang.deleteLeague}</Text>
				</TouchableOpacityDB>
			</View>
		</View>
	</View>);
}

function OnFocus() {
	_league = global.context.league;
	LoadSettings();
}

async function LeagueDeleteConfirm() {
	Alert.alert(lang.deleteLeague, "Are you sure you want to delete this league (" + _league.name + ")?",
		[{text: lang.cancel, style: "cancel"}, {text: lang.delete, onPress: () => LeagueDelete()}]
	);
}

async function LeagueDelete() {
	if (await API.Rest.LeagueDelete(_league._id)) {
		Helpers.Navigate("LeagueNav", { screen: "Leagues" });
	} else {
		Alert.alert(lang.error, "Unable to automatically delete league. Contact support if you need this league deleted.", [{text: lang.ok}]);
	}
}

async function LoadSettings() {
	console.log("LoadSettings " + _league.name);

	_SetName(_league.name);
	_SetLocation(_league.location);
}

async function SaveLeague() {
	let leagueName = _name.trim();
	if (leagueName.length<1) {
		Alert.alert(lang.needName, lang.enterLeagueName, [{text: lang.ok}]);
		return;
	}

	let leagueLocation = _location.trim();
	if (leagueLocation.length<1) {
		Alert.alert(lang.needLocation, lang.enterLocation, [{text: lang.ok}]);
		return;
	}

	API.SeasonWS.LeagueUpdate({ "name": leagueName, "location": leagueLocation }, LeagueUpdated);
}

async function LeagueUpdated() {
	_league.name=_name.trim();
	if (_location) {
		_league.location=_location.trim();
	}
	_props.navigation.goBack();
}

LeagueSettingsScreen.navigationOptions = {
	header: null,
};
