//import { StyleSheet } from "react-native";

//fbl black 231f20
//fbl oranges c17515, f6a518, fbc36e, fee2ba

const font="LibreFranklin";
const fontBold="LibreFranklin-Bold";
const fontItalic="LibreFranklin-Italic";
const fontBoldItalic="LibreFranklin-BoldItalic";

/*const font="sans-serif-thin";
const fontBold="sans-serif-thin";
const fontItalic="sans-serif-thin";*/

export const styles = {
	title1: {
		fontFamily: font,
		color: "#000000",
		fontSize: 21
	},
	title1b: {
		fontFamily: fontBold,
		color: "#000000",
		fontSize: 21
	},
	title2: {
		fontFamily: font,
		color: "#000000",
		fontSize: 18.67
	},
	title2b: {
		fontFamily: fontBold,
		color: "#000000",
		fontSize: 18.67
	},
	title3: {
		fontFamily: font,
		color: "#000000",
		fontSize: 17
	},
	title3b: {
		fontFamily: fontBold,
		color: "#000000",
		fontSize: 17
	},
	textSmall: {
		fontSize: 14,
		fontFamily: font,
		color: "#000000",
	},
	text: {
		fontSize: 16,
		fontFamily: font,
		color: "#000000",
	},
	textBold: {
		fontSize: 16,
		fontFamily: fontBold,
		color: "#000000",
	},
	textBoldItalic: {
		fontSize: 16,
		fontFamily: fontBoldItalic,
		color: "#000000",
	},
	textLight: {
		fontFamily: font,
		color: "#fee2ba",
	},
	textLightItalic: {
		fontFamily: fontItalic,
		color: "#fee2ba",
	},
	activityIndicator: {
		flex: 1,
		backgroundColor: "#ffffff",
		justifyContent: "center",
	},
	bgImage: {
		flex: 1
	},
	contentContainer: {
		flex: 1,
		alignItems:"stretch", justifyContent:"flex-start",
		backgroundColor:"#e8e8e8", //#fbc36e
		paddingTop: 5, paddingBottom: 5,
		paddingHorizontal:0,
	},
	contentContainerClear: {
		flex: 1,
		alignItems:"stretch", justifyContent:"flex-start",
		paddingTop: 5, paddingBottom: 5,
		paddingHorizontal:0,
	},
	mainBox: {
		//flex:1,
		alignItems: "center",
		//paddingTop: 30
		//marginHorizontal: 50,
	},
	box: {
		width:"100%",
		backgroundColor:"white",
		alignItems:"center",
		paddingVertical:8
	},
	userChangeText: {
		fontFamily: font,
		fontSize: 12.83,
		color: "rgba(250,90,90,1)",
		lineHeight: 24,
		textAlign: "center",
	},
	bottomContainer: {
		alignItems: "center",
		//backgroundColor: "#fbfbfb",
		paddingVertical: 6,
	},
	inputBox: {
		fontFamily: font,
		fontSize: 16, //fix
		height: 40,
		width: 306,
		backgroundColor:"#ffffff",
		borderColor:"gray",
		borderWidth:1,
		paddingHorizontal:10.67,
		color:"#000000"
	},
	inputDropdown: {
		height:40,
		maxWidth:306,
		backgroundColor:"#ffffff",
		borderColor:"gray",
		borderWidth:1,
	},
	button: {
		backgroundColor: "#f6a518", // c17515, f6a518, fbc36e, fee2ba //g f8c268
		paddingVertical: 4,
		paddingHorizontal: 15,
		shadowColor: "#808080",
		shadowOffset: {width: 1, height: 1},
		shadowRadius: 1,
		shadowOpacity: 1,
		elevation: 2,
		justifyContent: "center",
		alignItems: "center"
	},
	buttonText: {
		fontFamily: fontBoldItalic,
		fontSize: 16.33,
		textTransform: "uppercase",
		color: "#664000", //664000 6d4700
		justifyContent: "center",
		textAlign: "center",
		alignItems: "center",
		//textShadowColor: "#808080",
		//textShadowOffset: {width: 1, height: 1},
		//textShadowRadius: 3,
	},
	appleButton: {
		width: 223,
		height: 40
	},
	facebookButton: {
		backgroundColor: "#4267b2",
		paddingVertical: 9.22,
		alignItems:"center",
		width: 223
	},
	localButton: {
		backgroundColor: "#f6a518",
		paddingVertical: 9.22,
		alignItems:"center",
		width: 223
	},
	loginText: {
		fontFamily: fontBold,
		fontSize: 17.5,
		color: "#ffffff",
	},
	untappdButton: {
		backgroundColor: "#ffcc00",
		paddingVertical: 9.22,
		alignItems:"center",
		width: 223
	},
	appleButtonWeb: {
		backgroundColor: "#000000",
		paddingVertical: 9.22,
		alignItems:"center",
		width: 223
	},
	textHeading: {
		fontFamily: font,
		color: "#000000",
		paddingTop: 2,
		textDecorationLine: "underline"
	},
	textLink: {
		fontFamily: font,
		fontSize: 18.67,
		color: "#4040ff",
		textDecorationLine: "underline",
	},
	rowNormal: {
		backgroundColor:"#fafafa"
	},
	rowAlt: {
		backgroundColor:"#e4e4e4"
	},
	rowBad: {
		backgroundColor:"#ffdada"
	},
	rowHighlight: {
		backgroundColor:"#ffffff" //dafada
	},
	voteGood: {
		backgroundColor:"#88ff88", marginVertical:2, width:15, height:15, borderWidth:1, borderColor:"#505050"
	},
	votePartial: {
		backgroundColor:"#ffff20", marginVertical:2, width:15, height:15, borderWidth:1, borderColor:"#505050"
	},
	voteBad: {
		backgroundColor:"#ff8888", marginVertical:2, width:15, height:15, borderWidth:1, borderColor:"#505050"
	},
	voteGoodText: {
		fontFamily: font,
		color:"#005f00"
	},
	votePartialText: {
		fontFamily: font,
		color:"#808030"
	},
	voteBadText: {
		fontFamily: font,
		color:"#5f0000"
	},
	textWarning: {
		fontFamily: font,
		color:"#ff3030"
	},
	gameMain: {
		borderColor: "#000000",
		borderWidth: 1,
	},
	gameRow: {
		flexDirection:"row",
		alignItems:"center",
		paddingBottom: 4,
	},
	listItem: {
		flexDirection:"column",
		width:"100%",
		backgroundColor:"#dddddd",
		paddingVertical:3,
		borderColor: "#ffffff",
		borderRadius: 1,
		borderWidth: 1,
		alignItems:"center",
	},
	loading: {
		flex: 1,
		position: "absolute",
		left: 0, top: 0, bottom:0, right: 0,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#ffffff88"
	},
	sliderTrack: {
		height: 5,
		borderRadius: 3,
	},
	sliderThumb: {
		width: 30,
		height: 30,
		borderRadius: 15,
		backgroundColor: "#f6a518",
		borderColor: "#000000",
		borderWidth: 2,
	},
	webScrollViewFix: {
		height: 250
	}
};


export const ActivityIndicatorProps = {
	size: "large",
	animating: true,
	color: "#50ff50"
};

export const PlaceholderProps = {
	placeholderTextColor: "#909090"
};
