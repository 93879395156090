import React, { useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { Text, TextInput } from "react-native";
import SafeAreaView from "react-native-safe-area-view";

let Alert = null;
if (Platform.OS=="web") {
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}

import Helpers from "../classes/Helpers";
import UIHelpers from "../classes/UIHelpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";
const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";


let styles = null;
const lang = require("../constants/lang-en.json");


let _props = null;

let _oldpassword = "";
let _password1 = null;
let _password2 = null;
let _SetPassword1 = null;
let _SetPassword2 = null;
let _passwordWarning = null;
let _SetPasswordWarning = null;


export default function PasswordResetScreen(props) {
	styles = global.styles;
	_props = props;
	[ _password1, _SetPassword1 ] = useState("");
	[ _password2, _SetPassword2 ] = useState("");
	[ _passwordWarning, _SetPasswordWarning ] = useState(false);

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		return unsubscribe;
	}, [props.navigation]);

	return (<SafeAreaView style={styles.contentContainerClear} forceInset={{top: "always"}} keyboardShouldPersistTaps="always">
	<View style={styles.contentContainer}>
		<View style={styles.mainBox} keyboardShouldPersistTaps="always">
			{UIHelpers.Header(lang.passwordReset)}
			<View style={styles.box}>
				<TextInput placeholder={lang.password} {...PlaceholderProps} autoCapitalize="none" textContentType="password" secureTextEntry={true}
					style={[styles.inputBox,{marginBottom:Helpers.GetScaledSize(13.33)}]}
					value={_password1} onChangeText={(value) => { SetPassword(1, value); }} />

				<TextInput placeholder={lang.verifyPassword} {...PlaceholderProps} autoCapitalize="none" textContentType="password" secureTextEntry={true}
					style={[styles.inputBox]}
					value={_password2} onChangeText={(value) => { SetPassword(2, value); }} />
				{_passwordWarning && <Text style={styles.textWarning}>{lang.passwordMismatch}</Text>}

				<View style={{paddingTop: Helpers.GetScaledSize(26.67)}}></View>
				<View style={{flexDirection: "row"}}>
					<TouchableOpacityDB style={styles.button} onPress={SavePassword}>
						<Text style={styles.buttonText}>{lang.setPassword}</Text>
					</TouchableOpacityDB>
				</View>
			</View>
		</View>
	</View>
	</SafeAreaView>);
}

async function OnFocus() {
	if (!global.context.passwordResetUserId || !global.context.passwordResetKey) {
		Helpers.Navigate("SignIn");
	}
}

async function SetPassword(num, value) {
	if (num==1) {
		await _SetPassword1(value);
	} else {
		await _SetPassword2(value);
	}

	_oldpassword=_password1+_password2;
	setTimeout(async () => {
		if ((_password1+_password2)==_oldpassword && _password2.length>0) {
			_SetPasswordWarning(_password1!=_password2);
		}
	}, 1000);
}

async function SavePassword() {
	console.log("SavePassword");
	if (_password1!=_password2 || _password1.length<4) {
		Alert.alert(lang.needPassword, "Please enter a password.", [{text: lang.ok}]);
		return;
	}

	if (await API.Rest.UserSavePassword(global.context.passwordResetUserId, _password1, global.context.passwordResetKey)) {
		Alert.alert(lang.success, "Password updated.", [{text: lang.ok}]);
	} else {
		Alert.alert(lang.error, "Unable to update password.", [{text: lang.ok}]);
	}

	global.context.passwordResetUserId=null;
	global.context.passwordResetKey=null;

	Helpers.Navigate("SignIn");
}

PasswordResetScreen.navigationOptions = {
	header: null,
};
