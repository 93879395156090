import React, { Component } from "react";
import ReactDOM from "react-dom";

// create and get reference to Modal DOM node
const appRoot = document.getElementById("root");
appRoot.insertAdjacentHTML("afterend", "<div id=\"modal-root\"></div>");
const modalRoot = document.getElementById("modal-root");

class InnerModal extends Component {
	constructor(props) {
		super(props);
		this.el = document.createElement("div");
	}

	componentDidMount() {
		modalRoot.appendChild(this.el); //https://reactjs.org/docs/portals.html
	}

	componentWillUnmount() {
		modalRoot.removeChild(this.el);
	}

	render() {
		const containerStyle = {
			// copied from RNW View/StyleSheet/constants
			alignItems: "stretch",
			border: "0 solid black",
			boxSizing: "border-box",
			display: "flex",
			flexBasis: "auto",
			flexDirection: "column",
			flexShrink: 0,
			marginTop: 0,
			marginRight: 0,
			marginBottom: 0,
			marginLeft: 0,
			minHeight: 0,
			minWidth: 0,
			paddingTop: 0,
			paddingRight: 0,
			paddingBottom: 0,
			paddingLeft: 0,
			position: "relative",
			zIndex: 0,

			// modal
			position: "absolute",
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,

			// etc
			backgroundColor: this.props.transparent ? "transparent" : "white",
		};

		return ReactDOM.createPortal(<div style={containerStyle}>{this.props.children}</div>, this.el);
	}
}

export default class WebModal extends Component {
	render() {
		if (this.props.visible) {
			return <InnerModal {...this.props}/>;
		}

		return null;
	}
}
