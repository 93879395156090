import React, { useState } from "react";
import { Platform, ScrollView } from "react-native";

import Helpers from "../classes/Helpers";
import { TastingList } from "../components/TastingList";

let styles = null;

let _props = null;

let _display = null;
let _SetDisplay = null;


export default function SeasonScheduleScreen(props) {
	styles = global.styles;
	_props = props;
	[ _display, _SetDisplay ] = useState();

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		const unsubscribe2 = props.navigation.addListener("blur", OnBlur);
		return unsubscribe;
	}, [props.navigation]);

	const scrollStyle = (Platform.OS=="web") ? styles.webScrollViewFix : null;
	return (<ScrollView style={scrollStyle}>
		{_display}
	</ScrollView>);
}

function ListClose() {
	_props.navigation.goBack();
}

async function OnFocus() {
	global.context.ScreenRefresh = DisplayData;
	DisplayData();
}

function OnBlur() {
	console.log("SeasonSchedule - OnBlur");
	global.context.ScreenRefresh = null;
}

async function DisplayData() {
	_SetDisplay(<TastingList onClose={ListClose} seasonId={global.context.season._id} />);
}

SeasonScheduleScreen.navigationOptions = {
	header: null,
};
