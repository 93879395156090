import React, { useState } from "react";
import { Platform, BackHandler, TouchableOpacity, View } from "react-native";
import { ImageBackground, ActivityIndicator } from "react-native";
import { Text, TextInput } from "react-native";
import SafeAreaView from "react-native-safe-area-view";

let _isWeb = false;
let Alert = null;
if (Platform.OS=="web") {
	_isWeb = true;
	Alert = require("../components/web/WebAlert").default;
} else {
	Alert = require("react-native").Alert;
}

import * as Notifications from "expo-notifications";
import * as Facebook from "expo-facebook";
import * as Permissions from "expo-permissions";
import * as AppleAuthentication from "expo-apple-authentication";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";


import Helpers from "../classes/Helpers";
import API from "../classes/API";
import DebounceComponent from "../classes/DebounceComponent";

const TouchableOpacityDB = DebounceComponent(TouchableOpacity);

import { ActivityIndicatorProps, PlaceholderProps } from "../styles/main";

let styles = null;
const config = require("../config.json");
const lang = require("../constants/lang-en.json");


let _props = null;

let _email = null;
let _SetEmail = null;

let _password = null;
let _SetPassword = null;

let _showLocalLogin = null;
let _SetShowLocalLogin = null;

let _locked = false;

let _isLoading = false;
let _SetIsLoading = null;


export default function SignInScreen(props) {
	styles = global.styles;
	console.log("SignInScreen");
	_props = props;

	[ _isLoading, _SetIsLoading ] = useState(false);
	[ _email, _SetEmail ] = useState("");
	[ _password, _SetPassword ] = useState("");
	[ _showLocalLogin, _SetShowLocalLogin ] = useState(false);

	React.useEffect(() => {
		const unsubscribe = props.navigation.addListener("focus", OnFocus);
		BackHandler.addEventListener("hardwareBackPress", AndroidBackHandler);

		return unsubscribe;
	}, [props.navigation]);

	return (<ImageBackground source={require("../assets/images/bg_dark.jpg")} style={styles.bgImage}>
		<SafeAreaView style={styles.contentContainerClear} forceInset={{top: "always"}} keyboardShouldPersistTaps="always">
		<View style={{justifyContent:"space-between", flex:1}}>
			<KeyboardAwareScrollView style={[styles.webScrollViewFix,{alignSelf:"stretch"}]} keyboardShouldPersistTaps="always">
			<View style={[styles.mainBox, {paddingTop: Helpers.GetScaledSize(10)}]} keyboardShouldPersistTaps="always">
				<View style={{flex:1, alignItems: "center", justifyContent: "center", paddingBottom:Helpers.GetScaledSize(15)}}>
					<Text style={[styles.textBoldItalic,{color:"#fee2ba",fontSize:Helpers.GetScaledSize(70)}]}>LOGIN</Text>
				</View>

				{ Platform.OS!="web" && <TouchableOpacityDB style={styles.facebookButton} onPress={FacebookLogin}>
					<Text style={styles.loginText}>{lang.loginFacebook}</Text>
				</TouchableOpacityDB> }

				<View style={{ paddingTop:Helpers.GetScaledSize(10.67) }} />
				<TouchableOpacityDB style={styles.untappdButton} onPress={UntappdLogin}>
					<Text style={styles.loginText}>{lang.loginUntappd}</Text>
				</TouchableOpacityDB>

				{ Platform.OS=="ios" && Platform.Version>="13" && <View style={{ paddingTop:Helpers.GetScaledSize(10.67) }} /> }
				{ Platform.OS=="ios" && Platform.Version>="13" && <AppleAuthentication.AppleAuthenticationButton
					buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
					buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
					style={styles.appleButton} cornerRadius={0} onPress={AppleIDAuth}
				/> }

				{ Platform.OS=="web" && <View style={{ paddingTop:Helpers.GetScaledSize(10.67) }} /> }
				{ Platform.OS=="web" && <TouchableOpacityDB style={styles.appleButtonWeb} onPress={AppleIDAuth}>
					<Text style={styles.loginText}>{lang.loginApple}</Text>
				</TouchableOpacityDB> }

				<View style={{ paddingTop:Helpers.GetScaledSize(10.67) }} />
				<TouchableOpacityDB style={styles.localButton} onPress={ToggleLocalLogin}>
					<Text style={styles.loginText}>{lang.loginLocal}</Text>
				</TouchableOpacityDB>

				{ _showLocalLogin && <View style={{
						alignItems:"center", backgroundColor:"#c1751560", //e8e8e8 c17515, f6a518, fbc36e, fee2ba
						width:global.screen.width-Helpers.GetScaledSize(130),
						borderColor:"#000000", //f6a518
						borderWidth:Helpers.GetScaledSize(3),
						marginTop:Helpers.GetScaledSize(15),
						paddingVertical:Helpers.GetScaledSize(10)
					}}>
					<View style={{paddingBottom:Helpers.GetScaledSize(4), flexDirection:"row"}}>
						<Text style={[styles.title2,{color:"#ffffff"}]}>New to FBL? </Text>
						<TouchableOpacityDB onPress={()=>{ Helpers.Navigate("SignUp");}}>
							<Text style={[styles.textLink,{color:"#ffffff"}]}>{lang.createAccount}</Text>
						</TouchableOpacityDB>
					</View>

					<TextInput placeholder={lang.email} {...PlaceholderProps} autoCapitalize="none"
						importantForAutofill="yes" textContentType="emailAddress" autoCompleteType="email"
						style={[styles.inputBox, {marginBottom:Helpers.GetScaledSize(13.33)}]}
						value={_email} onChangeText={(value) => { _SetEmail(value); }} />
					<TextInput placeholder={lang.password} {...PlaceholderProps} autoCapitalize="none"
						textContentType="password" secureTextEntry={true} password={true}
						importantForAutofill="yes" textContentType="password" autoCompleteType="password"
						style={styles.inputBox}
						value={_password} onChangeText={(value) => { _SetPassword(value); }} />

					<View style={{paddingTop:Helpers.GetScaledSize(13), flexDirection:"row"}}>
						<TouchableOpacityDB onPress={ForgotPassword}>
							<Text style={[styles.textLink, {fontSize:Helpers.GetScaledSize(15.17), color:"#ffffff"}]}>{lang.forgotPassword}</Text>
						</TouchableOpacityDB>

						<View style={{paddingLeft:Helpers.GetScaledSize(100)}} />

						<TouchableOpacityDB style={styles.button} onPress={UserLogin}>
							<Text style={styles.buttonText}>{lang.login}</Text>
						</TouchableOpacityDB>
					</View>
				</View> }

				<TouchableOpacityDB style={{paddingTop:Helpers.GetScaledSize(30),alignItems:"center"}} onPress={AlcoholWarning}>
					<Text style={[styles.textLink, styles.textLight]}>Always Drink Responsibly</Text>
				</TouchableOpacityDB>

				{ /*<View style={{flexDirection:"row", paddingTop:Helpers.GetScaledSize(26.67)}}>
					<Text style={styles.userChangeText} onPress={SetUser1}>[SetUser1] </Text>
					<Text style={styles.userChangeText} onPress={SetUser2}> [SetUser2] </Text>
					<Text style={styles.userChangeText} onPress={SetUser3}> [SetUser3]</Text>
				</View> */ }
			</View>
			</KeyboardAwareScrollView>

			<View style={styles.bottomContainer}>
				<Text style={[styles.textLight, {fontSize:Helpers.GetScaledSize(12)}]}>{config.version}</Text>
			</View>
		</View>
		</SafeAreaView>
		{ _isLoading && <View style={styles.loading}>
			<ActivityIndicator {...ActivityIndicatorProps} />
		</View> }
	</ImageBackground>);
}

async function OnFocus() {
	_locked=false;

	//TODO verify loading animation
	if (global.context.UntappdLoginCallbackWeb || global.context.loginInfo) {
		await Helpers.SetIsLoading(_SetIsLoading, 5000);
	}

	if (global.context.loginInfo) {
		UserLoginToken(global.context.loginInfo.userId, global.context.loginInfo.token);

		global.context.loginInfo = null;
	}

	await PushNotifyInit();
}

async function ToggleLocalLogin() {
	console.log("ToggleLocalLogin");
	_SetShowLocalLogin(!_showLocalLogin);
}

function AlcoholWarning() {
	Alert.alert("Please Drink Responsibly", "Beer tastings should be small pours, especially for higher ABV beers. Please consider ride shares or otherwise avoid driving if you have to travel.", [{text: lang.ok}]);
}

async function UserLoginToken(userId, token) {
	if (!await API.Rest.UserLoginToken(userId, token)) {
		Alert.alert(lang.error, "Unable to login.", [{text: lang.ok}]);
		return;
	}

	/*if (global.context.leagues.length>0) {
		Helpers.Navigate("LeagueNav", { screen: "LeagueOverview"});
	} else {*/
		Helpers.Navigate("LeagueNav", { screen: "Leagues" });
	//}
}

async function ForgotPassword() {
	if (!Helpers.ValidateEmail(_email)) {
		return;
	}

	if (await API.Rest.PasswordReset(_email.toLowerCase())) {
		Alert.alert(lang.passwordReset, lang.passwordResetLink, [{text: lang.ok}]);
	} else {
		Alert.alert(lang.error, lang.passwordResetLink, [{text: lang.ok}]);
	}
}

async function UserLogin() {
	_email = _email.trim();
	if (!Helpers.ValidateEmail(_email)) {
		return;
	}

	if (_password.length==0) {
		Alert.alert(lang.needPassword, lang.enterPassword, [{text: lang.ok}]);
		return;
	}

	if (_locked) {
		Alert.alert("Working...", "Processing previous login attempt.", [{text: lang.ok}]);
		return;
	}

	_locked = true;
	await _SetIsLoading(true);
	if (!await API.Rest.UserLogin(_email, _password, global.context.pushToken)) {
		Alert.alert(lang.invalidLogin, lang.invalidLoginText, [{text: lang.ok}]);
		_SetIsLoading(false);
		_locked = false;
		return;
	}

	_SetEmail("");
	_SetPassword("");
	_SetIsLoading(false);

	/*if (global.context.leagues.length>0) {
		Helpers.Navigate("LeagueNav", { screen: "LeagueOverview"});
	} else {*/
		Helpers.Navigate("LeagueNav", { screen: "Leagues" });
	//}
}

async function SetUser1() {
	console.log("inside SetUser1");
	UserLoginToken("u:4679ec68d19e4863b595283dc3dddea8", "hZk9u7ytXbPRyeongv2YfucENunCD2Kjp/RO27JkxjoUomy2zi");
}

async function SetUser2() {
	console.log("inside SetUser2");
	await _SetEmail("stellar7@gmail.com");
	await _SetPassword("123456");
	UserLogin();
}

async function SetUser3() {
	console.log("inside SetUser3");
	await _SetEmail("stellar7+n1@gmail.com");
	await _SetPassword("123456");
	UserLogin();
}

async function FacebookLogin() {
	try {
		await _SetIsLoading(true);
		console.log("opening facebook " + config.facebookAppId);
		if (!_isWeb) {
			await Facebook.initializeAsync({ "appId": config.facebookAppId });
		}

		const { type, token, expires, permissions, declinedPermissions } = await Facebook.logInWithReadPermissionsAsync({ permissions: ["public_profile", "email"] });

		if (type == "success") {
			if (await API.Rest.UserLoginFacebook(token, global.context.pushToken)) {
				_SetIsLoading(false);
				Helpers.Navigate("LeagueNav", { screen: "Leagues" });
			} else {
				Alert.alert(lang.error, "Unable to login with Facebook.", [{text: lang.ok}]);
			}
		}

		_SetIsLoading(false);
	} catch (ex) {
		_SetIsLoading(false);
		console.log(ex);
		//Alert.alert(lang.error, "Unable to login with Facebook.", [{text: lang.ok}]);
		Alert.alert(lang.error, "Unable to login with Facebook: " + JSON.stringify(ex), [{text: lang.ok}]); //TODO debug
	}
}

async function UntappdLogin() {
	await Helpers.SetIsLoading(_SetIsLoading, 5000);

	if (_isWeb) {
		delete global.context.UntappdLoginCallback;
		global.context.UntappdLoginCallbackWeb = true;
	} else {
		global.context.UntappdLoginCallback = UntappdLoginCallback;
		delete global.context.UntappdLoginCallbackWeb;
	}
	Helpers.UntappdOpenAuth();
}

async function UntappdLoginCallback(token) {
	await Helpers.SetIsLoading(_SetIsLoading);
	console.log("calling UserLoginUntappd");

	if (await API.Rest.UserLoginUntappd(token, global.context.pushToken)) {
		_SetIsLoading(false);
		Helpers.Navigate("LeagueNav", { screen: "Leagues" });
	} else {
		Alert.alert(lang.error, "Unable to login with Untappd.", [{text: lang.ok}]);
	}

	_SetIsLoading(false);
}

async function AppleIDAuth() {
	if (_isWeb) {
		Helpers.OpenURL("https://appleid.apple.com/auth/authorize?client_id=com.fantasybeerleague.websignin&redirect_uri=https://us-chi01.playfbl.com/api/v1/auth/apple&scope=name%20email&response_type=code%20id_token&response_mode=form_post", true);
		return;
	}

	await _SetIsLoading(true);

	try {
		const cred = await AppleAuthentication.signInAsync({
			requestedScopes: [AppleAuthentication.AppleAuthenticationScope.FULL_NAME, AppleAuthentication.AppleAuthenticationScope.EMAIL ],
		});
		console.log(cred);
		global.appleInfo=cred;
		if (!cred.identityToken) {
			Alert.alert(lang.error, "Error getting Apple login information.", [{text: lang.ok}]);
			return;
		}

		if (!cred.fullName) {
			cred.fullName = { "givenName": "CHANGE", "familyName": "CHANGE"};
		}

		if (!cred.fullName.givenName) {
			cred.fullName.givenName = "CHANGE";
		}

		if (!cred.fullName.familyName) {
			cred.fullName.familyName = "CHANGE";
		}

		let data = { "token": cred.identityToken, "firstName": cred.fullName.givenName, "lastName": cred.fullName.familyName, "extra": JSON.stringify(cred) };
		if (await API.Rest.UserLoginApple(data, global.context.pushToken)) {
			_SetIsLoading(false);
			Helpers.Navigate("LeagueNav", { screen: "Leagues" });
		} else {
			Alert.alert(lang.error, "Unable to login with Apple.", [{text: lang.ok}]);
		}
	} catch (ex) {
		console.log(ex);
		//Alert.alert(JSON.stringify(ex));

		Alert.alert(lang.error, "Unable to login with Apple.", [{text: lang.ok}]);
		if (ex.code === "ERR_CANCELED") {
			console.log("canceled");
			// handle that the user canceled the sign-in flow
		} else {
			console.log("other");
			// handle other errors
		}
	}

	_SetIsLoading(false);
}

async function PushNotifyInit() {
	try {
		const { status: existingStatus } = await Permissions.getAsync(
			Permissions.NOTIFICATIONS
		);

		let finalStatus = existingStatus;

		// only ask if permissions have not already been determined, because iOS won't necessarily prompt the user a second time
		if (existingStatus !== "granted") {
			// Android remote notification permissions are granted during the app install, so this will only ask on iOS
			const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
			finalStatus = status;
		}

		if (finalStatus !== "granted") {
			return;
		}

		let pushTokenInfo = await Notifications.getExpoPushTokenAsync();
		if (pushTokenInfo.data) {
			global.context.pushToken = pushTokenInfo.data;
		} else {
			global.context.pushToken = pushTokenInfo;
		}
		console.log("push token is " + global.context.pushToken);

		if (Platform.OS=="android") {
			Notifications.createChannelAndroidAsync("default", { name:"default", sound:true, priority:"high", vibrate:true }); //[0, 250, 250, 250]
		}
		//_notifySub = Notifications.addListener(Helpers.HandlePushNotify);
	} catch (ex) {
		console.log("error in PushNotifyInit " + JSON.stringify(ex));
	}
}

function AndroidBackHandler() {
	BackHandler.exitApp();
}

Notifications.setNotificationHandler({
	handleNotification: async () => ({
		shouldShowAlert: true,
		shouldPlaySound: true,
		shouldSetBadge: false,
	}),
});

SignInScreen.navigationOptions = {
	header: null,
	title: "Sign In",
};
